import {selectCurrentJourneyId} from "@/models/journey";
import {
  changeCustomerNotesRequest,
  fetchCustomerDetailsRequest,
  fetchCustomersForJourney,
  fetchCustomerStatesRequest,
  unSubscribeCustomerRequest
} from "@/services/api/customer";
import {getChangeLoadings, setChangeLoadingsUtility} from "@/utils/form";
import {processAPIResponse} from "@/utils/request";
import {trackModalView, trackOnlineEditForm} from "@/services/tracker";
import {CUSTOMER_EDIT_ACTION} from "@/constants/trackerActions";
import {selectDateFilter} from "@/models/date";
import {message} from "antd";
import {openCSVLink} from "@/utils/download";

export default {
  namespace: 'customers',

  state: {
    list: {},
    states: [],
    details: {},
    changeLoadings: {},
    drawer: {
      customer: {},
      visible: false
    }
  },

  effects: {
    * fetch({payload = {}}: {payload: {search?: string, url?: string}}, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const query = {...date};
      if (payload.search){
        query.search = payload.search;
      }
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchCustomersForJourney, {params: {journeyId}, url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveCustomers',
            payload: data
          })
        },
        * onError(error){

        }
      }, 'data')

    },
    * downloadExport({ payload = {} }: {payload: {url?: string}}, { call, put, select }) {
      const date = yield select(selectDateFilter)
      const query = {...date, export: true};
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchCustomersForJourney, {params: {journeyId}, url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess() {
          if (data.data.links && data.data.links.export) {
          yield openCSVLink(data.data.links.export)
          }
        },
        * onError(error){

        }
      }, 'data')

    },
    * fetchStates({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchCustomerStatesRequest, {params: {journeyId}});

      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveCustomerStates',
            payload: data
          })
        },
        * onError(error){

        }
      })
    },
    * fetchDetails({payload: {customerId}}, {call, put, select}) {
      const {response, data} = yield call(fetchCustomerDetailsRequest, {
          params: {customerId},
        }
      );
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveCustomer',
            payload: {
              customerId,
              customer: data.Customer
            }
          })
        },
        * onError(error){
          if (error) message.error(error.message)

          yield put({
            type: "fetchCustomerDetailsFailed"
          })
        }
      }, 'data')
    },
    * change({payload: {customerId, ...body}}, {call, put}) {
      yield put({
        type: "setChangeLoadings",
        payload: getChangeLoadings(body, true)
      })
      const {response, data} = yield call(changeCustomerNotesRequest, {
          params: {customerId},
          payload: body
        }
      );
      yield put({
        type: "setChangeLoadings",
        payload: getChangeLoadings(body, false)
      })
      trackOnlineEditForm(body, CUSTOMER_EDIT_ACTION)

      yield processAPIResponse({response, data}, {
        * onSuccess(data) {},
        * onError(error){
        }
      }, 'data')
    },
    * unSubscribe({payload: {customerId}}, {call, put}) {
      const {response, data} = yield call(unSubscribeCustomerRequest, {
          params: {customerId},
          payload: {
            subscribe: false
          }
        }
      );
      if (response.status > 300) {
        return;
      }
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetch'
          })
          yield put({
            type: 'fetchDetails',
            payload: {
              customerId
            }
          })

        },
        * onError(error){
        }
      })

    }
  },
  reducers: {
    saveCustomers(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCustomer(state, action) {
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.customerId]: action.payload.customer
        },
      };
    },
    saveCustomerStates(state, action) {
      return {
        ...state,
        states: action.payload.data,
      };
    },
    setChangeLoadings(state, action) {
      return setChangeLoadingsUtility(state, action)
    },
    fetchCustomerDetailsFailed(state, action) {
      return {
        ...state,
        drawer: {
          visible: false
        }
      }
    },
    toggleDrawer(state, action) {
      const {payload: {customer, nested}} = action;
      const drawer = state.drawer || {};
      drawer.nested = nested
      if (customer) {
        drawer.customer = customer
        drawer.visible = true
        trackModalView('customer-details')
      } else {
        drawer.visible = false
      }

      return {
        ...state,
        drawer
      }
    }
  }
};
