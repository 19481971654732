import {ImageTagGroupModel, ImageTagInfoModel} from "@/typings/models/Image";

export const getTreeForTagsAndTagGroups = (tags: ImageTagInfoModel[] = [], tagGroups: ImageTagGroupModel[] = []) => {
  const processedTagGroups = tagGroups?.map(tagGroup => {
    return {
      ...tagGroup,
      name: tagGroup.title,
      tags: tags?.filter(tag => tag.taggroup?.id === tagGroup.id)
    }
  })
  const nullTagGroups = tags?.filter(tag => tag.taggroup === null)
  if (nullTagGroups?.length > 0) return processedTagGroups.concat({
    tags: nullTagGroups,
    title: "N/A",
    name: "N/A"
  })

  return processedTagGroups
}
