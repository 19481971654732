import React, {ReactNode} from "react";
import styles from "./index.less"

interface DrawerSectionProps {
  title: string | ReactNode;
  action?: ReactNode;
  children: ReactNode;
  sectionStyles: React.CSSProperties;
}
const DrawerSection: React.FC<DrawerSectionProps> = ({
  title,
  children,
  action,
  sectionStyles,
}) => {
  return (
    <div className={styles.drawerSection} style={sectionStyles}>
      <div className={styles.header}>
        {title ? <h2 className={styles.title}>{title}</h2> : null}
        {action}
      </div>
      {children && children}
    </div>
  );
};

export default DrawerSection
