import {StylesStatsModel} from "@/typings/models/StylesStats";
import {capitalizeAllLetters} from "@/utils/functions";

export const mapHistogramChartData = (data = {}) => {
  return Object.keys(data).map(key => ({
    key: capitalizeAllLetters(key),
    value: data[key]
  }))
}
export const mapRingChartData = (data: StylesStatsModel['values']) => {
  return Object.entries(data).map(([type, value]) => ({
    type: capitalizeAllLetters(type),
    value
  }))
}
