import {LockOutlined} from '@ant-design/icons';
import {message, Typography} from 'antd';
import React, {useState} from 'react';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import {FormattedMessage as F, history, NavLink, useIntl} from 'umi';
import {resetPasswordRequest} from '@/services/api/user';
import styles from './index.less';
import layoutStyles from '../userLayout.less';
import UserLayout from '@/pages/user/UserLayout';
import {DispatchFunction} from '@/typings/models/ReduxState';

type ResetPasswordProps = {
  dispatch: DispatchFunction;
  onChange?: (fields: any) => void;
  initialValues?: {
    [key: string]: string;
  };
};

const ResetPassword: React.FC<ResetPasswordProps> = ({ match, onChange, initialValues = {} }) => {
  const [submitting, setSubmitting] = useState(false);
  const intl = useIntl();

  const handleSubmit = async (values) => {
    setSubmitting(true);
    // login
    const APICall = await resetPasswordRequest({
      payload: {
        password: values.password,
      },
      params: {
        token: match.params.token,
      },
    });
    setSubmitting(false);
    if (APICall.response.status === 200) {
      message.success(APICall.data.message);
      history.push('/user/login');
      return;
    } else {
      message.error(APICall.data.message || APICall.data.error?.message);
    }
  };
  return (
    <UserLayout>
      <div>
        <Typography.Title className={layoutStyles.pageTitle}>
          <F id={'pages.resetPassword.title'} defaultMessage={'Assign A New Password'} />
        </Typography.Title>
        <ProForm
          initialValues={{
            autoLogin: true,
            ...initialValues,
          }}
          onValuesChange={onChange && onChange}
          submitter={{
            searchConfig: {
              submitText: intl.formatMessage({
                id: 'pages.resetPassword.setNewPassword',
                defaultMessage: 'Set new password',
              }),
            },
            render: (_, dom) => dom.pop(),
            submitButtonProps: {
              loading: submitting,
              size: 'large',
              style: {
                width: '100%',
              },
            },
          }}
          onFinish={async (values) => {
            handleSubmit(values as API.LoginParams);
          }}
        >
          <>
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={styles.prefixIcon} />,
              }}
              placeholder={intl.formatMessage({
                id: 'pages.resetPassword.placeholder',
                defaultMessage: 'Password',
              })}
              rules={[
                {
                  required: true,
                  message: (
                    <F
                      id="pages.resetPassword.passwordRequired"
                      defaultMessage="Please Enter Password"
                    />
                  ),
                },
              ]}
            />
            <ProFormText.Password
              name="passwordConfirm"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={styles.prefixIcon} />,
              }}
              placeholder={intl.formatMessage({
                id: 'pages.resetPassword.placeholderConfirm',
                defaultMessage: 'Password',
              })}
              rules={[
                {
                  required: true,
                  message: (
                    <F
                      id="pages.resetPassword.passwordRequired"
                      defaultMessage="Please Enter Password"
                    />
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(intl.formatMessage({id: 'pages.resetPassword.wrongConfirmation'})));
                  },
                })
              ]}
            />
          </>
          <div
            style={{
              marginBottom: 70,
            }}
          >
            <NavLink
              to={'/user/login'}
              style={{
                float: 'right',
              }}
              className={'color-secondary'}
            >
              <F id="pages.resetPassword.backToLogin" defaultMessage="Back to login" />
            </NavLink>
          </div>
        </ProForm>
      </div>
    </UserLayout>
  );
};

export default ResetPassword;
