import React from 'react';
import { DEFAULT_CURRENCY_UNIT } from '@/constants/currencyUnit';

export interface MoneyProps {
  amount: string,
  currency?: string
}

export const getMoneyString = (amount, currency = DEFAULT_CURRENCY_UNIT) => amount + " " + currency
const Money: React.FC<MoneyProps> =
  ({amount, currency= DEFAULT_CURRENCY_UNIT}) => {

    return <span>{getMoneyString(amount, currency)}</span>
  };

export default (Money)
