import React from "react";
import {JourneyModel} from "@/typings/models/Journey";
import styles from "./index.less";
import JourneyAvatar from "@/components/JourneyAvatar";

export interface SelectedJourneyTitleProps {
  journey: JourneyModel,
  mini?: boolean
}

const SelectedJourneyTitle: React.FC<SelectedJourneyTitleProps> =
  ({journey, mini = false}) => {
    return <div className={styles.selectedJourney}>
      <div className={styles.avatar}>
        <JourneyAvatar journey={journey} className={styles.journeyItemAvatar} size={35}/>
      </div>
      <div className={styles.title}>
        <strong>{!mini && journey?.name}</strong>
      </div>
    </div>
  };

export default (SelectedJourneyTitle)
