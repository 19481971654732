import React, {useEffect} from "react";
import {connect, useIntl} from "umi";
import {RadioChangeEvent, RadioGroupProps} from "antd";
import MyRadioGroup from "@/components/MyRadioGroup";
import {DispatchFunction} from "@/typings/models/ReduxState";
import styles from "./index.less"

export type PeriodSelectorProps = RadioGroupProps & {
  dispatch: DispatchFunction
}

const PeriodSelector: React.FC<PeriodSelectorProps> =
  ({
     value,
     dispatch,
     onChange,
    ...radioGroupProps
   }) => {

    const {formatMessage} = useIntl()

    const values = ['day', 'week', 'month']

    const options = values.map(value => ({
      title: formatMessage({
        id: `component.periodSelector.${value}`
      }),
      value
    }))

    useEffect(() => {

    }, []);

    function handleChange(e: RadioChangeEvent) {
      dispatch({
        type: 'periodSelector/change',
        payload: e.target.value
      })
      if (onChange) {
        onChange(e)
      }
    }
    return <div className={styles.periodSelector}>
      <MyRadioGroup onChange={handleChange} value={value} values={options} {...radioGroupProps}/>
    </div>
  };


export default connect(({periodSelector}) => ({
  value: periodSelector
}))(PeriodSelector)
