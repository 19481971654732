import * as React from 'react'
import {JourneyModel} from "@/typings/models/Journey";
import {Avatar} from 'antd';
import {getInitials} from "@/utils/functions";

interface JourneyAvatarProps {
  journey: JourneyModel,
  className?: string,
  size?: number
}

const JourneyAvatar: React.FC<JourneyAvatarProps> = ({journey, className, size = 25}: JourneyAvatarProps) => {
  function getJourneyInitials(){
    return getInitials(journey?.name)
  }
  return <Avatar size={size} className={className}>{getJourneyInitials()}</Avatar>
}

export default (JourneyAvatar)
