import React from "react";
import {daysForLocale} from "@/utils/date";
import {Select} from "antd";
import styles from "./index.less";
import {getLocale} from 'umi';

const {Option} = Select

interface WeekDayPickerProps {
  weekDay: number,
  onChange: (weekDay: number) => void
}

const WeekDayPicker: React.FC<WeekDayPickerProps> = ({weekDay, onChange}) => {

  const days = daysForLocale(getLocale()).map((dayName, index) => ({
    value: index + 1,
    text: dayName
  }))
  return <div className={styles.pickerWrapper}>
    <Select defaultValue={weekDay} value={weekDay} style={{width: 120}} onChange={onChange}>
      {days.map(day => <Option value={day.value}>{day.text}</Option>)}
    </Select>
  </div>
}

export default WeekDayPicker
