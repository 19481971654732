import {IEvent} from "@/typings/common/event";

export function paginationEventBuilder({
  label, action
                                       }: {
  label: string,
  action: string
}): IEvent {
  return {
    label,
    action,
    category: "pagination"
  }
}

export function exportEventBuilder({
  label, action
                                       }: {
  label: string,
  action: string
}): IEvent {
  return {
    label,
    action,
    category: "CSVExport"
  }
}
