import {Space} from 'antd';
import {MenuUnfoldOutlined} from '@ant-design/icons';
import React, {useEffect} from 'react';
import {useModel} from 'umi';
import styles from './index.less';
import DvaWatcher from '@/components/DvaWatcher';


const GlobalHeaderRight: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  useEffect(() => {
    if (initialState.collapsed){
      document.getElementById("root")?.classList.add("collapsed")
    }else{
      document.getElementById("root")?.classList.remove("collapsed")
    }
  },[initialState.collapsed])
  
  if (!initialState || !initialState.settings) {
    return null;
  }
  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }
  return (
    <Space className={className}>
      <DvaWatcher/>
      <span onClick={() => setInitialState({
        ...initialState,
        collapsed: !initialState.collapsed
      })}>
        <MenuUnfoldOutlined />
      </span>
    </Space>
  );
};
export default GlobalHeaderRight;
