import React, { useEffect, useState } from 'react';
import { Progress, Spin } from 'antd';

import { ImageModel } from '@/typings/models/Image';
import ImageTag from '@/components/Image/ImageTag';
import { addImageTagRequest, removeImageTag } from '@/services/api/image';
import { connect } from 'umi';

import NewTag from './NewTag';

export interface ImageTagsProps {
  image: ImageModel;
  imageIds: string[];
  setBulkEditLoading: (loading: boolean) => void;
  setImageIds: (imageIds: string[]) => void;
  selectedJourney: { hashId: string };
  tagList: any[];
  tagGroupList: any[];
  dispatch: any;
}

const ImageTags = ({
  image,
  imageIds = [],
  tagList,
  tagGroupList,
  selectedJourney,
  setBulkEditLoading,
  setImageIds,
  dispatch,
}: React.PropsWithChildren<ImageTagsProps>) => {
  const [tags, setTags] = useState(image?.tags ?? []);
  const [bulkEditDoneCount, setBulkEditDoneCount] = useState(-1);
  const [bulkEditTagId, setBulkEditTagId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!bulkEditTagId || bulkEditDoneCount < 0) return;

    if (bulkEditDoneCount > imageIds.length) {
      setBulkEditLoading(false);
      setLoading(false);
      setImageIds([]);
      setBulkEditDoneCount(-1);
      setBulkEditTagId(null);
      dispatch({
        type: 'images/fetch',
      });
      dispatch({
        type: 'images/fetchTags',
      });
      return;
    }
    addTagToImageIds(bulkEditTagId);
  }, [bulkEditDoneCount]);

  const addTagToImageIds = (tagId) => {
    const imageId = imageIds[bulkEditDoneCount];
    setBulkEditLoading(true);
    setLoading(true);
    addImageTagRequest({
      params: {
        journeyId: selectedJourney.hashId,
        imageId: imageId,
        tagId,
      },
    }).then(() => {
      setBulkEditDoneCount(bulkEditDoneCount + 1);
    });
  };

  const handleRemove = (tag) => {
    removeImageTag({
      params: {
        journeyId: selectedJourney.hashId,
        imageId: image.id,
        tagId: tag.id,
      },
    }).then(() => {});
    setTags(tags.filter((_tag) => _tag.id !== tag.id));
  };

  return (
    <>
      {bulkEditDoneCount >= 0 && (
        <Progress
          size={'small'}
          percent={(bulkEditDoneCount / imageIds.length) * 100}
          showInfo={false}
        />
      )}
      <Spin spinning={loading}>
        {tags?.map((tag) => (
          <ImageTag tag={tag} key={tag.id} onRemove={handleRemove} />
        ))}
        <NewTag
          image={image}
          imageIds={imageIds}
          selectedJourney={selectedJourney}
          setBulkEditDoneCount={setBulkEditDoneCount}
          setBulkEditTagId={setBulkEditTagId}
          setTags={setTags}
          tagList={tagList}
          tags={tags}
          dispatch={dispatch}
          tagGroupList={tagGroupList}
        />
      </Spin>
    </>
  );
};

export default connect(({ images, journey }) => ({
  selectedJourney: journey.selectedJourney,
  tagList: images.tags,
  tagGroupList: images.tagGroups,
}))(ImageTags);
