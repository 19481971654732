//@ts-nocheck

import get from "lodash/get";

/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
import {API} from "@/services/api/typings";

export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  return {
    adminRouteFilter: () => BACKOFFICE_ENV !== "production" || get(initialState, 'currentUser.User.isAdmin', false),
    impersonateRouteFilter: () => BACKOFFICE_ENV !== "production" || get(initialState, 'currentUser.Impersonate', false),
  };
}