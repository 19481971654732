import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";
import {GetCustomersRequest} from "@/typings/api/getCustomers";
import {UnsubscribeCustomerRequest} from "@/typings/api/unsubscribeCustomer";
import {GetCustomerStatesRequest} from "@/typings/api/getCustomerStates";
import {ChangeCustomerStateRequest} from "@/typings/api/changeCustomerState";
import {ChangeCustomerNotesRequest} from "@/typings/api/changeCustomerNotes";
import {GetCustomerRequest} from "@/typings/api/getCustomer";


// region Fetch Customers
type FetchCustomersForJourneyOptions = {
  params: GetCustomersRequest["parameters"],
  url?: string,
  query?: GetCustomersRequest["query"]
}

export function fetchCustomersForJourney({
                                           params,
                                           url,
                                           query = {}
                                         }: FetchCustomersForJourneyOptions) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_CUSTOMERS_API_PATH, {
    queryString: query,
    params
  })
  return request<GetCustomersRequest["response"]>(finalUrl, {
    method: 'GET',
    absoluteURL: url
  });
}

// endregion

// region UnSubscribe
type UnsubscribeCustomerRequestOptions = {
  params: UnsubscribeCustomerRequest["parameters"]
}

export function unSubscribeCustomerRequest({
                                             params,
                                           }: UnsubscribeCustomerRequestOptions) {
  const url = URLParamBuilder(PATHS.UNSUBSCRIBE_CUSTOMER_API_PATH, {
    params
  })

  return request<any>(url, {
    method: 'POST',
    data: {
      subscribe: false
    }
  });
}

// endregion

// region Fetch Customer States
type FetchCustomerStatesOptions = {
  params: GetCustomerStatesRequest["parameters"]
}

export function fetchCustomerStatesRequest({
                                             params,
                                           }: FetchCustomerStatesOptions) {
  const url = URLParamBuilder(PATHS.GET_CUSTOMER_STATES_API_PATH, {
    params
  })

  return request<GetCustomerStatesRequest["response"]>(url, {
    method: 'GET',
  });
}

// endregion

// region Change Customer States
type ChangeCustomerStatesOptions = {
  params: ChangeCustomerStateRequest["parameters"]
  payload: ChangeCustomerStateRequest["payload"]
}

export function changeCustomerStateRequest({
                                             params,
                                             payload
                                           }: ChangeCustomerStatesOptions) {
  const url = URLParamBuilder(PATHS.CHANGE_CUSTOMER_STATE_API_PATH, {
    params
  })

  return request<ChangeCustomerStateRequest["response"]>(url, {
    method: 'POST',
    data: payload
  });
}

// endregion

// region Change Customer Notes
type ChangeCustomerNotesOptions = {
  params: ChangeCustomerNotesRequest["parameters"],
  payload: ChangeCustomerNotesRequest["payload"],
}

export function changeCustomerNotesRequest({
                                             params,
                                             payload
                                           }: ChangeCustomerNotesOptions) {
  const url = URLParamBuilder(PATHS.CHANGE_CUSTOMER_NOTES_API_PATH, {
    params
  })

  return request<ChangeCustomerStateRequest["response"]>(url, {
    method: 'POST',
    data: payload
  });
}

// endregion

// region Fetch Customer Details
// region Change Customer Notes
type FetchCustomerDetailsOptions = {
  params: GetCustomerRequest["parameters"],
}

export function fetchCustomerDetailsRequest({
                                              params,
                                            }: FetchCustomerDetailsOptions) {
  const url = URLParamBuilder(PATHS.GET_CUSTOMER_API_PATH, {
    params
  })

  return request<GetCustomerRequest["response"]>(url, {
    method: 'GET'
  });
}

// endregion
