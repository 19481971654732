import MyPagination, {PAGINATION_MODES} from "@/components/MyPagination";
import {PaginationLinksModel} from "@/typings/models/PaginationLinks";
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import {Grid} from 'antd';
import React, {useEffect, useRef, useState} from "react";
import Card from "../Card";
import get from "lodash/get";


export type TableProps = {
  loading: boolean,
  dataSource: any[],
  headerTitle?: string,
  pagination?: boolean,
  total?: number,
  search?: boolean,
  fullScreen?: boolean,
  reload?: boolean,
  hasPageSize?: boolean,
  rowSelection: any,
  links?: PaginationLinksModel,
  request: (payload: any) => {},
  onTableChange: (payload: any) => {},
  columns: ProColumns<any, 'text'>[],
  rowKey?: string,
  header?: any,
  label?: string,
  expandableRow?: React.Component,
  rowClassName?: string,
  toolBarRender?: () => React.ReactElement,
  isClientSideSearch?: boolean
}

const Table: React.FC<TableProps> = ({
                                       request = () => {
                                       },
                                       onTableChange = () => {
                                       },
                                       total = 0,
                                       loading,
                                       search = true,
                                       fullScreen = true,
                                       reload = true,
                                       pagination,
                                       label,
                                       links,
                                       rowSelection,
                                       header,
                                       dataSource,
                                       headerTitle,
                                       hasPageSize,
                                       columns,
                                       rowKey,
                                       expandableRow,
                                       toolBarRender,
                                       rowClassName,
                                       isClientSideSearch = false
                                     }) => {
  const actionRef = useRef<ActionType>();
  const {useBreakpoint} = Grid;
  const screens = useBreakpoint();
  const [limit, setLimit] = useState(30)
  const [scrollHeight, setScrollHeight] = useState(500)
  const [filteredData, setFilteredData] = useState(dataSource); 

  const [page, setPage] = useState(0)
  useEffect(() => {
    request()
    setScrollHeight(window.innerHeight * 0.5)
  }, []);


  function onPaginationChange(type, value) {
    let newPage = page
    switch (type) {
      case "PREV": {
        newPage = page - 1
        request({
          url: links?.prev,
          page: newPage
        })
        break
      }
      case "FIRST": {
        newPage = 0
        request({
          url: links?.first,
          page: newPage
        })
        break
      }
      case "NEXT": {
        newPage = page + 1
        request({
          url: links?.next,
          page: newPage
        })
        break
      }
      case "LAST": {
        request({
          url: links?.last,
          page: newPage
        })
        break
      }
      case "SIZE": {
        request({
          limit: value,
          page: newPage
        })
        setLimit(value)
        break
      }
    }
    setPage(newPage)
  }
  useEffect(() => {
    setFilteredData(dataSource)
  }, [JSON.stringify(dataSource)]);

  function processChange(filters) {
    if (isClientSideSearch) {
      const keyword = filters.keyword?.toLowerCase() || '';
      const filtered = dataSource.filter((item) => {
        return Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(keyword));
      });
      setFilteredData(filtered); 
    }
    else{
      setFilteredData(dataSource)
    }

    setPage(0);
    request({
      search: filters.keyword,
      page: 0,
    });
  }

  const paginationMode = get(pagination, 'mode', PAGINATION_MODES.CURSOR);

  return <Card>
    <div>
      {header}
      <ProTable<API.RuleListItem, API.PageParams>
        rowClassName={rowClassName}
        headerTitle={false && headerTitle}
        pagination={false}
        loading={loading}
        actionRef={actionRef}
        rowKey={rowKey || "key"}
        request={processChange}
        search={false}
        options={{
          search,
          density: false,
          fullScreen,
          setting: false,
          reload,

        }}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        onChange={onTableChange}
        toolBarRender={toolBarRender}
        dataSource={filteredData}
        columns={columns}
        rowSelection={rowSelection}
        expandable={
          expandableRow && {
            expandedRowRender: expandableRow,
            rowExpandable: () => !screens.xl,
          }
        }
      />
      {pagination !== false && (links || paginationMode === PAGINATION_MODES.PAGE) &&
        <MyPagination tableLabel={label} count={dataSource.length}
                      links={links}
                      onChange={onPaginationChange}
                      loading={loading} size={limit}
                      hasPageSize={hasPageSize}
                      mode={paginationMode}
                      hasMore={get(pagination, 'hasMore')}
                      page={page} total={total}/>}
    </div>
  </Card>
};

export default (Table)
