import {selectCurrentJourneyId} from "@/models/journey";
import {setChangeLoadingsUtility} from "@/utils/form";
import {processAPIResponse} from "@/utils/request";
import {fetchImagesForJourney, fetchTagGroups, fetchTags} from "@/services/api/image";

export default {
  namespace: 'images',

  state: {
    list: {},
    tags: [],
    tagGroups: [],
    fetchError: null
  },

  effects: {
    * fetch({payload = {}}: {payload: {search?: string, url?: string, limit?: string}}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchImagesForJourney, {params: {journeyId}, url: payload.url, query: {limit: (payload?.limit || 30)}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveImages',
            payload: data
          })
        },
        * onError(error){
        yield put({
          type: 'setFetchError',
          payload: error
        })
        }
      }, 'data')

    },
    * fetchTags(_, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchTags, {params: {journeyId}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveTags',
            payload: data
          })
        },
        * onError(error){
        yield put({
          type: 'setFetchError',
          payload: error
        })

        }
      }, 'tags')

    },
    * fetchTagGroups(_, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchTagGroups, {params: {journeyId}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveTagGroups',
            payload: data
          })
        },
        * onError(error){
        yield put({
          type: 'setFetchError',
          payload: error
        })

        }
      }, 'taggroups')

    },
  },
  reducers: {
    saveImages(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    setFetchError(state, action) {
      return {
        ...state,
        fetchError: action.payload?.error,
      };
    },
    saveTags(state, action) {
      return {
        ...state,
        tags: action.payload,
      };
    },
    saveTagGroups(state, action) {
      return {
        ...state,
        tagGroups: action.payload,
      };
    },
    setChangeLoadings(state, action) {
      return setChangeLoadingsUtility(state, action)
    }
  }
};
