import env from '@/constants/environment';
import * as Sentry from '@sentry/react';

export function initializeSentry() {
  if (env.sentry)
    Sentry.init({
      dsn: env.sentry,
      tracesSampleRate: 0,
      // @ts-ignore
      environment: BACKOFFICE_ENV,
    });
}
