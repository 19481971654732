import React from "react";
import styles from './userLayout.less'
import {Col, Row} from "antd";
import UserLayoutPicture from "@/components/UserLayoutPicture";
import UserFormLayout from "@/components/UserFormLayout";
import EnvSwitcher from "@/components/EnvSwitcher";

const UserLayout: React.FC = ({children}) => {

  const formSpan = {
    xxl: 10,
    xl: 10,
    lg: 12,
    md: 14,
    sm: 24,
    xs: 24
  }
  const picSpan = {}
  Object.entries(formSpan).forEach(([key, value]) => {
    picSpan[key] = 24 - value
  })
  return (
    <div>
      <EnvSwitcher />
      <Row className={styles.userLayoutRow}>
        <Col {...picSpan}>
          <UserLayoutPicture />
        </Col>
        <Col {...formSpan}>
          <UserFormLayout>{children}</UserFormLayout>
        </Col>
      </Row>
    </div>
  );

}

export default UserLayout;
