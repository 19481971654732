import { fetchTagStatisticsRequestFor2TagGroups, fetchTagStatisticsRequestFor3TagGroups } from "@/services/api/tagStatistics";
import { processAPIResponse } from "@/utils/request";
import { selectCurrentJourneyId } from "umi";

export default {
    namespace: 'tagStatistics',
  
    state: {
      tagStatistics: [],
      // ...
    },
  
    effects: {
      * fetchTagStatisticsFor2TagGroups({ payload: { tagGroup1Hashid, tagGroup2Hashid } }, { call, put, select }) {
        const journeyToken = yield select(selectCurrentJourneyId)
        const { response, data } = yield call(fetchTagStatisticsRequestFor2TagGroups, {
          params: { journeyToken, tagGroup1Hashid, tagGroup2Hashid },
        });
        yield processAPIResponse({ response, data }, {
          * onSuccess(data) {
            yield put({
              type: 'saveTagStatistics',
              payload: data,
            });
          },
          * onError(error) {
            // Handle error
          },
        });
      },
  
      * fetchTagStatisticsFor3TagGroups({ payload: { tagGroup1Hashid, tagGroup2Hashid, tagGroup3Hashid } }, { call, put, select }) {
      const journeyToken = yield select(selectCurrentJourneyId)
      const { response, data } = yield call(fetchTagStatisticsRequestFor3TagGroups, {
          params: { journeyToken, tagGroup1Hashid, tagGroup2Hashid, tagGroup3Hashid },
        });
        yield processAPIResponse({ response, data }, {
          * onSuccess(data) {
            yield put({
              type: 'saveTagStatistics',
              payload: data,
            });
          },
          * onError(error) {
            // Handle error
          },
        });
      },
    },
  
    reducers: {
      saveTagStatistics(state, action) {
        return {
          ...state,
            tagStatistics: action.payload.data ?? [],
        };
      },
      // ...
    },
  };