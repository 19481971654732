import React, {useState} from 'react';
import {PageContainer} from '@ant-design/pro-layout';
import {connect} from 'umi';
import {DispatchFunction} from "@/typings/models/ReduxState";
import Card from "@/components/Card";
import styles from "./index.less"
import SettingsMenuTabs, {SETTINGS_TABS} from "@/components/Account/SettingsMenuTabs";
import JourneyVisibilitySettings from "@/pages/Settings/JourneyVisibilitySettings";

interface ProfilePageProps {
  dispatch: DispatchFunction
}

const TAB_CONTENTS = {
  [SETTINGS_TABS.VISIBILITY.KEY]: JourneyVisibilitySettings
}
const SettingsPage: React.FC<ProfilePageProps> = ({dispatch}: ProfilePageProps) => {

  const [activeKey, setActiveKey] = useState()
  console.log(activeKey)
  const Content = TAB_CONTENTS[activeKey]
  return (
    <PageContainer>
      <SettingsMenuTabs activeKey={activeKey} setActiveKey={setActiveKey}/>
      <Card className={styles.card}>
        {Content && <Content/>}
      </Card>
    </PageContainer>
  );
};

export default connect()(SettingsPage);
