import { URLParamBuilder } from '@/utils/functions';
import * as PATHS from '@/constants/path';
import { request } from '@/services/api/request';
import {
  CreatePromotionConditionRequest,
  CreatePromotionTypeRequest,
  DeletePromotionConditionRequest,
  DeletePromotionRequest,
  EditPromotionConditionRequest,
  EditPromotionRequest, GetPromotionConditionsRequest,
  GetPromotionsRequest,
  GetPromotionTypesRequest,
} from '@/typings/api/getPromotions';

// region Fetch Promotions
type FetchEmailPromotionsRequestOptions = {
  params: GetPromotionsRequest['parameters'];
};

export function fetchEmailPromotionsRequest({ params }: FetchEmailPromotionsRequestOptions) {
  const url = URLParamBuilder(PATHS.FETCH_EMAIL_PROMOTION_LIST, {
    params,
  });

  return request(url, {
    method: 'GET',
  });
}

// endregion

// region Fetch Promotion Types
type FetchEmailPromotionTypesRequestOptions = {
  params: GetPromotionTypesRequest['parameters'];
};

export function fetchEmailPromotionTypesRequest({
  params,
}: FetchEmailPromotionTypesRequestOptions) {
  const url = URLParamBuilder(PATHS.FETCH_EMAIL_PROMOTION_TYPES, {
    params,
  });

  return request(url, {
    method: 'GET',
  });
}

// endregion

// region Add Promotion
type CreateEmailPromotionRequestOptions = {
  params: CreatePromotionTypeRequest['parameters'];
  payload: CreatePromotionTypeRequest['payload'];
};

export function createEmailPromotionRequest({
  params,
  payload,
}: CreateEmailPromotionRequestOptions) {
  const url = URLParamBuilder(PATHS.ADD_EMAIL_PROMOTION, {
    params,
  });

  return request(url, {
    method: 'POST',
    // @ts-ignore
    data: payload,
  });
}

// endregion

// region Add Promotion
type EditEmailPromotionRequestOptions = {
  params: EditPromotionRequest['parameters'];
  payload: EditPromotionRequest['payload'];
};

export function editEmailPromotionRequest({ params, payload }: EditEmailPromotionRequestOptions) {
  const url = URLParamBuilder(PATHS.EDIT_EMAIL_PROMOTION, {
    params,
  });

  return request(url, {
    method: 'PUT',
    // @ts-ignore
    data: payload,
  });
}

// endregion

// region Delete Promotion
type DeleteEmailPromotionRequestOptions = {
  params: DeletePromotionRequest['parameters'];
};

export function deleteEmailPromotionRequest({ params }: DeleteEmailPromotionRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_EMAIL_PROMOTION, {
    params,
  });

  return request(url, {
    method: 'DELETE',
  });
}

// endregion

// region Fetch promotion conditions

type FetchEmailPromotionConditionsRequestOptions = {
  params: GetPromotionConditionsRequest['parameters'];
};
export function fetchPromotionConditionsRequest({ params }: FetchEmailPromotionConditionsRequestOptions) {
  const url = URLParamBuilder(PATHS.FETCH_EMAIL_PROMOTION_CONDITIONS, {
    params
  });

  return request(url, {
    method: 'GET',
  });
}

// endregion

// region Add promotion conditions

type CreateEmailPromotionConditionRequestOptions = {
  params: CreatePromotionConditionRequest['parameters'];
  payload: CreatePromotionConditionRequest['payload'];
};

export function createPromotionConditionsRequest({params, payload}: CreateEmailPromotionConditionRequestOptions) {
  const url = URLParamBuilder(PATHS.ADD_EMAIL_PROMOTION_CONDITION, {
    params: params
  });

  return request(url, {
    method: 'POST',
    // @ts-ignore
    data: payload
  });
}

// endregion

// region Edit promotion conditions

type EditEmailPromotionConditionRequestOptions = {
  params: EditPromotionConditionRequest['parameters'];
  payload: EditPromotionConditionRequest['payload'];
};

export function editPromotionConditionsRequest({params, payload}: EditEmailPromotionConditionRequestOptions) {
  const url = URLParamBuilder(PATHS.EDIT_EMAIL_PROMOTION_CONDITION, {
    params: params
  });

  return request(url, {
    method: 'PUT',
    // @ts-ignore
    data: payload
  });
}

// endregion

// region Delete promotion conditions

type DeleteEmailPromotionConditionRequestOptions = {
  params: DeletePromotionConditionRequest['parameters'];
};

export function deletePromotionConditionsRequest({params}: DeleteEmailPromotionConditionRequestOptions) {
  const url = URLParamBuilder(PATHS.DELETE_EMAIL_PROMOTION_CONDITION, {
    params: params
  });

  return request(url, {
    method: 'DELETE',
  });
}

// endregion
