import { JourneyModel } from "@/typings/models/Journey";
import { processAPIResponse } from "@/utils/request";
import { fetchJourneyConfigRequest } from "@/services/api/journey";


const initialState = {
  list: [],
  selectedJourney: {},
  config: {}
}
export function selectCurrentJourneyId(state): (string | undefined) {
  return state.journey?.selectedJourney?.hashId
}


const model = {
  namespace: 'journey',

  state: {
    ...initialState
  },

  effects: {
    * fetchConfig({ payload = { journeyId: undefined } }, { call, put, select }) {
      const journeyId = yield select(selectCurrentJourneyId)
      if (!payload.journeyId) {
        payload.journeyId = journeyId
      }
      const { response, data } = yield call(fetchJourneyConfigRequest, { params: { journeyId: payload.journeyId } });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveConfig',
            payload: {
              config: data.configuration,
              journeyId: payload.journeyId
            }
          })
        },
        * onError(error) {

        }
      }, 'data')

    },
    *select({ payload }, { call, put }) {
      yield put({
        type: "fetchConfig",
        payload
      })

      yield put({
        type: "setSelectedJourney",
        payload
      })

    }
  },
  reducers: {
    gotJourneys: (state, action) => ({
      ...state,
      list: action.payload,
      selectedJourney: state.selectedJourney || action.payload[0]
    }),
    saveConfig: (state, action) => ({
      ...state,
      config: {
        ...state.config,
        [action.payload.journeyId]: action.payload.config
      }
    }),
    setSelectedJourney: (state, action) => {
      const { journey, journeyId } = action.payload

      return ({
        ...state,
        selectedJourney: journey ?? state.list.find(j => j.hashId === journeyId) ?? state.list[0]
      })
    },
    reset: (state, action) => {
      return { ...initialState }
    }
  }
};
export default model;
