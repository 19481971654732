import React from "react";
import get from "lodash/get";

import {DYNAMIC_FORM_FIELD_TYPES, FIELD_TYPE_COMPONENTS, getDynamicFormInputRules} from "@/utils/form";


export interface IDynamicField {
  type: string;
  title?: string;
  label?: string;
  validation?: string;
  key?: string;
  tooltip?: string;
  read_only?: boolean;
  default?:string
  placeHolder?:string
}
interface DynamicFieldProps {
  field: IDynamicField;
  form;
  getExtraFieldProps: (field: IDynamicField) => Object
  initialValue:Object
  onValuesChange
}

interface DynamicFormProps {
  fields: IDynamicField[];
  form;
  getExtraFieldProps: (field: IDynamicField) => Object
  initialValues:Object
  onValuesChange
}

const DynamicField: React.FC<DynamicFieldProps> = ({field, getExtraFieldProps,form,initialValue,onValuesChange}: DynamicFieldProps) => {
  const Component = get(FIELD_TYPE_COMPONENTS, field.type, FIELD_TYPE_COMPONENTS[DYNAMIC_FORM_FIELD_TYPES.STRING])
  const rules = getDynamicFormInputRules(field)
  const extraFieldProps = getExtraFieldProps ? getExtraFieldProps(field) : {}
  
  // @ts-ignore
  return <Component label={field.title || field.label || field.key} name={field.key} rules={rules}
                    tooltip={field.tooltip}
                    field={field}
                    placeHolder={field.placeHolder}
                    defaultValue={field.default}
                    form={form}
                    initialValue={initialValue}
                    onValuesChange={onValuesChange}
                    {...extraFieldProps} />
}

const DynamicForm: React.FC<DynamicFormProps> = ({fields, getExtraFieldProps,form,initialValues,onValuesChange}: DynamicFormProps) => {
  return <>
    {fields.map(field => <DynamicField
    initialValue = {initialValues?.[field.key]}
    onValuesChange={onValuesChange}
    form={form}  getExtraFieldProps={getExtraFieldProps}
                                       key={field.key}
                                       field={field}/>)}
  </>
};

export default DynamicForm
