import {FormattedMessage as F} from "umi";
import * as React from "react";

export const CHART_MODELS = {
  REGISTRATIONS: 'registrations',
  SERVICE_REQUEST: 'services',
  SUBSCRIBERS: 'subscribers',
}
export const CHART_RADIO_GROUP_OPTIONS = [
  {
    title: <F
      id="component.registrationGrowth.change"
      defaultMessage="Change"
    />,
    value: "change"
  },
  {
    title: <F
      id="component.registrationGrowth.cumulative"
      defaultMessage="Cumulative"
    />,
    value: "cumulative"
  }]
export const CHART_LABEL_OPTIONS = [{
  label: <F
    id="component.registrationGrowth.title"
    defaultMessage="Registration"
  />,
  value: CHART_MODELS.REGISTRATIONS
}, {
  label: <F
    id="component.requestGrowth.title"
    defaultMessage="Requests"
  />,
  value: CHART_MODELS.SERVICE_REQUEST
}, {
  label: <F
    id="component.subscriberGrowth.title"
    defaultMessage="Subscribers"
  />,
  value: CHART_MODELS.SUBSCRIBERS
}]
export const REDUX_ACTIONS = {
  [CHART_MODELS.REGISTRATIONS]: "analytics/fetchRegistrationStats",
  [CHART_MODELS.SERVICE_REQUEST]: "analytics/fetchServiceRequestStats",
  [CHART_MODELS.SUBSCRIBERS]: "analytics/fetchSubscriberStats",
}
export const CHART_HINTS = {
  [CHART_MODELS.REGISTRATIONS]: <F
    id="component.registrationGrowth.hint"
    defaultMessage="Number of newly registered users with or without appointment"
  />,
  [CHART_MODELS.SERVICE_REQUEST]: <F
    id="component.requestGrowth.hint"
    defaultMessage="Number of customers with at least one appointment."
  />,
  [CHART_MODELS.SUBSCRIBERS]: <F
    id="component.subscriberGrowth.hint"
    defaultMessage="Number of active subscribers for email retargeting."
  />,
}
