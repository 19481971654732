export default {
  // region At A Glance - Dashboard Page
  'component.atAGlance.totalCustomers': 'Klanten',
  'component.atAGlance.totalCustomers.tooltip': 'Alle klanten die de vragenlijst hebben ingevuld.',
  'component.atAGlance.totalRequests': 'Aanvragen',
  'component.atAGlance.totalRequests.tooltip': 'Alle klanten die een afspraak hebben aangevraagd.',
  'component.atAGlance.totalSubscribers': 'Abonnees',
  'component.atAGlance.totalSubscribers.tooltip':
    'Alle klanten zonder afspraak die hun e-mailadres hebben bevestigd voor retargeting.',
    'component.atAGlance.sentMails': 'Verzonden e-mails',
  // endregion

  // region Registrations - Dashboard Page
  'component.registrationsChart.day': 'Dag',
  'component.registrationsChart.week': 'Week',
  'component.registrationsChart.month': 'Maand',
  'component.registrationsChart.year': 'Jaar',
  'component.registrationsChart.subscribers': 'Abonnees',
  // endregion

  // region ServiceRequest - Dashboard Page
  'component.serviceRequest.cardTitle': 'Laatste service aanvragen',
  'component.serviceRequest.cardTooltip':
    'Toont huidige aanvragen van klanten die een afspraak hebben aangevraagd',
  'component.serviceRequest.showAll': 'Toon alle',
  'component.serviceRequest.scrollMore': 'Scroll naar beneden voor meer',
  'component.serviceRequest.title': 'Service aanvrag',
  'component.serviceRequest.drawerDefaultLabel': 'Details serviceaanvraag',
  'component.serviceRequest.noMoreLoad': 'Niet meer te laden ...',
  // endregion

  // region CustomerDrawer
  'component.CustomerDrawer.qualification': 'Kwalificatie',
  'component.CustomerDrawer.status': 'Status',
  'component.CustomerDrawer.notes': 'Opmerkingen',
  'component.CustomerDrawer.profile': 'Profiel',
  'component.CustomerDrawer.styles': 'Stijlen',
  'component.CustomerDrawer.results': 'Resultaten',
  'component.CustomerDrawer.unsubscribe': 'Afmelden',
  'component.CustomerDrawer.showProfile': 'Toon profiel',
  // endregion

  // region LocationDrawer - Locations Page
  'component.locationDrawer.availability': 'Beschikbaarheid',
  'component.locationDrawer.addOpeningHours': 'Terugkerende openingsuren toevoegen',
  'component.locationDrawer.currentOpeningHours': 'Huidige terugkerende openingstijden',
  'component.locationDrawer.saturday': 'Zaterdag',
  'component.locationDrawer.sunday': 'Zondag',
  'component.locationDrawer.monday': 'Maandag',
  'component.locationDrawer.tuesday': 'Dinsdag',
  'component.locationDrawer.wednesday': 'Woensdag',
  'component.locationDrawer.thursday': 'Donderdag',
  'component.locationDrawer.friday': 'Vrijdag',
  'component.locationDrawer.from': 'Van',
  'component.locationDrawer.to': 'Tot',
  'component.locationDrawer.blockHours': 'Blok Speciale Uren',
  'component.locationDrawer.add': 'Toevoegen',
  // endregion

  'component.tagSelect.expand': 'Uitbreiden',
  'component.tagSelect.collapse': 'Instorten',
  'component.tagSelect.all': 'Alle',

  // region PeriodSelector
  'component.periodSelector.day': 'Dag',
  'component.periodSelector.week': 'Week',
  'component.periodSelector.month': 'Maand',
  // endregion

  // region Table
  'component.table.downloadCSV': 'CSV downloaden',
  'component.table.showing': 'Toont',
  'component.table.of': ' van de ',
  'component.table.items': 'records',
  'component.table.page': 'Pagina',

  // endregion

  // region Account component
  'component.account.profile': 'Profiel',
  'component.account.billings': 'Factureringen',
  'component.account.language': 'Taal',
  'component.account.language.select': 'Selecteer uw taal:',
  // endregion

  // region Registration Growth
  'component.registrationGrowth.title': 'Registraties',
  'component.registrationGrowth.hint':
    'Aantal nieuw geregistreerde gebruikers met of zonder afspraak',
  'component.registrationGrowth.change': 'Verander',
  'component.registrationGrowth.cumulative': 'Totaal',
  // endregion

  // region User Menu Dropdown
  'component.userMenu.settings': 'Instellingen',
  'component.userMenu.billing': 'Facturering',
  'component.userMenu.support': 'Steun',
  'component.userMenu.signOut': 'Uitschrijven',


  // region MApWithAutoComplete
  'component.mapWithAutoComplete.addressPlaceHolder': 'Veuillez entrer l\'adresse',
  'component.mapWithAutoComplete.addressError': "Adres niet gevonden. Als u de locatiezoekfunctie wilt gebruiken, gebruik dan een geldig adres.",
  // endregion

  // region Account
  'component.settings.visibility': 'Zichtbaarheid',
   // endregion
};
