import {List} from 'antd';
import React from 'react';
import styles from './index.less';

export interface TableListItem {
  label: React.ReactNode | string | number;
  value: string | number | React.ReactNode
}

const TableList: React.FC<{dataFields: TableListItem[]}> = ({ dataFields}) => {
  return <List
    bordered
    dataSource={dataFields}
    renderItem={ item => (
      <List.Item className={styles.listItem}>
        {item.label}
        {item.value}
      </List.Item>
    )}
  />
};

export default TableList;

