import TableList from "@/components/TableList";
import {ProColumns} from "@ant-design/pro-table";
import {Typography} from "antd";

// Uses table columns definition to create a function that takes in row data and returns an expandable row component
// If render function defined on column uses it for the value otherwise uses the value itself
// If label defined on the column uses the component, otherwise uses dataIndex
function createExpandedRowFromColumns<T = object>(cols: ProColumns<any>[]) {
  return (row: T) => {
      const expandedRowData = cols.map(
        (column, index) => {
        let renderComp = null;
        if (column.render && column.dataIndex) {
          // @ts-ignore
          renderComp = column.render(row[column.dataIndex as string], row)
        } else {
          renderComp = <Typography.Text
            key={column.dataIndex ? index : column.dataIndex}>{row[column.dataIndex as string] ?? null}</Typography.Text>
        }

        return {
          label: column.title ??  (<Typography.Text key={index}>{column.dataIndex ?? 'Untitled'}</Typography.Text>),
          value: renderComp
        }
      });

      return <TableList dataFields={expandedRowData} />
    }
}

export default createExpandedRowFromColumns;
