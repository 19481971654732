import React from "react";
import ProForm, {ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import {Form} from "antd";
import {useIntl} from "umi";
import FORM_TYPES from "@/constants/formTypes"
import {getFormBlurProps} from "@/utils/form";
import {PromotionConditionModel, PromotionModel} from "@/typings/models/Promotion";

interface PromotionConditionFormProps {
  onChange?: (promotion: PromotionModel) => void,
  initialValues?: PromotionConditionModel,
}

const PromotionConditionForm: React.FC<PromotionConditionFormProps> = ({onChange, initialValues = {}}) => {

  const [form] = Form.useForm()
  const intl = useIntl();

  function onFormChange(changedValues = null, values = form.getFieldsValue()) {
    onChange && onChange(values)
  }
  return (
    <div>
      <ProForm
        form={form}
        submitter={false}
        initialValues={initialValues}
        onValuesChange={onFormChange}
      >
        <ProFormText
          label={intl.formatMessage({
            id: 'pages.promotions.title',
            defaultMessage: 'Title',
          })}
          required
          validateStatus={'validating'}
          hasFeedback
          {...getFormBlurProps({
            type: FORM_TYPES.TEXT,
            name: 'name'
          })}
        />
        <ProFormTextArea
          label={intl.formatMessage({
            id: 'pages.promotions.promotionCondition',
            defaultMessage: 'Text',
          })}
          required
          validateStatus={'validating'}
          hasFeedback
          {...getFormBlurProps({
            type: FORM_TYPES.TEXT,
            name: 'condition'
          })}
        />
      </ProForm>
    </div>
  );
}

export default PromotionConditionForm
