import React, { useEffect, useState } from 'react';
import { LocationAvailability, LocationModel, LocationPeriod } from '@/typings/models/Location';
import { Button, Popconfirm } from 'antd';
import { useDispatch, FormattedMessage as F } from 'umi';
import styles from './index.less';
import Card from '@/components/Card';
import { CloseCircleOutlined } from '@ant-design/icons';
import MyDateTimeRangePicker from '@/components/MyDateTimePicker';
import DateFormat from '@/components/format/DateFormat';
import moment, { Moment } from 'moment';
import {
  getLocationAllowDateTimes,
  getLocationBlockPeriods,
  getLocationOpenPeriods,
} from '@/utils/location';

interface LocationAvailabilityFormProps {
  location: LocationModel;
  mode: string;
}

type ILocationPeriodsFormProps = {
  periods: LocationPeriod[];
  mode: string;
  onChange: (availability: LocationPeriod[]) => void;
};

const LocationPeriodsForm: React.FC<ILocationPeriodsFormProps> = ({
                                                                    periods,
                                                                    onChange,
                                                                    mode,
                                                                  }: ILocationPeriodsFormProps) => {
  function removeBlockPeriod(index: number) {
    const newPeriods = [...periods];
    newPeriods.splice(index, 1);
    onChange(newPeriods);
  }

  if ((periods || []).length === 0) {
    return null;
  }

  return (
    <div className={styles.blockPeriods}>
      <div className={styles.list}>
        {periods.map((period, index) => (
          <Card mini className={styles.card} key={period.start}>
            <div className={styles.inner}>
              <div>
                <div className={styles.from}>
                  <span className={styles.key}>
                    <F id={'pages.locations.from'} defaultMessage={'From'} />
                  </span>
                  <span className={styles.value}>
                    <DateFormat ignoreLocalTimezone date={period.start} />
                  </span>
                </div>
                <div className={styles.to}>
                  <span className={styles.key}>
                    <F id={'pages.locations.to'} defaultMessage={'To'} />
                  </span>
                  <span className={styles.value}>
                    <DateFormat ignoreLocalTimezone date={period.end} />
                  </span>
                </div>
              </div>
              <div className={styles.action}>
                <Popconfirm
                  onConfirm={() => new Promise(() => removeBlockPeriod(index))}
                  placement={'topRight'}
                  title={
                    mode === 'block' ? (
                      <F
                        id={'pages.location.removeBlockPeriod'}
                        defaultMessage={'Are you sure to remove this block period?'}
                      />
                    ) : (
                      <F
                        id={'pages.location.removeOpenPeriod'}
                        defaultMessage={'Are you sure to remove this open period?'}
                      />
                    )
                  }
                >
                  <a>
                    <CloseCircleOutlined />
                  </a>
                </Popconfirm>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

type IAddLocationPeriodProps = { defaultRange?: Moment[]; onAdd: (period: LocationPeriod) => void };
export const AddLocationPeriod: React.FC<IAddLocationPeriodProps> = ({
                                                                       onAdd,
                                                                       defaultRange = [],
                                                                     }: IAddLocationPeriodProps) => {
  const [range, setRange] = useState<Moment[]>(defaultRange);

  function onSubmit() {
    const rangeISODates = range.map((date) => date.format());
    onAdd({
      start: rangeISODates[0],
      end: rangeISODates[1],
    });
  }

  function isDisabled() {
    if (!range) {
      return true;
    }
    if (!Array.isArray(range)) {
      return true;
    }
    return !range[0] || !range[1];
  }

  return (
    <div className={styles.addLocationAvailability}>
      <div className={styles.inner}>
        <MyDateTimeRangePicker onChange={setRange} ranges={range} />
        <Button
          className={'button-secondary'}
          disabled={isDisabled()}
          onClick={onSubmit}
          type={'primary'}
        >
          <F id={'pages.common.add'} defaultMessage={'Add'} />
        </Button>
      </div>
    </div>
  );
};
const PeriodForm: React.FC<LocationAvailabilityFormProps> = ({
                                                               location,
                                                               mode,
                                                             }: LocationAvailabilityFormProps) => {
  const [periods, setPeriods] = useState<LocationPeriod[] | undefined>([]);

  const dispatch = useDispatch();

  function removeLocalTimezone(period: LocationPeriod) {
    const DATE_WITHOUT_TIMEZONE_FORMAT = 'YYYY-M-D[T]HH:mm:ss';

    return {
      start: moment(period.start).format(DATE_WITHOUT_TIMEZONE_FORMAT),
      end: moment(period.end).format(DATE_WITHOUT_TIMEZONE_FORMAT),
    };
  }
  function changed(periodList: LocationPeriod[]) {
    let newAvailability: LocationAvailability[] = getLocationAllowDateTimes(location).map(
      (allow) => ({
        allow_day_and_time: allow,
      }),
    );

    if (mode === 'block') {
      const openPeriods = getLocationOpenPeriods(location).map((openPeriod) => ({
        allow_period: removeLocalTimezone(openPeriod),
      }));
      newAvailability = newAvailability.concat(openPeriods).concat(
        periodList.map((blockPeriod) => ({
          block_period: removeLocalTimezone(blockPeriod),
        })),
      );
    } else {
      const blockPeriods = getLocationBlockPeriods(location).map((blockPeriod) => ({
        block_period: removeLocalTimezone(blockPeriod),
      }));
      newAvailability = newAvailability.concat(blockPeriods).concat(
        periodList.map((openPeriod) => ({
          allow_period: removeLocalTimezone(openPeriod),
        })),
      );
    }

    dispatch({
      type: 'location/change',
      payload: {
        locationId: location.id,
        Availability: newAvailability,
      },
    });
  }

  function onAdd(period: LocationPeriod) {
    const newPeriods = [...(periods || []), period];
    changed(newPeriods);
  }

  useEffect(() => {
    const periods =
      mode === 'block' ? getLocationBlockPeriods(location) : getLocationOpenPeriods(location);
    setPeriods(periods as LocationPeriod[]);
  }, [location]);

  return (
    <div>
      <AddLocationPeriod onAdd={onAdd} />
      <LocationPeriodsForm mode={mode} onChange={changed} periods={periods as LocationPeriod[]} />
    </div>
  );
};

export default PeriodForm;
