import React, {useEffect, useState} from 'react';
import {connect, useIntl, useSelector} from 'umi';
import ProForm, {ProFormSwitch} from "@ant-design/pro-form";
import {QuestionCircleFilled} from "@ant-design/icons";
import {Col, Form, message, Row, Space, Tabs, Tooltip} from "antd";
import pick from "lodash/pick";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import ProFormMultipleTagsFormItem from "@/components/Form/MultipleTagsFormItem";
import {selectCurrentJourneyConfig} from "@/services/selectors";
import {editJourneyConfigurationRequest} from "@/services/api/journey";
import JourneyVisibilitySettingsExamples from "@/pages/Settings/JourneyVisibilitySettingsExamples";
import {DispatchFunction} from "@/typings/models/ReduxState";

import styles from "./index.less"

const {TabPane} = Tabs;

interface JourneyVisibilitySettingsProps {
  dispatch: DispatchFunction
}

const TABS = {
  BLACKLIST: 'blackList',
  WHITELIST: 'whiteList'
}
const JourneyVisibilitySettings: React.FC<JourneyVisibilitySettingsProps> = ({dispatch}: JourneyVisibilitySettingsProps) => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState<string>("blackList");
  const [disabled, setDisabled] = useState<boolean>(false);
  const intl = useIntl();
  const journeyConfig = useSelector(selectCurrentJourneyConfig)

  const getInitialValues = () => pick(journeyConfig, ['blackList', 'whiteList', 'disabled'])
  useEffect(() => {
    const values = form.getFieldsValue()
    const isPopulated = Object.entries(values).map((key, value) => !isEmpty(value)).some(Boolean)
    if (!isPopulated && !isEmpty(journeyConfig)) {
      const initialValues = getInitialValues()
      const disabledValue = !get(initialValues, 'disabled')
      setDisabled(disabledValue)
      form.setFieldsValue({
        ...initialValues,
        disabled: disabledValue
      })
    }
  }, [JSON.stringify(journeyConfig)])

  const handleTabChange = tab => {
    const values = form.getFieldsValue()

    if ((tab === TABS.BLACKLIST && (values.whiteList || []).length > 0) || (tab === TABS.WHITELIST && (values.blackList || []).length > 0))
      return message.warning(intl.formatMessage({
        id: 'pages.settings.visibility.clearRules'
      }))

    setActiveTab(tab)
  }
  const getDisabledKeyValue = (value) => (value ? "0" : "1")
  const onValuesChange = (changedValues) => {
    const journeyId = journeyConfig.id
    if ('disabled' in changedValues) setDisabled(changedValues.disabled)
    const promises = Object.entries(changedValues).map((([key, value]) => {
      return editJourneyConfigurationRequest({
        params: {
          journeyId,
          configurationKey: key
        },
        data: key === "disabled" ? getDisabledKeyValue(value) : value
      })
    }))
    Promise.all(promises)
      .then(() => {
        message.success(intl.formatMessage({
          id: 'pages.settings.configuration.editSuccessMessage',
          defaultMessage: "Configuration updated successfully."
        }))
      }).finally(() => {
      dispatch({
        type: "journey/fetchConfig",
        payload: {
          journeyId
        }
      })
    })
  }
  return (
    <Row gutter={24}>
      <Col xl={12} md={24} sm={24} xs={24}>
        <ProForm
          form={form}
          onValuesChange={onValuesChange}
          submitter={{
            resetButtonProps: {
              style: {
                display: "none"
              },
            },
            submitButtonProps: {
              style: {
                display: 'none'
              }
            },
          }}
        >
          <ProFormSwitch
            name={"disabled"}
            tooltip={intl.formatMessage({
              id: 'pages.settings.visibility.active.tooltip',
              defaultMessage: 'You can deactivate your Journey on all pages',
            })}
            label={intl.formatMessage({
              id: 'pages.settings.visibility.active',
              defaultMessage: 'Active',
            })}
          />
          <Tabs className={classNames({
            [styles.inactiveSettings]: !disabled
          })} size={"small"} defaultActiveKey="blackList" animated onChange={handleTabChange} activeKey={activeTab}>
            <TabPane tab={<Space>
              {intl.formatMessage({
                id: 'pages.settings.visibility.blackList',
                defaultMessage: 'Exclude pages',
              })}
              <Tooltip title={intl.formatMessage({
                id: 'pages.settings.visibility.blackList.tooltip',
                defaultMessage: 'The journey will only be shown on the here listed pages',
              })}>
                <QuestionCircleFilled/>
              </Tooltip>
            </Space>} key={TABS.BLACKLIST}>
              <ProFormMultipleTagsFormItem addInputStyles={{width: 250}} name={"blackList"} type={"text"}/>
            </TabPane>
            <TabPane tab={
              <Space>
                {intl.formatMessage({
                  id: 'pages.settings.visibility.whiteList',
                  defaultMessage: 'Show only on',
                })}
                <Tooltip title={intl.formatMessage({
                  id: 'pages.settings.visibility.whiteList.tooltip',
                  defaultMessage: 'The Journey will be shown on all pages except the listed ones',
                })}>
                  <QuestionCircleFilled/>
                </Tooltip>
              </Space>
            } key={TABS.WHITELIST}>
              <ProFormMultipleTagsFormItem addInputStyles={{width: 250}} name={"whiteList"} type={"text"}/>
            </TabPane>
          </Tabs>
        </ProForm>
      </Col>
      <Col xl={12} md={24} sm={24} xs={24}>
        {/*<img src={image} style={{width: "100%"}} alt=""/>*/}
        <JourneyVisibilitySettingsExamples/>
      </Col>

    </Row>
  );
};

export default connect()(JourneyVisibilitySettings);
