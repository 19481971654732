import * as React from 'react'
import styles from "./index.less"

interface UserLayoutPictureProps {
children?: any
}

const UserLayoutPicture: React.FC<UserLayoutPictureProps> = ({children}: UserLayoutPictureProps) => {

  return <div className={styles.userLayoutPicture} style={{backgroundImage: "url('/login.png')"}}/>
}

export default (UserLayoutPicture)
