import React, {ReactNode} from 'react';
import styles from './index.less';
import classNames from "classnames";
import {NavLink} from 'umi';

interface LogoAndTitleProps {
  logo?: ReactNode,
  titleClassName?: string
}

const LogoAndTitle: React.FC<LogoAndTitleProps> = ({logo = <img src={"/logo.svg"} alt=""/>, titleClassName = ""}: LogoAndTitleProps) => {
  return (
    <NavLink to={"/"}>
      <div className={styles.logoAndTitle}>
      <span className={styles.logo}>
        {logo}
      </span>
        <span className={classNames(styles.title, titleClassName)}>
        CONVERSIONAL
      </span>
      </div>
    </NavLink>
  );
};

export default React.memo(LogoAndTitle);
