import { fetchMeRequest, refreshTokenRequest } from '@/services/api/user';
import storage, {ENGINES} from 'conversional-persistent-storage';
import { UserTokens } from '@/typings/models/User';
import { RequestResponse } from 'umi-request';
import get from 'lodash/get';
import { resetPersistentReduxStore } from '@/utils/persistStore';

const ACCESS_TOKEN_LS_KEY = 'at';
const REFRESH_TOKEN_LS_KEY = 'rt';
const LOGIN_TTL = 6 * 60 * 100000;
const STORAGE_ENGINE = ENGINES.LOCAL_STORAGE

export function setAuthenticationTokens(tokens: UserTokens): void {
  storage.setItem(ACCESS_TOKEN_LS_KEY, tokens.access_token, { ttl: LOGIN_TTL }, STORAGE_ENGINE);
  storage.setItem(REFRESH_TOKEN_LS_KEY, tokens.refresh_token, { ttl: LOGIN_TTL }, STORAGE_ENGINE);
}

export function getAccessToken(): string | null {
  return storage.getItem(ACCESS_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
}

export function getRefreshToken(): string | null {
  return storage.getItem(REFRESH_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
}

export function getAuthorizationHeader(): { Authorization?: string } {
  const accessToken = getAccessToken();
  return accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : {};
}

export function isExpiredToken(response: RequestResponse): boolean {
  const EXPIRE_MESSAGE = 'Expired Token';
  return response?.data?.message === EXPIRE_MESSAGE;
}

export function clearCredentials(): void {
  storage.removeItem(ACCESS_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
  storage.removeItem(REFRESH_TOKEN_LS_KEY, {}, STORAGE_ENGINE);
  resetPersistentReduxStore();
}

export function authCheck(): Promise<unknown> {
  return new Promise((resolve, reject) => {
    fetchMeRequest().then((UserAPICall) => {
      const status = get(UserAPICall, 'response.status');
      const data = get(UserAPICall, 'data', {});
      if (status === 200) {
        resolve(data);
      } else {
        reject(data);
      }
    });
  });
}

export function logOut(): void {
  clearCredentials();
}

export function refreshToken(): Promise<unknown> {
  return new Promise((resolve) => {
    refreshTokenRequest({
      refresh_token: getRefreshToken(),
    }).then((result) => {
      const data = get(result, 'data', {});
      setAuthenticationTokens(data);
      resolve(data);
    });
  });
}
