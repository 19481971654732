import React, {useCallback, useEffect} from 'react';
import {connect, FormattedMessage as F} from 'umi';
import {DispatchFunction} from '@/typings/models/ReduxState';
import {List, Spin} from 'antd';
import {JourneyModel} from '@/typings/models/Journey';
import InfiniteScroll from 'react-infinite-scroller';
import {RecentServiceRequestItem, ServiceRequestModel} from '@/typings/models/ServiceRequest';
import ReportItem from '@/components/ReportItem';
import CardComponent from '@/components/Card';
import CustomerName, {getCustomerText} from '@/components/Customer/CustomerName';
import {GenericPaginatedList} from '@/typings/models/Common';
import debounce from "lodash/debounce";

export type InfiniteServiceRequestsListProps = {
  dispatch: DispatchFunction,
  list: GenericPaginatedList<ServiceRequestModel>,
  loading: boolean,
  menuVisible?: boolean,
  selectedJourney: JourneyModel
};

const InfiniteServiceRequestsList: React.FC<InfiniteServiceRequestsListProps> = ({list, loading, dispatch, date}) => {


  function hasMore(){
    try{
      return list.total > list.items.length
    }catch(e){
      return true
    }
  }

  const fetchData = useCallback(debounce(() =>{
    handleInfiniteOnLoad({
      initial: true
    })
  }, 200), [])

  useEffect(() => {
    fetchData()
  }, [date.updatedAt])

  const handleInfiniteOnLoad = ({initial} : {initial?: boolean}) => {
    if (!hasMore()){
      return
    }
    if (!loading)
      dispatch({
        type: "serviceRequest/fetchInfinite",
        payload: {
          reset: initial
        }
      })
  };

  const LoadingComponent = (
    <div style={{textAlign: "center", padding: "20px"}}>
      <Spin
        size="large"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </div>
  );

  if (!list) {
    return LoadingComponent;
  }

  function getDataSource(){
    try{
      return list.items
    }catch(e){
      return []
    }
  }
  return (
    <CardComponent title={
      <F
      id="component.serviceRequest.title"
      defaultMessage="Service Requests"
      />
    }>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        threshold={10}
        loadMore={handleInfiniteOnLoad}
        hasMore={hasMore()}
        useWindow={true}
      >
        <List
          dataSource={getDataSource()}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          renderItem={(request: RecentServiceRequestItem, index) => (
            <List.Item key={request.ServiceRequest.id}>
              <ReportItem
                key={index}
                avatarTitle={getCustomerText(request.Customer)}
                title={<a onClick={() => dispatch({
                  type: 'serviceRequest/toggleDrawer',
                  payload:{
                    serviceRequest: request
                  }
                })}>{request.ServiceRequest.what}</a>}
                // TODO: If we want to open a drawer, the customer string id should be sent in the API response
                description={<CustomerName customer={request.Customer}/>}
                extraInfo={[request.ServiceRequest.where, request.ServiceRequest.created]}
              />
            </List.Item>
          )}
        >
          {loading && hasMore() && (
            LoadingComponent
          )}

          {!loading && !hasMore() && (
            <div style={{textAlign: "center"}}>
              <F
                id="component.serviceRequest.noMoreLoad"
                defaultMessage="No More to Load ..."
                />
            </div>
          )}
        </List>
      </InfiniteScroll>
    </CardComponent>
  );
};

export default connect(({serviceRequest: {infiniteList}, loading, date}) => ({
  list: infiniteList,
  date,
  loading: loading.effects['serviceRequest/fetchInfinite']
}))(InfiniteServiceRequestsList);
