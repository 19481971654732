import * as React from 'react'
import {Button, Card, Divider} from "antd";
import MyRangePicker from "@/components/MyRangePicker";
import PeriodSelector from "@/components/PeriodSelector";
import {connect} from 'umi';
import {DispatchFunction} from "@/typings/models/ReduxState";
import Histogram from "@/components/charts/Histogram";
import Funnel from "@/components/charts/Funnel";
import Ring from '@/components/charts/Ring';
import Area from '@/components/charts/Area';
import AccountMenuTabs from "@/components/Account/AccountMenuTabs";
import MyTimeRangePicker from "@/components/MyTimePicker";
import WeekDayPicker from "@/components/WeekDayPicker";
import MyDateTimeRangePicker from "@/components/MyDateTimePicker";
import UserMenuDropdown from "@/components/UserMenuDropdown";
import JourneyAccordion from "@/components/JourneyAccordion";


type StoryBookProps = {
  dispatch: DispatchFunction,
  serviceRequestLoading: boolean
}
const Storybook: React.FC<StoryBookProps> = ({dispatch, serviceRequestLoading}) => {

  function performFetchServiceRequestAPICall() {
    dispatch({
      type: "serviceRequest/fetch",
      payload: {
        journeyId: "SAMPLE_JOURNEY"
      }
    })
  }

  return <div>
    <Card title={"RangePicker"}>
      <MyRangePicker global disableDate={{
        notAfterToday: true
      }}/>
    </Card>
    <Divider/>
    <Card title={"PeriodSelector"}>
      <PeriodSelector size={"large"} buttonStyle={"solid"}/>
    </Card>
    <Divider/>
    <Divider/>
    <Card title={"Sample API Call"}>
      <Button loading={serviceRequestLoading} onClick={performFetchServiceRequestAPICall}>Perform API Call</Button>
    </Card>
    <Divider/>
    <Histogram helpTooltip={"This is Sample Help Tooltip"} cardTitle={"Sample Chart"}
               chartData={{Tobias: 4, Pouya: 5, Clemens: 8, Rafael: 2}}/>
    <Divider/>
    <Funnel cardTitle={"Sample Funnel"} chartData={[{step: "process_started", total_events: 285, total_users: 184},
      {step: "first_question", total_events: 116, total_users: 85},
      {step: "last_question", total_events: 118, total_users: 67},
      {step: "process_completed", total_events: 94, total_users: 65},
      {step: "appointment", total_events: 61, total_users: 42}]}/>
    <Divider/>
    <Ring cardTitle={"Test Ring"} chartData={{Tobias: 4, Pouya: 5, Clemens: 8, Rafael: 2}}/>
    <Divider/>
    <Area cardTitle={"Test Area"}/>
    <Divider/>
    <AccountMenuTabs/>
    <Divider/>
    <MyTimeRangePicker/>
    <Divider/>
    <WeekDayPicker/>
    <Divider/>
    <MyDateTimeRangePicker/>
    <Divider/>
    <UserMenuDropdown />
    <Divider/>
    <JourneyAccordion mini/>
  </div>
}

export default connect(({loading}) => ({
  serviceRequestLoading: loading.effects["serviceRequest/fetch"]
}))(Storybook)
