import styles from './index.less';
import React, {useEffect, useState} from 'react';
import {useDispatch, useIntl, useSelector, FormattedMessage as F} from 'umi';
import Drawer from '@/components/Drawer';
import LocationName from '@/components/Location/LocationName';
import {LocationAvailability, LocationModel} from '@/typings/models/Location';
import DrawerSection from '@/components/Drawer/DrawerSection';
import LocationDetailsForm from '@/components/LocationDrawer/LocationDetailsForm';
import LocationAvailabilityForm from '@/components/LocationDrawer/LocationAvailabilityForm';
import {Button, message, Space, Spin} from 'antd';
import LocationResources from '@/components/LocationDrawer/LocationResources';
import LocationPeriodsForm from '@/components/LocationDrawer/LocationPeriodsForm';
import LocationMetaForm from "@/components/LocationDrawer/LocationMetaForm";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const LocationDrawer: React.FC = () => {
  const [newLocationFormData, setNewLocationFormData] = useState<LocationModel>({});

  const dispatch = useDispatch();

  const {
    visible,
    availabilityLoading = false,
    location,
    createLocationLoading,
  } = useSelector(({location: {drawer, list, changeLoadings}, loading}: DefaultRootState) => ({
    ...drawer,
    location:
      drawer.locationId === 'NEW'
        ? {id: 'NEW'}
        : list.find((l) => l.id === drawer.locationId) || {},
    createLocationLoading: loading.effects['location/create'],
    availabilityLoading: changeLoadings.Availability,
  }));

  function toggle() {
    dispatch({
      type: 'location/toggleDrawer',
      payload: {
        customer: null,
      },
    });
  }

  useEffect(() => {
    setNewLocationFormData({});
  }, [visible]);

  function isNewLocation() {
    return location.id === 'NEW';
  }

  function handleAvailabilityChange(availability: LocationAvailability[]) {
    setNewLocationFormData({
      ...newLocationFormData,
      weekdays: availability.map((a) => a.allow_day_and_time),
    });
  }

  function handleFormChange(locationData) {
    setNewLocationFormData({
      ...newLocationFormData,
      ...locationData,
    });
  }

  function validateNewLocationForm() {
    return (
      newLocationFormData.location &&
      newLocationFormData.address &&
      newLocationFormData.email
    );
  }

  function onCreateLocation() {
    if (!validateNewLocationForm()) {
      message.error(
        intl.formatMessage({
          id: 'pages.common.form.validationError',
        }),
      );
      return;
    }
    dispatch({
      type: 'location/create',
      payload: {
        ...newLocationFormData,
        name: newLocationFormData.location,
      },
    });
  }

  function getFooter() {
    if (!isNewLocation()) return null;

    return (
      <div style={{textAlign: 'right'}}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            <F id={'app.settings.close'} defaultMessage={'Close'}/>
          </Button>
          <Button
            size={'large'}
            loading={createLocationLoading}
            onClick={onCreateLocation}
            type={'primary'}
          >
            <F id={'pages.location.addLocation'} defaultMessage={'Add location'}/>
          </Button>
        </Space>
      </div>
    );
  }

  function getTitle() {
    return isNewLocation() ? (
      intl.formatMessage({
        id: 'pages.locations.newLocation',
        defaultMessage: 'New Location',
      })
    ) : (
      <LocationName location={location} drawer={false}/>
    );
  }

  const intl = useIntl();


  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={520}
        footer={getFooter()}
        title={getTitle()}
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <DrawerSection
          title={intl.formatMessage({
            id: 'pages.location.edit',
            defaultMessage: 'Edit',
          })}
        >
          <LocationDetailsForm
            isNew={isNewLocation()}
            onChange={isNewLocation() ? handleFormChange : undefined}
            location={location}
          />
        </DrawerSection>
        <Spin spinning={availabilityLoading}>
          <DrawerSection
            title={intl.formatMessage({
              id: 'pages.locations.availability',
              defaultMessage: 'Open Hours',
            })}
          >
            <LocationAvailabilityForm
              onChange={isNewLocation() ? handleAvailabilityChange : undefined}
              location={location}
            />
          </DrawerSection>
          {!isNewLocation() && (
            <>
              <DrawerSection
                title={intl.formatMessage({
                  id: 'pages.locations.blockHours',
                  defaultMessage: 'Block Hours',
                })}
              >
                <LocationPeriodsForm mode={'block'} location={location} />
              </DrawerSection>
              <DrawerSection
                title={intl.formatMessage({
                  id: 'pages.locations.additionalOpenHours',
                  defaultMessage: 'Additional Open Hours',
                })}
              >
                <LocationPeriodsForm mode={'open'} location={location} />
              </DrawerSection>
              <DrawerSection
                title={intl.formatMessage({
                  id: 'pages.locations.resources',
                  defaultMessage: 'Resources',
                })}
              >
                <LocationResources location={location} />
              </DrawerSection>
            </>
          )}
        </Spin>
        <LocationMetaForm
          isNew={isNewLocation()}
          location={location}
          onChange={isNewLocation() ? handleFormChange : undefined}
        />
      </Drawer>
    </div>
  );
};

export default LocationDrawer;
