import React from 'react';
import MyPageContainer from "@/components/MyPageContainer";
import {Col, Row} from 'antd';
import InfiniteServiceRequestsList from "@/components/InfiniteServiceRequestsList";
import DashboardScore from "@/components/DashboardScore";
import TrendCharts from "@/components/TrendCharts";

const TRACKING_LABEL = "Dashboard"
const Dashboard: React.FC = () => {
  return (
    <MyPageContainer label={TRACKING_LABEL}  extraProps={{
      hasRangePicker: true
    }}>
      <DashboardScore/>

      <Row gutter={16} style={{marginBottom: '3rem'}}>
        <Col span={24}>
          <TrendCharts/>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <InfiniteServiceRequestsList/>
        </Col>
      </Row>
    </MyPageContainer>
  );
};

export default Dashboard;
