import * as React from 'react';
import {LocationModel} from '@/typings/models/Location';
import styles from './index.less';
import {useDispatch, FormattedMessage as F, useIntl} from 'umi';
import Card from '@/components/Card';
import {Button, Popconfirm, Tooltip} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons';
import ResourceForm from '@/components/LocationDrawer/ResourceForm';

interface LocationResourcesProps {
  location: LocationModel;
}

const LocationResources: React.FC<LocationResourcesProps> = ({
  location,
}: LocationResourcesProps) => {

  const dispatch = useDispatch()
  const intl = useIntl()
  function removeResource(resource, resolve) {
    dispatch({
      type: 'location/deleteResource',
      payload: {
        resourceId: resource.uuid,
        projectId: location.id,
        cb: resolve,
        intl
      },
    });
  }

  function getRemoveAction(resource) {
    const noResource = location.Resources?.length === 1;
    const confirmation = (
      <Popconfirm
        onConfirm={() => new Promise((resolve, reject) => removeResource(resource, resolve))}
        disabled={noResource}
        placement={'topRight'}
        title={
          <F
            id={'pages.location.removeResource'}
            defaultMessage={'Are you sure to remove this resource?'}
          />
        }
      >
        <Button disabled={noResource} type={'link'}>
          <CloseCircleOutlined />
        </Button>
      </Popconfirm>
    );

    if (noResource) {
      return (
        <Tooltip placement={'topRight'} title={<F id={'pages.locations.atLeastOneResource'} />}>
          <span>{confirmation}</span>
        </Tooltip>
      );
    }
    return confirmation;
  }
  return (
    <div className={styles.resourcesContainer}>
      <ResourceForm location={location} />

      {(location.Resources || []).map((resource) => (
        <Card mini className={styles.card} key={resource.email}>
          <div className={styles.inner}>
            <div>
              <div className={styles.from}>
                <span className={styles.key}>
                  <F defaultMessage={'Name'} id={'pages.location.resource.name'} />
                </span>
                <span className={styles.value}>{resource.name}</span>
              </div>
              <div className={styles.to}>
                <span className={styles.key}>
                  <F defaultMessage={'Email'} id={'pages.location.resource.email'} />
                </span>
                <span className={styles.value}>{resource.email}</span>
              </div>
            </div>
            <div className={styles.action}>{getRemoveAction(resource)}</div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default (LocationResources);
