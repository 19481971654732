import {URLParamBuilder} from "@/utils/functions";
import * as PATHS from "@/constants/path";
import {request} from "@/services/api/request";
import {GetBillingOverviewRequest} from "@/typings/api/getBillingOverview";
import {GetBillingRequest} from "@/typings/api/getBilling";

export function fetchBillingOverviewRequest({query}) {
  return request<GetBillingOverviewRequest["response"]>(PATHS.BILLING_OVERVIEW_API_PATH, {
    method: 'GET',
    queryString: query
  });
}


// region Fetch Billing Details
type FetchBillingDetailsOptions = {
  params: GetBillingRequest["parameters"],
  query: Record<string, string>
}

export function fetchBillingDetailsRequest({params, query}: FetchBillingDetailsOptions) {
  const url = URLParamBuilder(PATHS.BILLING_DETAILS_API_PATH, {
    params,
    queryString: query
  })

  return request<GetBillingRequest["response"]>(url, {
    method: 'GET'
  });
}

// endregion
