import React, {useEffect} from "react"
import styles from "./index.less"
import {useIntl} from "umi"
import {Form} from "antd"
import ProForm, {ProFormText} from "@ant-design/pro-form"
import {ReadonlyFormProps} from "@/typings/models/ReadonlyForm";


const ReadonlyForm : React.FC<ReadonlyFormProps> = ({data, config}: ReadonlyFormProps) => {
  const [form] = Form.useForm()
  const intl = useIntl();
  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])
  return <div className={styles.readonlyForm}>
    <ProForm
      form={form}
      submitter={false}
      initialValues={data}
    >
      {config?.map(readonlyRow =>  <ProFormText
        disabled
        name={readonlyRow.key}
        placeholder={""}
        key={readonlyRow.key}
        label={readonlyRow.defaultMessage === null ? readonlyRow.name : intl.formatMessage({
          id: readonlyRow.name,
          defaultMessage: readonlyRow.defaultMessage,
        })}
      />)}
    </ProForm>

  </div>
}

export default ReadonlyForm
