import React, {useEffect, useState} from "react";
import ProForm from "@ant-design/pro-form";
import {LocationModel} from "@/typings/models/Location";
import {Form} from "antd";
import {useDispatch, useIntl, useSelector} from "umi";
import FORM_TYPES from "@/constants/formTypes"
import {DYNAMIC_FORM_FIELD_TYPES, getFormBlurProps} from "@/utils/form";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import DrawerSection from "@/components/Drawer/DrawerSection";
import DynamicForm, {IDynamicField} from "@/components/DynamicForm";

interface LocationDetailsFormProps {
  location: LocationModel,
  isNew: boolean,
  onChange?: (location: LocationModel) => void,
}

const LocationMetaForm: React.FC<LocationDetailsFormProps> = ({
                                                                location,
                                                                onChange,
                                                                isNew,
                                                              }) => {

  const [form] = Form.useForm()

  const [meta, setMeta] = useState<LocationModel["meta"]>({})
  const intl = useIntl();
  const dispatch = useDispatch()

  const {loadings, journeyMetaConfig} = useSelector(({location: {changeLoadings}, journey}: DefaultRootState) => ({
    loadings: changeLoadings,
    journeyMetaConfig: get(journey, `config.${journey.selectedJourney.hashId}.calkit_meta`, [])
  }))

  useEffect(() => {
    if (isEmpty(location.meta)) return

    setMeta(location.meta)
    setTimeout(() => {
      form.setFieldsValue(location.meta)
    }, 100)
  }, [location])

  function onValueChange(name: string, value: any) {
    if (isNew) return
    if (get(location.meta, name) === value) return
    const field = metaToBeShown.find(meta => meta.key === name)
    const newMeta = {
      ...meta,
      [name]: value
    }
    setMeta(newMeta)

    if (isEmpty(value) && field.type !== DYNAMIC_FORM_FIELD_TYPES.TAGS) return

    dispatch({
      type: "location/change",
      payload: {
        locationId: location.id,
        meta: newMeta
      }
    })
  }


  const metaToBeShown = journeyMetaConfig.filter(meta => {
    if (meta.read_only) return !isNew
    return true
  })

  if (metaToBeShown.length === 0) return null
  const onFormChange = (changedValues, values) => {
    Object.keys(changedValues).filter((changedKey) => {
      const field = metaToBeShown.find(meta => meta.key === changedKey)
      return field?.type === DYNAMIC_FORM_FIELD_TYPES.TAGS
    }).forEach((changedKey) => onValueChange(changedKey, changedValues[changedKey]))
    if (!isNew) return
    onChange && onChange({meta: values})
  }
  const getExtraFieldProps = (field: IDynamicField) => ({
    ...getFormBlurProps({
      type: FORM_TYPES.TEXT,
      value: meta[field.key],
      callback: onValueChange,
      name: field.key,
      loadings
    }),
    disabled: field.read_only || loadings.meta,
    hasFeedback: loadings.meta,
  })
  return <DrawerSection
    title={intl.formatMessage({
      id: 'pages.locations.meta',
      defaultMessage: 'Meta',
    })}
  >
    <ProForm
      form={form}
      submitter={false}
      initialValues={meta}
      onValuesChange={onFormChange}
    >
      <DynamicForm fields={metaToBeShown} getExtraFieldProps={getExtraFieldProps}/>
    </ProForm>
  </DrawerSection>
}

export default LocationMetaForm
