// @ts-nocheck

  import AreaChartOutlined from '@ant-design/icons/es/icons/AreaChartOutlined';
import TeamOutlined from '@ant-design/icons/es/icons/TeamOutlined';
import FileDoneOutlined from '@ant-design/icons/es/icons/FileDoneOutlined';
import EnvironmentOutlined from '@ant-design/icons/es/icons/EnvironmentOutlined';
import PictureOutlined from '@ant-design/icons/es/icons/PictureOutlined';
import LineChartOutlined from '@ant-design/icons/es/icons/LineChartOutlined';
import NotificationOutlined from '@ant-design/icons/es/icons/NotificationOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import QuestionCircleOutlined from '@ant-design/icons/es/icons/QuestionCircleOutlined';
import CreditCardOutlined from '@ant-design/icons/es/icons/CreditCardOutlined'
  export default {
    AreaChartOutlined,
TeamOutlined,
FileDoneOutlined,
EnvironmentOutlined,
PictureOutlined,
LineChartOutlined,
NotificationOutlined,
SettingOutlined,
QuestionCircleOutlined,
CreditCardOutlined
  }