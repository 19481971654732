// @ts-ignore
import { FormattedMessage, getLocale } from 'umi';
import { DefaultFooter } from '@ant-design/pro-layout';
import React from 'react';
import { Typography } from 'antd';
// @ts-ignore
import styles from './index.less';

export default function UserLayoutFooter() {
  const language = getLocale()?.split('-')[0];
  return (
    <DefaultFooter
      className={styles.userLayoutFooter}
      copyright={false}
      links={[
        {
          key: 'imprint',
          title: (
            <Typography.Text strong className={'color-secondary'}>
              {/* @ts-ignore */}
              <FormattedMessage id={'app.footer.imprint'} defaultMessage={'Imprint'} />
            </Typography.Text>
          ),
          href: `https://conversion.al/${language}/imprint`,
          blankTarget: true,
        },
        {
          key: 'policy',
          title: (
            <Typography.Text strong className={'color-secondary'}>
              {/* @ts-ignore */}
              <FormattedMessage id={'app.footer.policy'} defaultMessage={'Policy'} />
            </Typography.Text>
          ),
          href: `https://conversion.al/${language}/privacy`,
          blankTarget: true,
        },
      ]}
    />
  );
}
