import {LocationModel} from '@/typings/models/Location';

export function getLocationAllowDateTimes(location: LocationModel) {
  if (Array.isArray(location.Availability)) {
    return location.Availability.filter(
      (availability) => availability.allow_day_and_time !== undefined,
    ).map((availability) => availability.allow_day_and_time);
  }
  return [];
}

export function getLocationOpenPeriods(location: LocationModel) {
  if (Array.isArray(location.Availability)) {
    return location.Availability.filter(
      (availability) => availability.allow_period !== undefined,
    ).map((availability) => availability.allow_period);
  }
  return [];
}

export function getLocationBlockPeriods(location: LocationModel) {
  if (Array.isArray(location.Availability)) {
    return location.Availability.filter((av) => av.block_period).map((av) => av.block_period);
  }
  return [];
}

export function isVideoCallEnabled(videoCall: any) {
  return videoCall === 'true' || videoCall === 1 || videoCall === true;
}
