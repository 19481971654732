import React, {useEffect} from "react";
import styles from "./index.less"
import {Tabs} from "antd";
import {FormattedMessage as F} from "umi";
import Card from "@/components/Card";

export const SETTINGS_TABS = {
  VISIBILITY: {
    path: "visibility",
    KEY: "1"
  }
}
const {TabPane} = Tabs
const SettingsMenuTabs: React.FC = ({activeKey, setActiveKey}) => {

  useEffect(() => {
    if (!activeKey) setActiveKey(SETTINGS_TABS.VISIBILITY.KEY)
  }, [])
  return <div className={styles.menu}>
    <Card className={styles.card}>
      <Tabs defaultActiveKey={SETTINGS_TABS.VISIBILITY.KEY} activeKey={activeKey} onChange={setActiveKey}>
        <TabPane tab={
          <a> <F
            id="component.settings.visibility"
            defaultMessage="Visibility"
          /></a>
        } key={SETTINGS_TABS.VISIBILITY.KEY}/>
      </Tabs>
    </Card>
  </div>
};

export default SettingsMenuTabs
