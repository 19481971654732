import * as stringUtils from "./stringUtils";

const SELF_DOMAINS = ['roomtailors.de', 'conversional.de', 'conversion.al']
const GOOGLE_STORAGE_URL = 'storage.googleapis.com/conversional_'
// @ts-ignore
const APP_ENV = process.env.REACT_APP_JOURNEY_ENV

export const CLOUD_IMAGE_URL_DOMAIN = "https://image-resizer.conversional.de/"

export function getDomainEnvironmentForImage(imageSource: string): string | null {
    const rootDomain = SELF_DOMAINS.find(domain => imageSource.includes(domain))
    if (rootDomain) {
        const urlParts = imageSource.split('//')
        const domainPart = urlParts[1].split('/')[0]
        const env = domainPart.split(rootDomain)[0]
        return env === "" ? "production" : env.split(".")[0]
    }

    if (imageSource.includes(GOOGLE_STORAGE_URL)) {
        const parts = imageSource.split(GOOGLE_STORAGE_URL)
        return parts[1].split("/")[0]
    }
    return null
}

export function isImageInConversionalDomain(imageSource: string) {
    try {
        if (imageSource.includes(GOOGLE_STORAGE_URL)) return true

        const urlParts = imageSource.split('//')
        const domainPart = urlParts[1].split('/')[0]
        return SELF_DOMAINS.some(domain => domainPart.includes(domain))
    } catch (e) {
        // Do not break in case it is not recognizable
        return false
    }
}

export const getCloudResponsiveImageUrl = (fileUrl: string, domainEnvironment: string | null, env?: string) => {
    const buckets = {
        "production": "production",
        "development": "production",
        "staging": "staging",
        "testing": "testing",
        "default": "production",
    }
    const pictureEnvironment = domainEnvironment ? buckets[domainEnvironment] : (buckets[env] || buckets['default'])
    if (fileUrl.startsWith("conversional_"))
        fileUrl = fileUrl.split("/").slice(1).join("/")
    if (fileUrl.endsWith(".svg"))
        return `https://storage.googleapis.com/conversional_${pictureEnvironment}/${fileUrl}`

    return `${CLOUD_IMAGE_URL_DOMAIN}image-url?bucket=conversional_${pictureEnvironment}&image=${fileUrl}`
}


/**
 * The breakpoint will be added before the file name in image path.
 * This is the link:
 * https://conversional-cloud.ey.r.appspot.com/image-url?bucket=conversional_production&image=files/filestore/file/1000/KuCB34z27grauklassisch.jpg
 * This is the response sample:
 * {"image_url": "http://lh3.googleusercontent.com/4G-Hcjzvdbd4pbA2wkI2Cwlw7G5N2hnPV1jgLyylEdcnRyTFStHAjJUlJ3ww_k1Pt0UnNSw1j9G-5XUTZnEVC3p2XWLsgkYH-bZP_36hw3-ieg"}
 * To create responsive images:
 * http://lh3.googleusercontent.com/4G-Hcjzvdbd4pbA2wkI2Cwlw7G5N2hnPV1jgLyylEdcnRyTFStHAjJUlJ3ww_k1Pt0UnNSw1j9G-5XUTZnEVC3p2XWLsgkYH-bZP_36hw3-ieg=s${widthInPixesl}
 * For example if we want to have an image with 100 with:
 * http://lh3.googleusercontent.com/4G-Hcjzvdbd4pbA2wkI2Cwlw7G5N2hnPV1jgLyylEdcnRyTFStHAjJUlJ3ww_k1Pt0UnNSw1j9G-5XUTZnEVC3p2XWLsgkYH-bZP_36hw3-ieg=s100
 * */
export function generateMultiResolutionSrc({imageSource, width, aspectRatio, env = APP_ENV}: {imageSource: string, width?: number, aspectRatio?: string, env?: string}) {

    if (!isImageInConversionalDomain(imageSource)) return imageSource
    width = Math.round(width)
    if (width === 0) return ''

    const isSvg = imageSource.endsWith('svg')
    const thirdSlashIndex = stringUtils.getPosition(imageSource, "/", 3)
    let domainEnvironment = getDomainEnvironmentForImage(imageSource)
    let fileUrl = imageSource.slice(thirdSlashIndex + 1) + (isSvg ? "" : "&w=" + width)
    if (typeof aspectRatio === "string") {
        const aspectRatioH = parseInt(aspectRatio.split(":")[0])
        const aspectRatioW = parseInt(aspectRatio.split(":")[1])
        if (!isNaN(aspectRatioH) && !isNaN(aspectRatioW)) {
            const height = Math.round(width * aspectRatioW / aspectRatioH)
            fileUrl += "&h=" + height
        }
    }
    return getCloudResponsiveImageUrl(fileUrl, domainEnvironment, env)
}


