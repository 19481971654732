/* eslint-disable react/prop-types */
import React from 'react';
import { Empty, List, Typography } from 'antd';
import FileIcon from './FileIcon';
import { UploadedFilesItem } from '@/typings/models/Customer';
import { F } from '@/utils/intl';

interface CustomerFilesProps {
  uploadedFiles: UploadedFilesItem[];
}

const CustomerFiles: React.FC<CustomerFilesProps> = ({ uploadedFiles }) => {
  return (
    <List
      dataSource={uploadedFiles}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 60 }}
            description={
              <F id={'pages.customer.noUploadedFiles'} defaultMessage={'Uploaded Files'} />
            }
          ></Empty>
        ),
      }}
      renderItem={(file, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            avatar={<FileIcon filename={file.filename} />}
            title={
              <Typography.Text>
                <a target={'_blank'} className={'color-secondary'} href={file.path}>
                  {file.filename}
                </a>
              </Typography.Text>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default CustomerFiles;
