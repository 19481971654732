import React, { useEffect } from 'react';
import { connect, FormattedMessage as F, useIntl } from 'umi';
import { Button, Space, Typography } from 'antd';

import ImagesTable from './ImagesTable';
import MyPageContainer from '@/components/MyPageContainer';
import TagListInformation from '@/components/Image/TagListInformation';
import ImageGalleryAccessRequest from './ImageGallaryAccessRequest';

export interface BackofficeStudioProps {}

const TRACKING_LABEL = 'Images';

const BackofficeImages: React.FC<BackofficeStudioProps> = ({ dispatch, fetchError }) => {

const intl = useIntl();

  useEffect(() => {
    dispatch({
      type: 'images/fetchTags',
    });
    dispatch({
      type: 'images/fetchTagGroups',
    });
  }, []);

  return (
    <MyPageContainer
      label={TRACKING_LABEL}
      extraProps={{
        hasRangePicker: false,
      }}
    >
      <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
        {fetchError?.code === 400 && fetchError?.message?.toLowerCase().includes('permission denied') ? (
          <ImageGalleryAccessRequest />
        ) : (
          <>
            <TagListInformation />
            <ImagesTable />
          </>
        )}
      </Space>
    </MyPageContainer>
  );
};

export default connect(({ journey, images }) => ({
  selectedJourney: journey.selectedJourney,
  fetchError: images.fetchError,
}))(BackofficeImages);
