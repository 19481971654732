import React, {useEffect, useState} from 'react';
import {FormattedMessage as F, useDispatch, useIntl, useSelector} from 'umi';
import pick from 'lodash/pick';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment';
import {Button, Col, Divider, Row, Space, Typography} from 'antd';

import {PromotionModel} from '@/typings/models/Promotion';
import PromotionName from '@/components/Promotion/PromotionName';
import Drawer from '@/components/Drawer';
import PromotionDetailsForm from '@/components/PromotionDrawer/PromotionDetailsForm';
import PromotionTypeForm from '@/components/PromotionDrawer/PromotionTypeForm';
import DrawerSection from '@/components/Drawer/DrawerSection';
import PromotionConditionSelector from "@/components/PromotionDrawer/PromotionConditionSelector";

import styles from './index.less';
import {processHtml, processHtmlStyles} from './utils';
import PromotionConditionDrawer from "@/components/PromotionDrawer/PromotionConditionDrawer";
import { DYNAMIC_FORM_RULE_TYPES, extractDynamicFormRules } from '@/utils/form';

const PromotionDrawer: React.FC = () => {
  const [promotionFormData, setPromotionFormData] = useState<PromotionModel>({});
  const [processedHtml, setProcessedHtml] = useState("");

  const dispatch = useDispatch();
  const {promotionTypes} = useSelector(({promotion: {promotionTypes}}: DefaultRootState) => ({
    promotionTypes
  }))
  const { visible, promotion, createPromotionLoading, editPromotionLoading } = useSelector(
    ({ promotion: { drawer, list }, loading, journey }: DefaultRootState) => ({
      ...drawer,
      promotion:
        drawer.promotionId === 'NEW'
          ? { id: 'NEW' }
          : list.find((l) => l.id === drawer.promotionId) || {},
      createPromotionLoading: loading.effects['promotion/create'],
      editPromotionLoading: loading.effects['promotion/edit']
    }),
  );
  const selectedJourneyConfig = useSelector(
    (state: DefaultRootState) => state.journey?.config?.[state.journey.selectedJourney?.hashId],
  );


  useEffect(() => {
    setPromotionFormData({})
    setProcessedHtml("")
  }, [visible])

  useEffect(() => {
    if (promotionFormData?.promo_type)
      setProcessedHtml(
        processHtmlStyles(
          processHtml(
            promotion?.promo_type?.preview ||
              promotionTypes.find((x) => x.id === promotionFormData?.promo_type?.id)?.preview,
            promotionFormData?.promo_type,
          ),
          selectedJourneyConfig,
        ),
      );
  }, [JSON.stringify(promotionFormData)]);

  function toggle() {
    dispatch({
      type: 'promotion/toggleDrawer',
      payload: {
        promotion: null,
      },
    });
    dispatch({
      type: 'promotion/resetRecentlyCreatedPromotionCondition',
    });
    setPromotionFormData({})
  }

  function isNewPromotion() {
    return promotion.id === 'NEW';
  }
  //We send empty url if its validation included CUSTOM_URL_EMPTY_IF_DEFAULT 
  const clearUrlIfDefault = (payload) => {
    const selectedPromoType  =promotionTypes?.find(type=>type.id===payload?.promo_type_id)?.fields?.find(field => field.key === "url")
    const rules =extractDynamicFormRules(selectedPromoType?.validation)
    if (!rules[DYNAMIC_FORM_RULE_TYPES.CUSTOM_URL_EMPTY_IF_DEFAULT]) return;
    const selectedPromoTypeURLField = promotionTypes?.find(type=>type.id===payload?.promo_type_id)?.fields?.find(field => field.key === "url");
    const defaultUrl = selectedPromoTypeURLField?.["default"];
    if (payload?.promo?.url === defaultUrl) {
      payload.promo.url = '';
    }
  };
  const getPromotionConditionId = () =>
    promotionFormData.condition_id || promotion?.condition_id

  function preparePayload() {
    const payload: any = pick(promotionFormData, ['title', 'is_active']);
    payload.promo_type_id = get(promotionFormData, 'promo_type.id');
    payload.promo = omit(get(promotionFormData, 'promo_type'), ['id', 'validated']);
    clearUrlIfDefault(payload);
    payload.start_date = moment(new Date(get(promotionFormData, 'range.start'))).format(
      'YYYY-MM-DD',
    );
    payload.end_date = moment(new Date(get(promotionFormData, 'range.end'))).format('YYYY-MM-DD');
    payload.condition_id = getPromotionConditionId()
    return payload;
  }

  function onCreatePromotion() {
    dispatch({
      type: 'promotion/create',
      payload: preparePayload(),
    });
  }

  function onEditPromotion() {
    dispatch({
      type: 'promotion/edit',
      payload: {
        ...preparePayload(),
        emailPromotionId: promotion.id,
      },
    });
  }

  function getFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            {isNewPromotion() ? (
              <F id={'app.settings.close'} defaultMessage={'Close'} />
            ) : (
              <F id={'pages.common.discard'} defaultMessage={'Discard'} />
            )}
          </Button>
          {isNewPromotion() ? (
            <Button
              size={'large'}
              loading={createPromotionLoading}
              onClick={onCreatePromotion}
              type={'primary'}
            >
              <F id={'pages.promotions.addPromotion'} defaultMessage={'Add Promotion'} />
            </Button>
          ) : (
            <Button
              size={'large'}
              loading={editPromotionLoading}
              onClick={onEditPromotion}
              type={'primary'}
            >
              <F id={'pages.common.save'} defaultMessage={'save'} />
            </Button>
          )}
        </Space>
      </div>
    );
  }

  function getTitle() {
    return isNewPromotion() ? (
      intl.formatMessage({
        id: 'pages.promotions.newPromotion',
        defaultMessage: 'New Promotion',
      })
    ) : (
      <PromotionName promotion={promotion} drawer={false} />
    );
  }

  const intl = useIntl();

  return (
    <div className={styles.drawerContainer}>
      <Drawer
        width={'70%'}
        footer={getFooter()}
        title={getTitle()}
        visible={visible}
        destroyOnClose
        onVisibleChange={toggle}
      >
        <Row gutter={16} style={{ flex: 1 }}>
          <Col span={10}>
            <Divider plain>
              <Typography.Title level={3}> <F id={'pages.promotions.form'} defaultMessage={'Form'} /></Typography.Title>
            </Divider>
            <PromotionDetailsForm
              onChange={(details) => setPromotionFormData({ ...promotionFormData, ...details })}
              promotion={promotion}
              isNew={isNewPromotion()}
            />
            <DrawerSection
              title={intl.formatMessage({
                id: 'pages.promotions.type',
                defaultMessage: 'Promotion type',
              })}
            >
              <PromotionTypeForm
                onChange={(promotionType) =>
                  setPromotionFormData({ ...promotionFormData, promo_type: promotionType })
                }
                promotion={promotion}
                isNew={isNewPromotion()}
              />
            </DrawerSection>
            <DrawerSection
              title={intl.formatMessage({
                id: 'pages.promotions.promotionCondition',
                defaultMessage: 'Terms and conditions',
              })}
            >
              <PromotionConditionSelector value={getPromotionConditionId()} onChange={(promotionConditionId) => setPromotionFormData({
                ...promotionFormData,
                condition_id: promotionConditionId
              })}/>
            </DrawerSection>
          </Col>

          <Col offset={2} span={12}>
            <Divider plain>
              <Typography.Title level={3}> <F id={'pages.promotions.preview'} defaultMessage={'Preview'} /></Typography.Title>
            </Divider>
            <div
              style={{
                border: '1px solid gray',
                borderRadius: 10,
                padding: 20,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: processedHtml }} />
            </div>
          </Col>
        </Row>
        {visible && <PromotionConditionDrawer/>}
      </Drawer>
    </div>
  );
};

export default PromotionDrawer;
