import {URLParamBuilder} from "@/utils/functions";
import * as PATHS from "@/constants/path";
import {request} from "@/services/api/request";
import {GetSubscribersStatsRequest} from "@/typings/api/getSubscribersStats";
import {GetResultsStatsRequest} from "@/typings/api/getResultsStats";
import {GetAnalyticsFunnelRequest} from "@/typings/api/getAnalyticsFunnel";
import {GetStylesStatsRequest} from "@/typings/api/getStylesStats";
import {GetRegistrationStatsModel} from "@/typings/api/getRegistrationStats";
import {GetCTAChartRequest, GetCTATableRequest} from "@/typings/api/getCTAStats";
import {GetServiceRequestStatsRequest} from "@/typings/api/getServiceRequestStats";


// region Fetch Subscriber Stats
type FetchSubscriberStatsOptions = {
  params: GetSubscribersStatsRequest["parameters"],
  query?: GetSubscribersStatsRequest["query"],
}
export function fetchSubscribersStatsRequest({params, query = {}}: FetchSubscriberStatsOptions) {
  const url = URLParamBuilder(PATHS.GET_SUBSCRIBERS_STATS_API_PATH, {
    params, queryString: query
  })

  return request<GetSubscribersStatsRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion

// region Fetch Service Request Stats
type FetchServiceRequestStatsOptions = {
  params: GetServiceRequestStatsRequest["parameters"],
  query?: GetServiceRequestStatsRequest["query"],
}
export function fetchServiceRequestStats({params, query = {}}: FetchServiceRequestStatsOptions) {
  const url = URLParamBuilder(PATHS.GET_SERVICE_REQUESTS_STATS_API_PATH, {
    params, queryString: query
  })

  return request<GetServiceRequestStatsRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion

// region Fetch Customer Stats
type FetchRegistrationStatusOptions = {
  params: GetRegistrationStatsModel["parameters"],
  query?: GetRegistrationStatsModel["query"],
}
export function fetchRegistrationStatsRequest({params, query = {}}: FetchRegistrationStatusOptions) {
  const url = URLParamBuilder(PATHS.GET_REGISTRATION_STATS_API_PATH, {
    params, queryString: query
  })

  return request<GetRegistrationStatsModel["response"]>(url, {
    method: 'GET'
  });
}
// endregion

// region Fetch Result Stats
type FetchResultStatsOptions = {
  params: GetResultsStatsRequest["parameters"],
  query?: GetResultsStatsRequest["query"],
}
export function fetchResultStatsRequest({params, query = {}}: FetchResultStatsOptions) {
  const url = URLParamBuilder(PATHS.GET_RESULTS_STATS_API_PATH, {
    params
  })

  return request<GetSubscribersStatsRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion

// region Fetch Styles Stats
type FetchStylesStatsOptions = {
  params: GetStylesStatsRequest["parameters"],
  query?: GetStylesStatsRequest["query"],
}
export function fetchStylesStatsRequest({params, query = {}}: FetchStylesStatsOptions) {
  const url = URLParamBuilder(PATHS.GET_STYLES_STATS_API_PATH, {
    params,
    queryString: query
  })

  return request<GetStylesStatsRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion

type FetchAnalyticsFunnelDataOptions = {
  params: GetAnalyticsFunnelRequest["parameters"],
  query?: GetAnalyticsFunnelRequest["query"] }

export function fetchAnalyticsFunnelDataRequest({params, query = {}}: FetchAnalyticsFunnelDataOptions) {
  const url = URLParamBuilder(PATHS.GET_ANALYTICS_FUNNEL_API_PATH, {
    params,
    queryString: query
  })

  return request<GetAnalyticsFunnelRequest["response"]>(url, {
    method: 'GET'
  });
}

type FetchScoresRequestOptions = {
  params: GetAnalyticsFunnelRequest["parameters"],
  query?: GetAnalyticsFunnelRequest["query"] }

export function fetchScoresRequest({params, query = {}}: FetchScoresRequestOptions) {
  const url = URLParamBuilder(PATHS.GET_GLANCE_DASHBOARD_API_PATH, {
    params,
    queryString: query
  })

  return request<GetAnalyticsFunnelRequest["response"]>(url, {
    method: 'GET'
  });
}

// region Fetch CTA Chart
type FetchCTAChartOptions = {
  params: GetCTAChartRequest["parameters"],
  query?: GetCTAChartRequest["query"],
}
export function fetchCTAChartRequest({params, query = {}}: FetchCTAChartOptions) {
  const url = URLParamBuilder(PATHS.GET_CTA_CHART_API_PATH, {
    params,
    queryString: query
  })

  return request<GetCTAChartRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion

// region Fetch CTA Table
type FetchCTATableOptions = {
  params: GetCTATableRequest["parameters"],
  query?: GetCTATableRequest["query"],
}
export function fetchCTATableRequest({params, query = {}}: FetchCTAChartOptions) {
  const url = URLParamBuilder(PATHS.GET_CTA_TABLE_API_PATH, {
    params,
    queryString: query
  })

  return request<GetCTATableRequest["response"]>(url, {
    method: 'GET'
  });
}
// endregion
