import React, {HTMLProps, useEffect, useRef, useState} from "react";
import {ImageTagInfoModel} from "@/typings/models/Image";
import {Input, InputRef, Popover, Space, Spin, Tag} from "antd";
import get from "lodash/get"
import styles from "./index.less"
import {editTagRequest} from "@/services/api/image";
import {connect, FormattedMessage as F} from "umi"
import {JourneyModel} from "@/typings/models/Journey";
import {DispatchFunction} from "@/typings/models/ReduxState";
import ChooseTagGroup from "@/components/Image/ChooseTagGroup";

export interface ImageTagsProps {
  tag: ImageTagInfoModel,
  showCount?: boolean,
  editOnDoubleClick?: boolean,
  extra?: any
  selectedJourney: JourneyModel
  dispatch: DispatchFunction
  onRemove?: (tag: ImageTagInfoModel) => void
}

const ImageTag =
  ({
     tag,
     extra,
     showCount,
     onRemove,
     editOnDoubleClick,
     selectedJourney,
     dispatch,
   }: React.PropsWithChildren<ImageTagsProps>) => {

    const [editInputValue, setEditInputValue] = useState(undefined);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [chooseTagGroup, setChooseTagGroup] = useState(false)

    const editInputRef = useRef<InputRef>(null);


    const handleDoubleClick = e => {
      setEditInputValue(tag.name);
      setIsEditing(true)
      e.preventDefault()
    }

    useEffect(() => {
      if (!isEditing) setChooseTagGroup(false)
    }, [isEditing])

    useEffect(() => {
      editInputRef.current?.focus();
    }, [editInputValue]);

    const handleEdit = (taggroup_id = tag.taggroup?.id) => {
      if (editInputValue === tag.title) {
        setIsEditing(false)
        return
      }
      setLoading(true)
      setIsEditing(false)
      editTagRequest({
        params: {
          tagId: tag.id,
          journeyId: selectedJourney.hashId
        },
        payload: {
          ...tag,
          name: editInputValue,
          taggroup_id
        }
      }).then(() => {
        setEditInputValue(undefined)
        setLoading(false)
        dispatch({
          type: "images/fetch"
        })
        dispatch({
          type: "images/fetchTags"
        })
      })
    }

    const contentProps: HTMLProps<any> = {}

    if (editOnDoubleClick) {
      contentProps.onDoubleClick = handleDoubleClick
    }

    if (isEditing) {
      return <Popover trigger={"click"} visible={chooseTagGroup} onVisibleChange={visible => {
        if (!visible) {
          setIsEditing(false)
          setEditInputValue(tag.name)
          return
        }
      }
      } placement={"bottom"}
                      title={<Space>
                        <F id={"pages.images.chooseGroup"}/>
                        <strong>{editInputValue}</strong>
                      </Space>}
                      content={<ChooseTagGroup
                        tag={tag}
                        submitText={<F id={"pages.images.editTag"}/>}
                        onConfirm={handleEdit}/>
                      }>
        <Input
          ref={editInputRef}
          key={tag}
          size="small"
          className="tag-input"
          value={editInputValue}
          onChange={(e) => setEditInputValue(e.target.value)}
          onBlur={() => setChooseTagGroup(true)}
          onPressEnter={() => setChooseTagGroup(true)}
          style={{maxWidth: "100px"}}
        />
      </Popover>

    }
    return <Tag className={styles.tag}
                closable={onRemove}
                loading={true}
                onClose={(e) => {
                  e.preventDefault()
                  onRemove(tag)
                }}
                color={tag?.taggroup?.display_color}>
        <span {...contentProps}>
          {editInputValue || tag.name}
          {!isNaN(tag.images_count) && showCount && <strong>{`  (${tag.images_count})`}</strong>}
          {extra && extra}
          {loading && <Spin style={{transform: "scale(0.7)", padding: "0 5px"}} size={"small"}/>}
        </span>
    </Tag>
  };

export default connect(({journey}) => ({
  selectedJourney: journey.selectedJourney
}))(ImageTag)
