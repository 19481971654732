import * as React from 'react';
import { useEffect } from 'react';
import { Spin } from 'antd';
import { setAuthenticationTokens } from '@/services/auth';
import { eraseCookie, readCookie } from '@/utils/cookie';
import { resetPersistentReduxStore } from '@/utils/persistStore';

const ACCESS_TOKEN_COOKIE_KEY = 'tokens[access_token]';
const REFRESH_TOKEN_COOKIE_KEY = 'tokens[refresh_token]';

const MimicExternalLogin: React.FC = () => {
  useEffect(() => {
    const access_token = readCookie(ACCESS_TOKEN_COOKIE_KEY);
    const refresh_token = readCookie(REFRESH_TOKEN_COOKIE_KEY);
    if (access_token && refresh_token) {
      resetPersistentReduxStore();
      setAuthenticationTokens({
        access_token,
        refresh_token,
      });
      eraseCookie(ACCESS_TOKEN_COOKIE_KEY);
      eraseCookie(REFRESH_TOKEN_COOKIE_KEY);
    }
    window.location.replace('/');
  }, []);
  return <Spin />;
};

export default MimicExternalLogin;
