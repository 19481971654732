import storage from 'conversional-persistent-storage';
import moment from 'moment';
import { DateRanges, formatDateForAPIs } from '@/utils/date';

function initialDate() {
  const initialRange = DateRanges().filter((d) => d.dateDiff === 30)[0].period;
  return {
    start_date: formatDateForAPIs(initialRange[0]),
    end_date: formatDateForAPIs(initialRange[1]),
  };
}

export function selectDateFilter(state) {
  const start = moment(state.date.start);
  const end = moment(state.date.end);

  if (start.isValid() && end.isValid()) {
    return {
      start_date: formatDateForAPIs(start),
      end_date: formatDateForAPIs(end),
    };
  }
  // This is a rare corner-case
  return initialDate();
}

export default {
  namespace: 'date',
  state: {
    start: parseInt(storage.getItem('fromDate')),
    end: parseInt(storage.getItem('toDate')),
    quickDiff: parseInt(storage.getItem('quickDiff')),
    initialized: false,
  },

  effects: {
    *changeDate({ payload = {} }, { put }) {
      yield put({
        type: 'changedDate',
        payload,
      });
    },
  },

  reducers: {
    changedDate(state, action) {
      setDatestorage(action.payload);
      return {
        ...action.payload,
        updatedAt: Date.now(),
        initialized: true,
      };
    },
    randomUpdate(state) {
      return {
        ...state,
        updatedAt: Date.now(),
        random: Math.random(),
      };
    },
  },
};

function setDatestorage({ start, end, quickDiff }) {
  storage.setItem('fromDate', start);
  storage.setItem('toDate', end);
  if (quickDiff) storage.setItem('quickDiff', quickDiff);
  else storage.removeItem('quickDiff');
}
