import {fetchRecentServiceRequests} from "@/services/api/serviceRequest";
import {selectCurrentJourneyId} from "@/models/journey";
import {trackModalView} from "@/services/tracker";
import {processAPIResponse} from "@/utils/request";
import {selectDateFilter} from "@/models/date";
import {openCSVLink} from "@/utils/download";

export function selectCurrentServiceRequestInfiniteList(state){
  return state.serviceRequest.infiniteList
}

export function selectServiceRequestJourneyId(state){
  return state.serviceRequest.currentJourneyForServiceRequest
}

export default {
  namespace: 'serviceRequest',

  state: {
    list: {},
    currentJourneyForServiceRequest: null,
    infiniteList: {}
  },

  effects: {
    * fetch({payload = {}} : {payload: {search?: string, url?: string}}, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const journeyId = yield select(selectCurrentJourneyId)
      const query = {...date};
      if (payload.search){
        query.search = payload.search;
      }
      const {response, data} = yield call(fetchRecentServiceRequests, {params: {journeyId},url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveRecentServiceRequests',
            payload: data
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * downloadExport({ payload = {} } : {payload: {url?: string}}, { call, select }) {
      const date = yield select(selectDateFilter)
      const query = {...date, export: true};
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchRecentServiceRequests, {params: {journeyId}, url: payload.url, query});
      yield processAPIResponse({response, data}, {
        * onSuccess() {
          if (data.data.links && data.data.links.export) {
          yield openCSVLink(data.data.links.export)
          }
        },
        * onError(){

        }
      }, 'data')

    },
    * fetchInfinite({payload}, {call, put, select}) {
      const date = yield select(selectDateFilter)
      const journeyId = yield select(selectCurrentJourneyId)
      const previousJourneyId = yield select(selectServiceRequestJourneyId)
      if (previousJourneyId !== journeyId){
        payload.reset = true
        yield put({
          type: 'saveJourney',
          payload: {journeyId}
        })
      }
      const {reset} = payload;
      let url = undefined;
      if (!reset){
        const current = yield select(selectCurrentServiceRequestInfiniteList)
        url = current.links.next
      }
      const {response, data} = yield call(fetchRecentServiceRequests, {params: {journeyId},url, query: {limit: 4, ...date}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveInfiniteServiceRequests',
            payload: {
              data: data,
              ...payload
            }
          })
        },
        * onError(error) {

        }
      }, 'data')
    }
  },
  reducers: {
    saveRecentServiceRequests(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },   saveJourney(state, action) {
      return {
        ...state,
        currentJourneyForServiceRequest: action.payload.journeyId,
      };
    },
    saveInfiniteServiceRequests(state, action) {
      const {payload} = action
      const {data, reset} = payload
      const items = reset ? data.items : state.infiniteList.items.concat(data.items)
      const newInfiniteList = {
        ...data,
        items
      }
      return {
        ...state,
        infiniteList: newInfiniteList,
      };
    },
    toggleDrawer(state, action) {
      const {payload: {serviceRequest, nested}} = action;
      const drawer = state.drawer || {};
      drawer.nested = nested
      if (serviceRequest) {
        drawer.serviceRequest = serviceRequest
        drawer.visible = true
        trackModalView('service-request-details')
      } else {
        drawer.visible = false
      }

      return {
        ...state,
        drawer
      }
    }
  }
};
