import {Badge} from "antd";
import React from "react";


interface StatusCheckProps{
  checked?: boolean | string | number,
  badge: boolean,
  offChildren?: React.ReactNode | string
}
const StatusCheck : React.FC<StatusCheckProps> = ({badge = true,checked, offChildren}) => {
  const realChecked = checked === true || checked === 1 || checked === "true"
  if (!realChecked){
    if (offChildren !== undefined){
      return offChildren
    }
  }

  if (badge){
    return <Badge color={realChecked ? "green" : "red"}/>
  }
  return null;
}

export default StatusCheck
