import {Alert, Space, Table} from "antd";
import React from "react";
import {F} from "@/utils/intl";

import styles from "./index.less"

export default function JourneyVisibilitySettingsExamples() {
  const BASE_LOCALE_PATH = 'pages.settings.visibility.example'
  const getPath = path => `${BASE_LOCALE_PATH}.${path}`


  const dataSource = [0, 1, 2].map(index => ({
    key: '0',
    value: <F id={getPath(`urls[${index}].value`)}/>,
    description: <F id={getPath(`urls[${index}].description`)}/>,
  }))

  const columns = [
    {
      dataIndex: 'value',
      key: 'value',
    },
    {
      dataIndex: 'description',
      key: 'description',
    },
  ]
  return <Alert
    message={<Space direction={"vertical"}>
      <F id={getPath('hint')}/>
      <strong><F id={getPath('exampleTitle')}/></strong>
    </Space>}
    description={<Table
      className={styles.journeyVisibilitySettingsTable}
      style={{marginBottom: 5, background: "transparent"}}
      size="small"
      dataSource={dataSource}
      columns={columns}
      showHeader={false}
      pagination={false}
    />}
    type="info"
    showIcon
  />

}
