export function selectCurrentPeriod(state): (string | undefined) {
  return state.periodSelector
}

export default {
  namespace: 'periodSelector',

  state: 'week',

  effects: {
    * change({payload = {}}, {put}) {
      yield put({
        type: 'changePeriodSelector',
        payload,
      });
    }
  },

  reducers: {
    changePeriodSelector(state, action) {
      return action.payload
    }
  }
};
