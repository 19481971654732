import { ROUTE_PATHS } from '../../../config/RoutePaths';
import { CustomerServiceOutlined, FileSyncOutlined, ImportOutlined, SettingOutlined } from '@ant-design/icons';

export const FOOTER_ROUTE_CONFIG = [
    {
        path: ROUTE_PATHS.PROFILE,
        name: 'settings',
        icon: SettingOutlined,
        messageId: 'component.userMenu.settings',
        defaultMessage: 'Settings',
    },
    {
        path: ROUTE_PATHS.BILLINGS,
        name: 'billing',
        icon: FileSyncOutlined,
        messageId: 'component.userMenu.billing',
        defaultMessage: 'Billing',
    },
    {
        path: ROUTE_PATHS.SUPPORT,
        name: 'support',
        icon: CustomerServiceOutlined,
        messageId: 'component.userMenu.support',
        defaultMessage: 'Support',
    },
    {
        path: ROUTE_PATHS.LOGIN,
        name: 'signout',
        icon: ImportOutlined,
        messageId: 'component.userMenu.signOut',
        defaultMessage: 'Sign Out',
    },
];
