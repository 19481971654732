import React, { useCallback, useEffect, useState } from 'react';
import { connect, FormattedMessage as F, NavLink, useIntl } from 'umi';
import { Alert, Button, Popconfirm, Space, Tooltip, Typography, message } from 'antd';
import type { ProColumns } from '@ant-design/pro-table';
import { DeleteFilled, DeleteOutlined, LineChartOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

import Table from '@/components/Table';
import { ImageModel } from '@/typings/models/Image';
import ImageThumbnail from '@/components/Image/ImageThumbnail';
import ImageTags from '@/components/Image/ImageTags';
import { removeImageRequest } from '@/services/api/image';
import ImageUploadDrawer from '@/components/Image/ImageUploadDrawer';

import styles from './index.less';

const TRACKING_LABEL = 'Images';
const REDUX_ACTION = 'images/fetch';
const ImagesTable: React.FC = ({ dispatch, list, loading, selectedJourney, imageListError }) => {
  const [tablePayload, setTablePayload] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [bulkEditLoading, setBulkEditLoading] = useState<boolean>(false);
  const intl = useIntl();

  const fetchTableData = useCallback(
    debounce((payload = tablePayload) => {
      setTablePayload(payload);
      dispatch({
        type: REDUX_ACTION,
        payload,
      });
    }, 200),
    [],
  );
  const success = () => {
    message.success(
      intl.formatMessage({
        id: 'pages.images.deleteSelectedImagesSuccess',
        defaultMessage: 'Images deleted successfully',
      }),
      3,
    );
  };
  const deleteSelectedImagesConfirm = (image) => {
    if (!image && !selectedRowKeys?.length) return;
    return new Promise((resolve) => {
      removeImageRequest({
        params: {
          journeyId: selectedJourney?.hashId,
        },
        queryString: { ids: selectedRowKeys?.length ? selectedRowKeys : [image?.id] },
      }).then((res) => {
        setSelectedRowKeys([]);
        resolve(res);
        fetchTableData();
        success();
      });
    });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const columns: ProColumns<any>[] = [
    {
      title: '',
      dataIndex: 'url',
      render: (_, image: ImageModel) => <ImageThumbnail image={image} />,
    },
    {
      title: <F id="pages.images.tags" />,
      dataIndex: 'tags',
      render: (_, image: ImageModel) => <ImageTags image={image} />,
    },
    {
      title: <F id="pages.common.actions" />,
      render: (_, image: ImageModel) => (
        <Popconfirm
          onConfirm={() => deleteSelectedImagesConfirm(image)}
          placement={'topRight'}
          title={<F id={'pages.images.removeImageConfirmation'} />}
        >
          <a>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className={styles.tableContainer}>
      <Table
        columns={columns}
        rowKey={'id'}
        size={'small'}
        label={TRACKING_LABEL}
        total={list.total}
        links={list.links}
        search={false}
        hasPageSize={true}
        rowSelection={rowSelection}
        dataSource={list.items}
        loading={loading || bulkEditLoading}
        request={fetchTableData}
        expandableRow={false}
        header={
          <div style={{ height: '50px' }}>
            {selectedRowKeys.length > 0 && (
              <Alert
                type="info"
                showIcon
                className={styles.tableSelectionAlert}
                action={
                  <Space direction="horizontal">
                    <Button
                      type="primary"
                      size="small"
                      style={{ marginInlineStart: 8, color: 'white' }}
                      onClick={() => setSelectedRowKeys([])}
                    >
                      <F id={'pages.common.clear'} />
                    </Button>
                    <Popconfirm
                      title={<F id={'pages.images.deleteSelectedImagesConfirm'} defaultMessage='Do you want to delete (x) images?'/>}
                      onConfirm={deleteSelectedImagesConfirm}
                      okText={<F id={'pages.common.confirm'} defaultMessage='Confirm'/>}
                      cancelText={<F id={'pages.common.cancel'}  defaultMessage='Cancel'/>}
                    >
                      <Button
                        type="default"
                        icon={<DeleteFilled />}
                        size="small"
                        danger
                        style={{ marginInlineStart: 8, minWidth: '100px' }}
                      >
                        {' '}
                        <F id={'pages.common.remove'} />
                      </Button>
                    </Popconfirm>
                  </Space>
                }
                message={
                  <Space size={24}>
                    <Typography.Text strong>
                      ({selectedRowKeys.length}) <F id={'pages.common.selected'} />
                    </Typography.Text>
                    <ImageTags
                      setImageIds={setSelectedRowKeys}
                      setBulkEditLoading={setBulkEditLoading}
                      imageIds={selectedRowKeys}
                    />
                  </Space>
                }
              />
            )}
          </div>
        }
        toolBarRender={() => [
          <Tooltip title={<F id="pages.images.selectTagStatistics" />}>
            <NavLink to="/tag-statistics">
              <Button type="text" icon={<LineChartOutlined />} />
            </NavLink>
          </Tooltip>,
          <ImageUploadDrawer key="upload" />,
        ]}
      />
    </div>
  );
};

export default connect(({ images, loading, journey }) => ({
  list: images.list,
  selectedJourney: journey.selectedJourney,
  loading: loading.effects[REDUX_ACTION],
}))(ImagesTable);
