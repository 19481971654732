import ReactGA from 'react-ga'
import env from "@/constants/environment"
import {IEvent} from "@/typings/common/event";

export function initializeTracker(){
  ReactGA.initialize(env.GA_ID, {})
}

export function trackPageView(){
  ReactGA.pageview(location.pathname);
}

export function trackEvent(event: IEvent){
  ReactGA.event(event);
}

export function trackFormSubmission(){
  ReactGA.ga('send', 'formSubmit');
}

export function trackOnlineEditForm(changeBody, action){
  const keys = Object.keys(changeBody).join(", ")
  return ReactGA.event(action(keys))
}

// Tracking Drawers & Mobiles
export function trackModalView(name: string){
  ReactGA.modalview(name)
}
