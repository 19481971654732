import React, {useCallback, useEffect} from 'react';
import MyPageContainer from '@/components/MyPageContainer';
import {Col, Divider, Empty, Row, Spin} from 'antd';
import {connect} from 'umi';
import Histogram from '@/components/charts/Histogram';
import {DispatchFunction} from '@/typings/models/ReduxState';
import {StylesStatsModel} from '@/typings/models/StylesStats';
import Ring from '@/components/charts/Ring';
import {FormattedMessage as F} from '@@/plugin-locale/localeExports';
import {capitalizeAllLetters} from "@/utils/functions";
import debounce from "lodash/debounce";

const REDUX_ACTION = "analytics/fetchStylesStats"
interface MarketingReportProps{
  dispatch: DispatchFunction,
  loading?: boolean,
  charts: StylesStatsModel[]
}
const TRACKING_LABEL = "Marketing"

const MarketingPieCharts: React.FC<{ charts: StylesStatsModel[] }> = ({charts}) => {
  return <Row gutter={[48, 48]}>
    {charts.map(chart => <Col lg={6} sm={12} xs={24}>
      <Ring cardTitle={chart.feature} chartData={chart.values}/>
    </Col>)}
  </Row>
}

const MarketingBarCharts: React.FC<{ charts: StylesStatsModel[] }> = ({charts}) => {
  return <Row gutter={[48, 48]}>
    {charts.map(chart => <Col lg={12} sm={24} xs={24}>
      <Histogram cardTitle={capitalizeAllLetters(chart.feature)} chartData={chart.values}/>
    </Col>)}
  </Row>
}

const MarketingReport: React.FC<MarketingReportProps> = ({dispatch, charts, loading= false, date}): React.ReactNode => {


  const fetchData = useCallback(debounce( () =>{
    dispatch({
      type: REDUX_ACTION
    })
  }, 200), [])

  useEffect(() => {
    fetchData()
  }, [date.updatedAt])

  const pieCharts = charts.filter(chart => Object.keys(chart.values).length <= 3)
  const barCharts = charts.filter(chart => Object.keys(chart.values).length > 3)

  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{hasRangePicker: true}}>
      {charts.length === 0 && !loading && <Empty description={
      <F
      id="pages.marketing.noChart"
      defaultMessage="No Chart Available. Try Changing Date Filter"
      />
      }/>}
     <Spin spinning={loading}>
       <MarketingPieCharts charts={pieCharts}/>
       {pieCharts.length > 0 && barCharts.length > 0 && <Divider/>}
       <MarketingBarCharts charts={barCharts}/>
     </Spin>
    </MyPageContainer>
  );
};
export default connect(({analytics: {styles}, loading, date}) => ({charts: styles, loading: loading.effects[REDUX_ACTION], date }))(MarketingReport)
