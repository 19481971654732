import React from "react";
import FunnelChart from "@/components/charts/Funnel/FunnelChart"
import {AnalyticsFunnelStepModel} from "@/typings/models/AnalyticsFunnel";
import Card from "@/components/Card"

export type FunnelProps = {
  cardTitle: String,
  helpTooltip?: String,
  data: AnalyticsFunnelStepModel[]
}
const Funnel : React.FC<FunnelProps> = ({cardTitle, data}) => {
  return <Card title={cardTitle}>
    <FunnelChart data={data}/>
  </Card>
}

export default Funnel
