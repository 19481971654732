import React, {ReactElement} from "react";
import {CustomerModel} from "@/typings/models/Customer";
import {Space} from "antd";
import {useDispatch} from "umi";

export interface CustomerNameProps {
  customer: CustomerModel,
  drawer?: boolean,
  fallback?: ReactElement | string,
}

export function getCustomerText(customer: CustomerModel){
  return `${customer.firstname || ""} ${customer.lastname || ""}`
}

const CustomerName =
  ({
     customer,
     fallback = " - ",
     children,
     drawer = false
   }: React.PropsWithChildren<CustomerNameProps>) => {

    const dispatch = useDispatch()
    function toggleDrawer() {
      dispatch({
        type: "customers/toggleDrawer",
        payload: {
          customer
        }
      })
    }
    if (!customer && !children) {
      return <>{fallback}</>;
    }

    const content = children ? children : <Space size={3}>
      <span>{customer.firstname || ""}</span>
      <span>{customer.lastname || ""}</span>
    </Space>


    if (drawer) {
      return <a onClick={toggleDrawer}>
        {content}
      </a>
    }
    return <div>
      {content}
    </div>
  };

export default CustomerName
