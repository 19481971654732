import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import {useIntl} from "umi";
import { CloseOutlined } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';

const VALID_URL = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/
const CustomUrlFormItem = ({
  label,
  placeHolder,
  rules = [],
  defaultValue,
  name,
  form,
  initialValue,
  onValuesChange,
  tooltip
}) => {
  const [isDefaultValue, setIsDefaultValue] = useState(true);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();

  const isRequired = rules.some((rule) => rule.required === true);
  useEffect(() => {
    if (!form) return;
    const value = form.getFieldValue(name);
    if (!value) return;
    setInputValue(value);
    validateInput(value);
    setIsDefaultValue(!value);
  }, [form]);
  useEffect(() => {
    if (!form) return;
    form.setFieldsValue({ [name]: inputValue });
    if (onValuesChange) {
      onValuesChange({ [name]: inputValue }, form.getFieldsValue());
    }
  }, [inputValue, form, name]);

  const validateInput = (value, setToDefault = false) => {
    if (setToDefault) {
      setIsValid(true);
      setErrorMessage('');
      return true;
    }

    let valid = true;
    let error = '';
    if (isRequired && !value) {
      valid = false;
      error = intl.formatMessage({
        id: 'pages.promotions.error_required',
        defaultMessage: 'This field is required.',
      });
    }

    if (!VALID_URL.test(value)) {
      valid = false;
      error = intl.formatMessage({
        id: 'pages.promotions.invalid_error',
        defaultMessage: 'Please enter a valid URL',
      });
    }

    setIsValid(valid);
    setErrorMessage(error);
  };
  const handleTagClose = () => {
    setIsDefaultValue(false);
    setInputValue(initialValue);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
  };

  const handleSetToDefaultValue = () => {
    setIsDefaultValue(true);
    setInputValue(defaultValue);
    validateInput(defaultValue, true);
  };
  return (
    <Form.Item
      label={label}
      required={isRequired}
      validateStatus={!isValid ? 'error' : undefined}
      help={!isValid ? errorMessage : undefined}
      name={name}
      tooltip={tooltip}
    >
      {isDefaultValue ? (
        <Input
          value={inputValue}
          name={name}
          readOnly
          disabled
          addonAfter={
            <CloseOutlined
              onClick={handleTagClose}
              style={{ cursor: 'pointer', color: '#ff4d4f' }}
            />
          }
        />
      ) : (
        <Input
          required={isRequired}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          addonAfter={
            <Link onClick={handleSetToDefaultValue} style={{ marginLeft: 8 }}>
              {defaultValue}
            </Link>
          }
          placeholder={placeHolder}
        />
      )}
    </Form.Item>
  );
};

export default CustomUrlFormItem;
