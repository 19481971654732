import React, {CSSProperties, ReactElement} from "react";
import {Space} from "antd";
import {useDispatch} from "umi"
import {LocationModel} from "@/typings/models/Location";

export interface LocationNameProps {
  location: LocationModel,
  style?: CSSProperties,
  fallback?: ReactElement | string,
  drawer?:boolean,
  children?: ReactElement
}

const LocationName =
  ({
     location,
     style = {},
     fallback = " - ",
     children,
     drawer = false
   }: LocationNameProps) => {


    const dispatch = useDispatch()
    function toggleDrawer() {
      dispatch({
        type: "location/toggleDrawer",
        payload: {
          location
        }
      })
    }

    if (!children && !location) {
      return <>
        {fallback}
      </>;
    }

    const content = children ? children : <Space size={3}>
      <span>{location?.location}</span>
    </Space>


    if (drawer) {
      return <a style={style} onClick={toggleDrawer}>
        {content}
      </a>
    }
    return <div style={style}>
      {content}
    </div>
  };

export default LocationName
