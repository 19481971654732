import React, {useState} from "react";
import ProForm, {ProFormText} from "@ant-design/pro-form";
import {LocationModel} from "@/typings/models/Location";
import {Button, Collapse, Form, Tooltip} from "antd";
import {useSelector, useDispatch, FormattedMessage as F, useIntl} from "umi";
import styles from "@/components/LocationDrawer/index.less";


const {Panel} = Collapse

interface ResourceFormProps {
  location: LocationModel,
}

const ResourceForm: React.FC<ResourceFormProps> = ({location}: ResourceFormProps) => {

  const [form] = Form.useForm()
  const [active, setActive] = useState(false)
  const intl = useIntl();
  const dispatch = useDispatch()
  const {addLoading} = useSelector(({loading}: DefaultRootState) => ({
    addLoading: loading.effects['location/addResource']
  }))
  function onAdd(values) {
    return new Promise<boolean>((resolve) => {
      dispatch({
        type: 'location/addResource',
        payload: {
          projectId: location.id,
          body: values,
          intl,
          cb: () => {
            form.resetFields()
          }
        },
      })
      resolve(true)
    })

  }

  function getCollapse() {
    const moreThanFiveResources = (location.Resources || []).length >= 5

    const collapse = <Collapse
      defaultActiveKey={['1']}
      activeKey={active ? '1' : '0'}
      expandIconPosition={"right"}
      onChange={() => setActive(!active)}
    >
      <Panel disabled={moreThanFiveResources}
             header={<F id={"pages.locations.addNewResource"} defaultMessage="Add New Resource"/>} key="1">
        <div className={styles.addResource}>
          <ProForm
            form={form}
            submitter={false}
            onFinish={onAdd}
            className={styles.inner}
            style={{flexWrap: 'wrap'}}
          >
            <ProFormText
              name={"first_name"}
              rules={REQUIRED_RULE}
              placeholder={intl.formatMessage({
                id: 'pages.resource.name',
                defaultMessage: 'Name',
              })}
            />
            <ProFormText
              name={"last_name"}
              rules={REQUIRED_RULE}
              placeholder={intl.formatMessage({
                id: 'pages.resource.surname',
                defaultMessage: 'Surname',
              })}
            />
            <ProFormText
              rules={REQUIRED_RULE}
              name={"email"}
              placeholder={intl.formatMessage({
                id: 'pages.resource.email',
                defaultMessage: 'Email',
              })}
            />
            <Button loading={addLoading} htmlType={'submit'} className={'button-secondary'} block type={"primary"}><F
              id={"pages.common.add"} defaultMessage={"Add"}/></Button>
          </ProForm>
        </div>
      </Panel>
    </Collapse>

    if (moreThanFiveResources) {
      return <Tooltip placement={"topLeft"} title={<F id={"pages.locations.resourceCountLimitation"}/>}>
        <div>
          {collapse}
        </div>
      </Tooltip>
    }

    return collapse
  }

  const REQUIRED_RULE = [{
    required: true,
    message: intl.formatMessage({
      id: 'pages.common.form.required'
    })
  }]
  return <div className={styles.addResource}>
    {getCollapse()}


  </div>
}

export default ResourceForm
