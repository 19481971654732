import React from 'react';
import { DefaultFooter } from '@ant-design/pro-layout';

const Footer = () => {
  return (
    <DefaultFooter
      copyright={false}
      links={[
        {
          key: 'Conversional',
          title: 'Conversional Website',
          href: 'https://conversion.al',
          blankTarget: true,
        },
      ]}
    />
  );
};

export default Footer;
