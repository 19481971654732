import * as React from "react";
import {IEvent} from "@/typings/common/event";
import {trackEvent} from "@/services/tracker";


export interface ClickTrackerProps {
  children: any,
  event: IEvent
}


const ClickTracker: React.FC<ClickTrackerProps> =
  ({children, event}) => {

    return <span>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          onClick: (e) => {
            child.props.onClick && child.props.onClick(e)
            trackEvent(event)
          }
        })
      )}
    </span>
  };

export default ClickTracker
