import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Select, Space} from "antd";

import {useDispatch, useSelector} from "umi";
import {PromotionConditionModel} from "@/typings/models/Promotion";
import {F} from "@/utils/intl";
import {PlusOutlined, EditOutlined} from "@ant-design/icons";


interface PromotionConditionSelectorProps {
  onChange: (value: string) => void,
  value: string
}

const PromotionConditionSelector: React.FC<PromotionConditionSelectorProps> = ({onChange, value}) => {

  const dispatch = useDispatch()


  const {promotionConditions, recentlyCreatedPromotionCondition} = useSelector(({
                                                                                  promotion: {
                                                                                    promotionConditions,
                                                                                    recentlyCreatedPromotionCondition
                                                                                  }
                                                                                }: DefaultRootState) => ({
    promotionConditions,
    recentlyCreatedPromotionCondition
  }))

  const filterPromotionCondition = (input: string, option?: { option: PromotionConditionModel; value: string }) => {
    return (`${option?.option.title} ${option?.option.text}`).toLowerCase().indexOf(input.toLowerCase()) >= 0
  };

  useEffect(() => {
    dispatch({
      type: 'promotion/fetchPromotionConditions'
    })
  }, [])

  useEffect(() => {
    if (recentlyCreatedPromotionCondition?.id) {
      onChange(recentlyCreatedPromotionCondition?.id)
      dispatch({
        type: 'promotion/resetRecentlyCreatedPromotionCondition'
      })
    }

  }, [JSON.stringify(recentlyCreatedPromotionCondition)])

  const selectedPromotion = (promotionConditions || []).find(promotionCondition => promotionCondition.id === value)
  return <Space direction={"vertical"} style={{width: "100%"}}>
    <Select
      onSelect={onChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{margin: '8px 0'}}/>
          <Space style={{padding: '0 8px 4px'}}>
            <Button type="text" icon={<PlusOutlined/>} onClick={() => {
              dispatch({
                type: 'promotion/togglePromotionConditionDrawer',
              })
            }}>
              <F id={"pages.promotions.addPromotionCondition"}/>
            </Button>
          </Space>
        </>
      )}
      value={value}
      showSearch
      placeholder={<F id={"pages.promotion.selectPromotionConditionPlaceholder"}/>}
      filterOption={filterPromotionCondition} style={{width: "100%"}}>
      {promotionConditions.map(promotionCondition => <Select.Option option={promotionCondition}
                                                                    key={promotionCondition.id}
                                                                    value={promotionCondition.id}>
        <Space direction={"horizontal"}>
          <strong>{promotionCondition.name}</strong>
          <small>{promotionCondition.condition}</small>
        </Space>
      </Select.Option>)}
    </Select>
    {selectedPromotion && <div>
      <Button size={"small"} type={"link"} icon={<EditOutlined/>} onClick={() => {
        dispatch({
          type: 'promotion/togglePromotionConditionDrawer',
          payload: {
            promotionCondition: selectedPromotion
          }
        })
      }
      }><F id={"pages.common.edit"}/></Button>
    </div>}
  </Space>
}

export default PromotionConditionSelector
