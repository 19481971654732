import shuffle from "lodash/shuffle";

export const PRIMARY_COLOR = "#fa7f46"
export const FONT_FAMILY = "raleway"

export const CHART_COLORS = shuffle(['#DE7967', '#E17778', '#DF788B', '#D77C9D', '#CA83AD', '#B88ABA', '#A293C3', '#8A9BC8', '#6FA2C7', '#54A8C1', '#3EACB7', '#33AFA9', '#3AB298', '#4CB286', '#61B274', '#77B062', '#8DAE54', '#A3AA49', '#B8A444', '#CC9E45', '#DD974D', '#EC905A', '#F68B6A'])

export const CHART_THEME = {
  colors10: CHART_COLORS,
  colors20: CHART_COLORS,
  styleSheet: {
    fontFamily: FONT_FAMILY
  }
}
