import * as React from 'react'
import MyPageContainer from "@/components/MyPageContainer";
import Card from "@/components/Card";
import {Divider, Typography} from 'antd';
import {FormattedMessage as F} from "umi";
import {EMAIL, EMAIL_HREF, PHONE_HREF, PHONE_TEXT} from '@/constants/support';

interface SupportPageProps {

}

const TRACKING_LABEL = "Support"

const SupportPage : React.FC<SupportPageProps> = ({} : SupportPageProps) => {

    return <MyPageContainer label={TRACKING_LABEL}>
      <Card>
        <Typography.Title level={2}><F defaultMessage={"Support Title"} id={"pages.support.title"}/></Typography.Title>
        <Divider/>
        <div>
          <Typography.Title level={4}><F defaultMessage={"Telephone"} id={"pages.support.telephone"}/></Typography.Title>
          <Typography.Text copyable><a href={PHONE_HREF}>{PHONE_TEXT}</a></Typography.Text>
        </div> <Divider/>
        <div>
          <Typography.Title level={4}><F defaultMessage={"Email"} id={"pages.support.email"}/></Typography.Title>
          <Typography.Text copyable><a href={EMAIL_HREF}>{EMAIL}</a></Typography.Text>
        </div>
      </Card>
    </MyPageContainer>
}

export default (SupportPage)
