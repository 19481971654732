import createExpandedRowFromColumns from "@/components/Table/createExpandedRowFromColumns";
import React from 'react';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import MyPageContainer from "@/components/MyPageContainer";
import Table from "@/components/Table";
import type {ProColumns} from "@ant-design/pro-table";
import {BillingOverviewModel} from "@/typings/models/Billing";

const TRACKING_LABEL = "BillingOverview"
const BillingOverview: React.FC = ({dispatch, list, loading, history}) => {

  const intl = useIntl();

  function fetchTableData(payload: any) {
    dispatch({
      type: 'billing/fetchOverview'
    })
  }


  const viewBillingDetails = (billingOverview: BillingOverviewModel) => {
    const billingId = billingOverview.links.href.split("billings/")[1]
    history.push("/billings/" + billingId)
  }


  const columns: ProColumns<BillingOverviewModel>[] = [
    {
      title: (
        <F
          id="pages.billings.period"
          defaultMessage="Period"
        />
      ),
      dataIndex: '',
      render: (_, record: BillingOverviewModel) => <a
      onClick={() => viewBillingDetails(record)}>{record.start} - {record.end}</a>
    }, {
      title: (
        <F
          id="pages.billings.created"
          defaultMessage="Invoice Created On"
        />
      ),
      dataIndex: 'created',
      responsive: ['xxl', 'xl']
    }, {
      title: (
        <F
          id="pages.common.tableActions"
          defaultMessage="Actions"
        />
      ),
      render: (_, record: BillingOverviewModel) => <div>
        <a onClick={() => viewBillingDetails(record)}><F
          id="pages.billings.viewDetails"
          defaultMessage="Details"
        /></a>
      </div>,
      dataIndex: '',
    },
  ];

  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{exportTable: {}, hasRangePicker: true}}>
        <Table headerTitle={intl.formatMessage({
          id: 'pages.billings.tableTitle',
          defaultMessage: 'Billings',
        })} columns={columns}
               rowKey={"created"}
               label={TRACKING_LABEL}
               pagination={false}
               search={false}
               dataSource={list} loading={loading}
               request={fetchTableData}
               expandableRow={createExpandedRowFromColumns<BillingOverviewModel>(columns)}
        />
    </MyPageContainer>
  );
};

export default connect(({billing, loading}) => ({
  list: billing.overview,
  loading: loading.effects["billing/fetchOverview"]
}))(BillingOverview);
