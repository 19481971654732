import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

import { getLanguageFromUmiLocale } from '@/utils/locale';
import { fetchCaptions, loadYouTubeIframeAPI, VIDEO_Id } from './util';

const YouTubeVideoWithCaptions = ({ height, width }) => {
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      // Increase size by 200px when the video starts playing
      const newHeight = parseInt(height, 10) + 200;
      const newWidth = parseInt(width, 10) + 200;
      playerRef.current.setSize(newWidth, newHeight);
    }
  };

  const initYouTubePlayer = async () => {
    try {
      await loadYouTubeIframeAPI();
      window.onYouTubeIframeAPIReady = () => {
        playerRef.current = new window.YT.Player('player', {
          height: height,
          width: width,
          videoId: VIDEO_Id,
          playerVars: {
            playsinline: 1,
            cc_load_policy: 1,
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange 
          },
        });
      };
    } catch (error) {
      console.error(error);
    }
  };

  const onPlayerReady = async () => {
    try {
      const captions = await fetchCaptions();
      const preferredLanguage = getLanguageFromUmiLocale();
      const hasPreferredLanguageCaptions = captions?.some(caption => caption.language === preferredLanguage);
      if (hasPreferredLanguageCaptions) {
        showPlayer();
        
      }
    } catch (error) {
      console.error('Failed to fetch captions:', error);
    }
  };

  const showPlayer = () => {
    const playerElement = document.getElementById('player');
    if (playerElement) {
      playerElement.style.display = '';
      playerElement.style.opacity = '0';
      playerElement.style.transition = 'opacity 1s ease-in-out';
      setIsLoading(false);
      // Ensure styles have been applied
      setTimeout(() => {
        playerElement.style.opacity = '1';
      }, 0);
    }
  };

  useEffect(() => {
    initYouTubePlayer();
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, []);

  return (
      <div id="player" style={{ display: 'none', opacity: 0 }}></div>
  );
};

export default YouTubeVideoWithCaptions;
