import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Autocomplete, GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import {Form, Spin, Typography} from 'antd';
import {useIntl} from 'umi';
import {CheckCircleOutlined} from '@ant-design/icons';

import {AddressModel} from '@/typings/models/Location';
import {getAddressFromLatLng, getAPIKey, getLatLngFromAddress} from './util';

const libraries = ["places"];
interface MapWithAutocompleteProps {
  onChange: (value: AddressModel) => void;
  addressModel?: AddressModel;
}
const MapWithAutocomplete: React.FC<MapWithAutocompleteProps> = ({ onChange, addressModel }) => {

  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState(
    addressModel ?? {
      latitude: 0,
      longitude: 0,
      address: '',
    },
  );
  const [error, setError] = useState<string | null>(null);
  const [locationStoring, setLocationStoring] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(location?.address || '');

  const intl = useIntl();
  const inputRef = useRef(null);

  const onLoad = useCallback((autoC) => {
    setAutocomplete(autoC);
  }, []);
  const API_KEY = getAPIKey()
  useEffect(() => {
    const fetchLatLng = async () => {
      if (addressModel?.address && (!addressModel?.latitude || !addressModel?.longitude)) {
        const res = await getLatLngFromAddress(addressModel.address);
        if (!res) {
          setLocation((prevState) => ({
            ...prevState,
            address: addressModel.address,
            latitude: 0,
            longitude: 0,
          }));

          return;
        }
        setLocation((prevState) => ({
          ...prevState,
          address: addressModel?.address,
          latitude: res.lat,
          longitude: res.lng,
        }));
      }
    };
    fetchLatLng();
  }, []);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const showMap = location?.latitude && location?.longitude && !error;

  const checkLocationWarning = () => {
    if (!location.latitude && !location.longitude && inputValue) {
      setError(
        intl.formatMessage({
          id: 'component.mapWithAutoComplete.addressError',
          defaultMessage: 'Address not located. If you want to use location search please use a valid address.',
        }),
      );
    } else setError((state) => (state = null));
  }

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      setLocationStoring(true);

      const place = autocomplete.getPlace();
      setLocation((prevState) => ({
        ...prevState,
        address: place?.formatted_address ?? inputValue,
        latitude: place?.geometry?.location?.lat() ?? 0,
        longitude: place?.geometry?.location?.lng() ?? 0,
      }));
      setLocationStoring(false);

    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }, [autocomplete, inputValue]);

  useEffect(() => {
    checkLocationWarning();
    setLocation((prevState) => ({
      ...prevState,
      address: inputValue !== location.address ? inputValue : location.address,
      latitude: inputValue !== location.address ? 0 : location.latitude,
      longitude: inputValue !== location.address ? 0 : location.longitude,
    }));
  }, [inputValue]);

  useEffect(() => {
    setInputValue(location?.address || '');
    setLocationStoring(false);

    if (location?.address && location?.address !== addressModel?.address) {
      onChange(location);
    }
  }, [JSON.stringify(location)]);

  const onMapClick = useCallback(async (event) => {
    if (inputRef.current) {
      setInputValue(location?.address);
      setLocation((prevState) => ({
        ...prevState,
        address: location?.address,
        latitude: location?.latitude ?? 0,
        longitude: location?.longitude ?? 0,
      }));
    }
  }, []);

  return (
    <div style={{ marginBottom: 10 }}>
      {' '}
      <LoadScript googleMapsApiKey={API_KEY} libraries={libraries} loadingElement={<Spin />}>
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <>
            <Form.Item
              label={intl.formatMessage({
                id: 'pages.locations.address',
                defaultMessage: 'Address',
              })}
              required
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  ref={inputRef}
                  value={inputValue}
                  onChange={handleInputChange}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'component.mapWithAutoComplete.addressPlaceHolder',
                    defaultMessage: 'Please enter the address',
                  })}
                  style={{
                    width: '100%',
                    height: '32px',
                    padding: '6px 12px',
                    fontSize: '14px',
                    lineHeight: '1.42857143',
                    color: '#555',
                    backgroundColor: '#fff',
                    border: '1px solid #d9d9d9',
                    borderRadius: '20px',
                    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
                  }}
                />
                {locationStoring ? (
                  <Spin />
                ) : (
                  inputValue && (
                    <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />
                  )
                )}
              </div>
              {error && <Typography.Text type="warning">{error}</Typography.Text>}{' '}
            </Form.Item>
          </>
        </Autocomplete>
        {
          <GoogleMap
            id="map"
            mapContainerStyle={{
              width: '100%',
              height: showMap ? '400px' : 0,
              opacity: showMap ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
            center={{ lat: location?.latitude ?? 0, lng: location?.longitude ?? 0 }}
            zoom={10}
            onClick={onMapClick}
            onLoad={setMap}
            onUnmount={() => setMap(null)}
          >
            <Marker position={{ lat: location?.latitude, lng: location?.longitude }} />
          </GoogleMap>
        }
      </LoadScript>
    </div>
  );
};

export default MapWithAutocomplete;
