import React from 'react';
import { Tag } from 'antd';
import styles from './EngagementScore.less';

interface EngagementScoreProps {
  score: number;
}

export const EngagementScore: React.FC<EngagementScoreProps> = ({ score }) => {
  const getTagColorByScoreRange = (score: number) => {
    if (score < 25) {
      return 'error';
    }
    if (score < 50) {
      return 'orange';
    }
    if (score < 75) {
      return 'success';
    }
    return '#87d068';
  };
  const scoreAsNumber = Number(score);
  return !isNaN(scoreAsNumber) ? (
    <Tag className={styles.scoreTag} color={getTagColorByScoreRange(scoreAsNumber)}>
      {`${scoreAsNumber} %`}
    </Tag>
  ) : (
    <span>-</span>
  );
};
