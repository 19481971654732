import React, {useCallback, useEffect, useState} from 'react';
import MyPageContainer from '@/components/MyPageContainer';
import {Alert, Col, Divider, Empty, Row, Space, Tag, Tooltip} from 'antd';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import {DispatchFunction} from '@/typings/models/ReduxState';
import {CTAStatsModel} from '@/typings/models/CTAStats';
import debounce from "lodash/debounce";
import {CHART_THEME} from "@/constants/theme";
import Card from "../../components/Card";
import Table from "@/components/Table";
import {ProColumns} from "@ant-design/pro-table";
import {Column} from "@ant-design/charts";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import CardHelpTooltip from "../../components/Help/CardHelpTooltip";

const REDUX_ACTION_CHART = "analytics/fetchCTACharts"
const REDUX_ACTION_TABLE = "analytics/fetchCTATable"

interface MarketingReportProps {
  dispatch: DispatchFunction,
  chartLoading?: boolean,
  tableLoading?: boolean,
  chart: CTAStatsModel["chart"]
  table: CTAStatsModel["table"]
}

const TRACKING_LABEL = "CallToActions"

const SORT_SIGN = {
  'descend': '-',
  'ascend': ''
}
const CTAReport: React.FC<MarketingReportProps> = ({
                                                     dispatch,
                                                     chart,
                                                     table,
                                                     chartLoading = false,
                                                     tableLoading = false,
                                                     date
                                                   }): React.ReactNode => {

  const intl = useIntl();
  const [filters, setFilters] = useState({})
  const [sorter, setSorter] = useState({})
  const [ctas, setCtas] = useState([])

  const fetchData = useCallback(debounce(() => {
    dispatch({
      type: REDUX_ACTION_CHART
    })
    fetchCTATable()
  }, 200), [])


  const fetchCTATable = payload => {
    if (!isEmpty(sorter) && sorter.order){
      set(payload, 'sort', `${SORT_SIGN[sorter.order]}${sorter.field}`)
    }

    if (!isEmpty(filters)){
      const actionFilter = filters?.used_action?.join(",")
      if (actionFilter)
        set(payload, 'used_action', actionFilter)
    }

    dispatch({
      type: REDUX_ACTION_TABLE,
      payload
    })
  }
  useEffect(() => {
    fetchData()
  }, [date.updatedAt])

  useEffect(() => {
    setCtas(chart?.chart?.map(d => d.used_action))
  }, [JSON.stringify(chart)])

  function processChartData(chartData = [], key) {
    const processed = []
    try {
      chartData.forEach(point => {
        [key].forEach(dataKey => {
          processed.push({
            series: dataKey.toLocaleUpperCase(),
            value: point[dataKey],
            used_action: point.used_action
          })
        })
      })
    } catch (e) {

    }
    return processed

  }

  function processTableData(tableData = {}) {
    try {
      return tableData?.table?.filter(d => {
        if (filters.used_action) {
          return filters.used_action.includes(d.used_action)
        }

        return true
      }).map(d => ({...d, key: `${d.page}${d.used_action}`}))
    } catch (e) {
      return []
    }

  }

  const config = {
    isGroup: true,
    xField: 'used_action',
    yField: 'value',
    seriesField: 'series',
    theme: CHART_THEME,
    columnStyle: {
      radius: [10, 10, 0, 0],
    },
    legend: false,
    yAxis: {
      label: {
        formatter: point => parseInt(point).toLocaleString(),
      }
    },
    label: {
      position: 'middle',
      formatter: point => point.value.toLocaleString(),
      layout: [

        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  const columns: ProColumns<any>[] = [
    {
      title: "Call To Action",
      dataIndex: 'used_action',
      render: (value) => <Tag>{value}</Tag>,
      filters: (ctas || []).map(cta => ({
        text: cta,
        value: cta
      })),
    }, {
      title: (
        <F
          id="pages.cta.clicks"
          defaultMessage="Clicks"
        />
      ),
      sortDirections: ['descend'],
      dataIndex: 'clicks',
      defaultSortOrder: 'descend',
      sorter: true,
      render: value => value.toLocaleString()
    }, {
      title: (
        <F
          id="pages.cta.conversion"
          defaultMessage="Conversions"
        />
      ),
      sortDirections: ['descend'],
      dataIndex: 'converted',
      sorter: true,
      render: value => value.toLocaleString()
    }, {
      title: (
        <F
          id="pages.cta.conversionRate"
          defaultMessage="Conversion Rate"
        />
      ),
      sorter: true,
      sortDirections: ['descend'],
      dataIndex: 'conversion_rate',
      render: (value, row) => `${((row.converted / row.clicks) * 100).toFixed(1)}%`
    }, {
      title: (
        <F
          id="pages.cta.page"
          defaultMessage="Page"
        />
      ),
      dataIndex: 'page',
      render: (page) => <Tooltip title={page}>
        <a href={page} style={{fontSize: "small"}} target={"_blank"}>{new URL(page).pathname}</a>
      </Tooltip>
    },
  ];

  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{hasRangePicker: true}}>
      <Alert type="info" message={
        intl.formatMessage({
          defaultMessage: 'This data is based on tracking events. Due to tracking blockers and sampling this data will not represent all users. You may see different values compared to the dashboard.',
          id: 'pages.performance.infoText'
        })
      } style={{marginBottom: 20}}/>
      <Card loading={chartLoading}>

        {!chartLoading && chart.length === 0 ? <Empty/> :
          <Row gutter={40}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Divider>Clicks</Divider>
              <Column {...config} color={"#fa7f46"} data={processChartData(chart?.chart || [], 'clicks')}/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Divider>
                <Space>
                  <span>Conversions</span>
                  <CardHelpTooltip title={intl.formatMessage({id: 'pages.cta.conversions.help'})}/>
                </Space>
                </Divider>
              <Column {...config} color={"#27AE9D"} data={processChartData(chart?.chart || [], 'converted')}/>
            </Col>
          </Row>
        }
      </Card>
      <Divider/>
      <Table columns={columns}
             rowKey={"key"}
             fullScreen={false}
             reload={false}
             size={"small"}
             label={TRACKING_LABEL}
             pagination={{
               mode: "page",
               hasMore: table.has_more
             }}
             request={fetchCTATable}
             onTableChange={(_pagination, _filters, _sorter) => {
               setFilters(_filters)
               setSorter(_sorter)

             }}
             dataSource={processTableData(table) || []}
             loading={tableLoading}
      />
    </MyPageContainer>
  );
};
export default connect(({analytics: {ctaChart, ctaTable}, loading, date}) => ({
  chart: ctaChart || [],
  table: ctaTable || [],
  chartLoading: loading.effects[REDUX_ACTION_CHART],
  tableLoading: loading.effects[REDUX_ACTION_TABLE],
  date
}))(CTAReport)
