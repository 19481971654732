import styles from "./index.less"
import React, {ReactNode} from "react";
import {Drawer as AntDesignDrawer, DrawerProps as AntDesignDrawerProps, Grid} from "antd"
import {CloseCircleOutlined} from "@ant-design/icons";


type DrawerProps = AntDesignDrawerProps & {
  visible?: boolean,
  closable?: boolean,
  title: string | ReactNode,
  children?: ReactNode,
  width?: number,
  onVisibleChange: () => void
}


const DrawerHeader = ({title, toggle}: {title: string | ReactNode, toggle: () => void}) => <div className={styles.drawerHeader}>
  <span>{title}</span>
  <a onClick={toggle}><CloseCircleOutlined/></a>
</div>

const MyDrawer : React.FC<DrawerProps> = ({visible, closable = true, width=450, onVisibleChange, title, children, ...restProps} : DrawerProps) => {

  const {useBreakpoint} = Grid;
  const screens = useBreakpoint();
  const drawerWidth = screens.xs ? window.innerWidth : width;
  const placement = screens.xs ? 'bottom' : 'right';

  return <div className={styles.drawerContainer}>
    <AntDesignDrawer
      closable={closable}
      width={drawerWidth}
      height={'100%'}
      visible={visible}
      onClose={onVisibleChange}
      closeIcon={false}
      placement={placement}
      {...restProps}
    >
      <DrawerHeader title={title} toggle={onVisibleChange}/>
      <div className={styles.drawerBody}>{children}</div>
    </AntDesignDrawer>
  </div>
}

export default MyDrawer;
