import React, { useEffect, useRef, useState } from 'react';
import { InputRef, Popover, Select, Space, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { F } from '@/utils/intl';
import { addImageTagRequest, createTagRequest } from '@/services/api/image';

import ChooseTagGroup from './ChooseTagGroup';
import { getTreeForTagsAndTagGroups } from '@/utils/images';

export interface NewTagProps {
  setBulkEditDoneCount: (count: number) => void;
  setBulkEditTagId: (tagId: string) => void;
  imageIds: string[];
  tagList: any[];
  selectedJourney: { hashId: string };
  tags: any[];
  setTags: (tags: any[]) => void;
  image: any;
  dispatch: any;
  tagGroupList: any[];
}
const NewTag = ({
  setBulkEditDoneCount,
  setBulkEditTagId,
  imageIds,
  tagList,
  selectedJourney,
  tags,
  setTags,
  image,
  dispatch,
  tagGroupList,
}: React.PropsWithChildren<NewTagProps>) => {
  const inputRef = useRef<InputRef>(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [chooseTagGroup, setChooseTagGroup] = useState(false);
  const [selectedTagGroup, setSelectedTagGroup] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const editInputRef = useRef<InputRef>(null);
  const NEW_TAG_ID = 'NEW';

  const filteredTags = tagList.filter(
    (tag) => tags?.filter((_tag) => tag.id === _tag.id).length === 0,
  );

  const tagsData = getTreeForTagsAndTagGroups(filteredTags, tagGroupList);

  useEffect(() => {
    if (!image) return;
    setTags(image.tags);
  }, []);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const tagPlusStyle: React.CSSProperties = {
    borderStyle: 'dashed',
  };
  const showInput = () => {
    setInputVisible(true);
  };
  useEffect(() => {
    if (!chooseTagGroup) {
      setInputValue('');
    }
  }, [chooseTagGroup]);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  const findTagById = (tagId: number) => tagList.find((tag) => tag.id === tagId);
  const getOptions = (inputValue) => {
    if (inputValue)
      return [
        {
          name: (
            <Space>
              <PlusOutlined /> <strong>{inputValue}</strong>
            </Space>
          ),
          isAdd: true,
          id: NEW_TAG_ID,
        },
        ...tagsData,
      ];

    return tagsData;
  };
  const handleAddTag = (tagId) => {
    if (tagId === NEW_TAG_ID) {
      setChooseTagGroup(true);
      setInputVisible(false);
      return;
    }
    if (imageIds.length > 0) {
      setBulkEditDoneCount(0);
      setBulkEditTagId(tagId);
      return;
    }
    const tag = findTagById(tagId);
    addImageTagRequest({
      params: {
        journeyId: selectedJourney.hashId,
        imageId: image.id,
        tagId: tag.id,
      },
    }).finally(()=>{
      setChooseTagGroup(false);
      setInputValue(undefined);

    });
    setTags([...tags, tag]);
   
  };
  const handleCreateTag = (taggroup_id = selectedTagGroup) => {
    setLoading(true);
    createTagRequest(selectedJourney.hashId, inputValue, taggroup_id).then(({ data }) => {
      dispatch({
        type: 'images/fetchTags',
      });
      const tagId = data.tag.id;
      if (imageIds.length > 0) {
        setBulkEditTagId(tagId);
        setBulkEditDoneCount(0);
        return;
      }
      addImageTagRequest({
        params: {
          journeyId: selectedJourney.hashId,
          imageId: image.id,
          tagId,
        },
      }).then(() => {
        setTags([...tags, data.tag]);
      }).finally(()=>{
        setLoading(false);
        setInputValue('');
        setSelectedTagGroup(undefined);
        setChooseTagGroup(false);
        setInputVisible(false)
      });
    });
  };
  return (
    <Popover
      trigger={'click'}
      open={chooseTagGroup}
      onOpenChange={(visible) => {
        if (!visible) setChooseTagGroup(visible);
      }}
      placement={'bottom'}
      title={
        <Space>
          <F id={'pages.images.chooseGroup'} />
          <strong>{inputValue}</strong>
        </Space>
      }
      content={<ChooseTagGroup loading={loading} onConfirm={(tagGroupId)=>handleCreateTag(tagGroupId)} />}
    >
      {inputVisible ? (
        <Select
          style={{ width: 200 }}
          showSearch
          onSearch={setInputValue}
          filterOption={(input, option) => {
            if (option.isAdd) return true;
            return (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
          }}
          open={inputVisible}
          ref={inputRef}
          size={'small'}
          onBlur={() => setInputVisible(false)}
          onChange={handleAddTag}
          fieldNames={{
            label: 'name',
            options: 'tags',
            value: 'id',
          }}
          options={getOptions(inputValue)}
        />
      ) : (
        <Tag style={tagPlusStyle} onClick={showInput}>
          <PlusOutlined /> <F id={'pages.images.newTag'} />
        </Tag>
      )}
    </Popover>
  );
};

export default NewTag;
