import React from 'react';
import {Funnel} from '@ant-design/charts';
import {AnalyticsFunnelStepModel} from "@/typings/models/AnalyticsFunnel";
import {FunnelConfig} from '@ant-design/charts/es/funnel';
import {CHART_THEME, FONT_FAMILY} from "@/constants/theme";
import {Datum} from '@antv/g2/lib/interface';
import {Empty} from "antd";


const xField = 'label';
// Using events here because user_count is currently unreliable
const yField = 'total_events';
function formatLabel(datum: Datum){
  const yFieldValue = datum[yField] ? datum[yField].toLocaleString() : ''
  return `${datum[xField]} : ${yFieldValue}`
}

function formatTooltip(datum: Datum){
  const xFieldValue = datum[xField] ? datum[xField] : ''
  const yFieldValue = datum[yField] ? datum[yField].toLocaleString() : ''
  return {
    name: xFieldValue,
    value: yFieldValue
  }
}

const FunnelChart: React.FC<{data: AnalyticsFunnelStepModel[]}> = ({data = []}: {data: AnalyticsFunnelStepModel[]}) : React.ReactNode => {

  if (data.length === 0){
    return <Empty/>;
  }
  const config : FunnelConfig = {
    data: data,
    theme: CHART_THEME,
    xField,
    yField,
    animation: true,
    isTransposed: true,
    minSize: 0.03,
    maxSize: 1,
    legend: {
      itemName: {
        style: {
          fontFamily: FONT_FAMILY,
        }
      }
    },
    label: {
      style: {
        fontFamily: FONT_FAMILY,
      },
      formatter: function formatter(datum) {
        return formatLabel(datum);
      },
    },
    conversionTag: {},
    tooltip: {
      formatter: function formatter(datum) {
        return formatTooltip(datum);
      },
    },
    padding: [40, 0, 40, 0],
    autoFit: true,
  };
  return <Funnel {...config} />;
};

export default FunnelChart;
