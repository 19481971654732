import { useIntl } from 'react-intl';

const getLocationTypes = () => {
  const intl = useIntl();

  const locationTypes = [
    {
      value: 'home',
      label: intl.formatMessage({
        id: 'pages.location.home',
        defaultMessage: 'At Home',
      }),
    },
    {
      value: 'store',
      label: intl.formatMessage({
        id: 'pages.location.store',
        defaultMessage: 'Store',
      }),
    },
    {
      value: 'online',
      label: intl.formatMessage({
        id: 'pages.location.online',
        defaultMessage: 'Online',
      }),
    },
  ];

  return locationTypes;
};

export default getLocationTypes;