import React from 'react';
import {Avatar, Typography} from 'antd';
import styles from './index.less';
import {getInitials} from '@/utils/functions';

const { Text, Title } = Typography;

interface ReportItem {
  title?: string;
  avatarTitle?: string;
  description?: string;
  image?: string;
  icon?: React.ReactNode;
  iconBackground?: string;
  extraInfo?: [string];
}

const ReportItem: React.FC<ReportItem> = (props: ReportItem) => {
  const { title, description, image,avatarTitle, icon, iconBackground = 'transparent', extraInfo } = props;
  return (
    <article className={styles.ReportItem}>
      <div className={styles.ReportItem__image} style={{ backgroundColor: iconBackground }}>
        <Avatar className={styles.ReportItem__image__avatar}>
          {getInitials(avatarTitle)}
        </Avatar>
        {icon && icon}
      </div>
      <section className={styles.ReportItem__content}>
        {title && (
          <Title level={3} className={styles.ReportItem__content__title}>
            {title}
          </Title>
        )}
        {description && (
          <Text className={styles.ReportItem__content__description}>{description}</Text>
        )}
        {extraInfo && extraInfo.length && (
          <div className={styles.ReportItem__content__footer}>
            {extraInfo.map((info: string, index) => (
              <Text key={index} className={styles.ReportItem__content__footer__info}>
                {info}
              </Text>
            ))}
          </div>
        )}
      </section>
    </article>
  );
};

export default ReportItem;
