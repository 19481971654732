import * as React from 'react'
import {ReactElement, useEffect} from 'react'
import {connect, Redirect, useHistory, useModel} from "umi"
import {JourneyModel} from "@/typings/models/Journey";
import {DispatchFunction} from "@/typings/models/ReduxState";
import {RouterMatchProps} from "@/typings/common/react";

interface JourneyWrapperProps {
  children?: ReactElement | null,
  dispatch: DispatchFunction,
  match: RouterMatchProps,
  selectedJourney: JourneyModel
  config:any

}

const JourneyWrapper: React.FC<JourneyWrapperProps> = ({
  children,
  dispatch,
  selectedJourney,
  match,
  config,
}: JourneyWrapperProps) => {
  const history = useHistory();
  const { initialState, setInitialState } = useModel('@@initialState');

  useEffect(() => {
    const { journeyId } = match.params;
    if (journeyId === selectedJourney?.hashId) {
      return;
    }
    const realPath =
      match.path.split('/:journeyId').length > 1 ? match.path.split('/:journeyId')[1] : match.path;
    if (selectedJourney && selectedJourney.hashId)
      history.push(`/${selectedJourney?.hashId}${realPath}`);
  }, [selectedJourney]);

  useEffect(() => {
    setInitialState({
      ...initialState,
      config: { ...initialState?.config, ...config },
    });
  }, [JSON.stringify(config)]);

  if (match.params.journeyId && !config?.[match.params.journeyId]) {
    dispatch({
      type: 'journey/select',
      payload: {
        journeyId: match.params.journeyId,
      },
    });
  }
  if (!match.params.journeyId) {
    if (selectedJourney && selectedJourney.hashId)
      return <Redirect to={`${selectedJourney.hashId}${match.path}`} />;
  } else {
    const { journeyId } = match.params;
    if (journeyId && journeyId !== selectedJourney?.hashId) {
      return null;
    } else {
      return children;
    }
  }
};

export default connect(({ journey }) => ({
  selectedJourney: journey.selectedJourney,
  config: journey.config,
}))(JourneyWrapper);
