import React from 'react';
import { Typography, Button } from 'antd';
import { useIntl, FormattedMessage as F } from 'react-intl';

const ImageGalleryAccessRequest: React.FC = () => {
  const intl = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        marginTop: '10%',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography.Title style={{ color: 'gray' }} level={2}>
          {intl.formatMessage({
            id: 'pages.images.noCustomImageGalleryAvailable',
            defaultMessage: 'You do not have a custom image gallery yet.',
          })}
        </Typography.Title>
        <Typography.Paragraph style={{ color: 'gray' }}>
          <F
            id={'pages.images.contactSupportToSetUpImageGallery'}
            defaultMessage="You are currently not using a custom image gallery. If you want to have more control over your own images, please get in touch."
          />
        </Typography.Paragraph>
        <Button
          onClick={() => {
            window.location.href = `mailto:support@conversional.de?subject=Custom Image Gallery&body=I want to use a custom image gallery. Please get in touch.`;
          }}
          type="primary"
        >
          {intl.formatMessage({
            id: 'pages.images.requestAccess',
            defaultMessage: 'Request Custom Gallery',
          })}
        </Button>
      </div>
    </div>
  );
};

export default ImageGalleryAccessRequest;
