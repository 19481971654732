import {selectCurrentJourneyId} from '@/models/journey';
import {setChangeLoadingsUtility} from '@/utils/form';
import {trackModalView} from '@/services/tracker';
import {processAPIResponse} from '@/utils/request';
import {message} from 'antd';
import {
  createEmailPromotionRequest,
  createPromotionConditionsRequest,
  deleteEmailPromotionRequest,
  editEmailPromotionRequest, editPromotionConditionsRequest,
  fetchEmailPromotionsRequest,
  fetchEmailPromotionTypesRequest,
  fetchPromotionConditionsRequest,
} from '@/services/api/promotion';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import get from "lodash/get";

export default {
  namespace: 'promotion',

  state: {
    list: [],
    changeLoadings: {},
    promotionTypes: [],
    promotionConditions: [],
    recentlyCreatedPromotionCondition: {},
    drawer: {
      promotion: {},
      visible: false
    },
    promotionConditionDrawer:{
      visible: false
    }
  },

  effects: {
    * init({}, {put}) {
      const actions = ['fetch', 'fetchPromotionTypes', 'fetchPromotionConditions'];
      for (const action of actions) {
        yield put({
          type: action,
        });
      }
    },
    * fetch({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId);
      const {response, data} = yield call(fetchEmailPromotionsRequest, {params: {journeyId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'savePromotionList',
              payload: data,
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * fetchPromotionTypes({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId);
      const {response, data} = yield call(fetchEmailPromotionTypesRequest, {
        params: {journeyId},
      });

      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'savePromotionTypes',
              payload: data,
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * fetchPromotionConditions({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId);
      const {response, data} = yield call(fetchPromotionConditionsRequest, {
        params: {journeyId},
      });

      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'savePromotionConditions',
              payload: data,
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * create({payload}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId);
      const {response, data} = yield call(createEmailPromotionRequest, {
        params: {journeyId},
        payload,
      });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'toggleDrawer',
              payload: {},
            });
            yield put({
              type: 'fetch',
            });
          },
        },
      );
    },
    * createPromotionCondition({payload}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId);
      const {response, data} = yield call(createPromotionConditionsRequest, {
        params: {journeyId},
        payload,
      });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'togglePromotionConditionDrawer',
              payload: {},
            });
            yield put({
              type: 'createdPromotionCondition',
              payload: {
                promotionCondition: data.data
              }
            })
            yield put({
              type: 'fetchPromotionConditions',
            });
          },
        },
      );
    },
    * editPromotionCondition({payload}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId);
      const {response, data} = yield call(editPromotionConditionsRequest, {
        params: {journeyId, emailPromotionConditionId: payload.id},
        payload: omit(payload, 'id'),
      });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'togglePromotionConditionDrawer',
              payload: {},
            });
            yield put({
              type: 'fetchPromotionConditions',
            });
          },
        },
      );
    },
    * edit({payload}, {call, put, select}) {
      const {response, data} = yield call(editEmailPromotionRequest, {
        params: {emailPromotionId: get(pick(payload, 'emailPromotionId'), 'emailPromotionId')},
        payload: omit(payload, 'emailPromotionId'),
      });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'toggleDrawer',
              payload: {},
            });
            yield put({
              type: 'fetch',
            });
          },
        },
      );
    },
    * delete({payload: {emailPromotionId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteEmailPromotionRequest, {
        params: {emailPromotionId},
      });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess() {
            yield put({
              type: 'fetch',
            });
            intl &&
            message.success(
              intl.formatMessage({id: 'pages.promotions.removePromotionsSuccess'}),
            );
            cb && cb(data);
          },
          * onError(error) {
            message.error(
              error?.message ||
              intl.formatMessage({id: 'pages.promotions.removePromotionsFailed'}),
            );
          },
        },
      );
    },
  },
  reducers: {
    savePromotionList(state, action) {
      return {
        ...state,
        list: action.payload.data,
      };
    },
    savePromotionConditions(state, action) {
      return {
        ...state,
        promotionConditions: action.payload.data,
      };
    },
    savePromotionTypes(state, action) {
      return {
        ...state,
        promotionTypes: action.payload.data,
      };
    },
    setChangeLoadings(state, action) {
      return setChangeLoadingsUtility(state, action);
    },
    updateDrawerLocation(state, action) {
      const {
        payload: {promotion},
      } = action;
      return {
        ...state,
        drawer: {
          ...state.drawer,
          promotion,
        },
      };
    },
    toggleDrawer(state, action) {
      const {
        payload: {promotion},
      } = action;

      const drawer = state.drawer || {};
      if (promotion) {
        drawer.promotionId = promotion.id;
        drawer.visible = true;
        trackModalView('promotion-details');
      } else {
        drawer.visible = false;
      }

      return {
        ...state,
        drawer,
      };
    },
    togglePromotionConditionDrawer(state, action) {
      const drawer = state.promotionConditionDrawer || {};
      if (!drawer.visible) {
        drawer.visible = true;
        drawer.promotionCondition = action.payload?.promotionCondition
        trackModalView('promotion-condition-details');
      } else {
        drawer.visible = false;
        drawer.promotionCondition = null
      }

      return {
        ...state,
        promotionConditionDrawer: drawer,
      };
    },
    createdPromotionCondition(state, action) {
      return {
        ...state,
        recentlyCreatedPromotionCondition: action.payload.promotionCondition
      };
    },
    resetRecentlyCreatedPromotionCondition(state, action) {
      return {
        ...state,
        recentlyCreatedPromotionCondition: null
      };
    },
  },
};
