import React, { useEffect, useState } from 'react';
import { Tooltip, UploadProps, Upload, Radio, Divider, message, Progress, Typography, Row } from 'antd';
import axios from 'axios';
import { CheckCircleOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { connect, FormattedMessage as F, useIntl } from 'umi';

import Drawer from '@/components/Drawer';
import Paths from '@/services/paths';
import { URLParamBuilder } from '@/utils/functions';
import env from '@/constants/environment';
import { API_PATH_PREFIX } from '@/constants/path';
import { getAuthorizationHeader } from '@/services/auth';

import styles from './index.less';
import { FILE_SIZE_ERROR, UNKNOWN_ERROR, upload_server_known_status_error } from './errors';

export interface ImageTagsProps {
  visible?: boolean;
}
const MAX_UPLOAD_SIZE = 5;
const { Text } = Typography;

const ImageUploadDrawer = ({
  selectedJourney,
  dispatch,
}: React.PropsWithChildren<ImageTagsProps>) => {
  let uploadQueue = [];
  let pendingFiles = [];
  const [visible, setVisible] = useState<boolean>(false);
  const [isDirectory, setIsDirectory] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    dispatch({
      type: 'images/fetch',
    });
  }, [visible]);
  const processQueue = async ({ onProgress }) => {
    if (uploadQueue.length < 3 && pendingFiles.length > 0) {
      const file = pendingFiles.shift();
      uploadQueue.push(file);
      await uploadFile({ file, onProgress });
    }
  };
  const setFileState = (fileId: string, error: string, progress: number) => {
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [fileId]: {
        error: error,
        progress: progress,
      },
    }));
  };

  const uploadFile = async (options) => {
    let retryCount = 0;
    const { file, onProgress } = options;

    const upload = async () => {
      setFileState(file.uid, '', 0);

      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
          URLParamBuilder(Paths.UPLOAD_IMAGE, {
            params: { journeyId: selectedJourney.hashId },
            root: `${env.ROOT_URL}${API_PATH_PREFIX}`,
          }),
          formData,
          {
            headers: { ...getAuthorizationHeader(), 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setFileState(file.uid, '', percent);
              onProgress && onProgress({ percent });
            },
          },
        );
        if (response.status === 200) {
          dispatch({
            type: 'images/fetch',
          });
          // Remove the file from the queue after successful upload
          uploadQueue = uploadQueue.filter((queueFile) => queueFile.uid !== file.uid);
          processQueue({ onProgress });
        } else {
          setFileState(file.uid, upload_server_known_status_error(response.status), 0);
        }
      } catch (error) {
        Sentry.captureException(JSON.stringify(error));
        setFileState(file.uid, upload_server_known_status_error(error.status, error?.message), 0);
        if (retryCount < 3 && error?.status !== 400) {
          retryCount += 1;

          setTimeout(() => {
            upload();
          }, 3000);
        } else {
          // Remove the file from the queue after all retries have failed
          uploadQueue = uploadQueue.filter((queueFile) => queueFile.uid !== file.uid);
          setFileState(
            file.uid,
            error?.message ?? UNKNOWN_ERROR,
            0,
          );

          processQueue({ onProgress });
        }
      }
    };

    upload();
  };

  const showProgress = (fileId:string)=>{
    return (
      uploadProgress[fileId]?.progress &&
      uploadProgress[fileId]?.progress !== 100 &&
      !uploadProgress[fileId]?.error
    );
  }
  const isUploadSuccess = (fileId:string)=>{
    return uploadProgress[fileId]?.progress === 100 && !uploadProgress[fileId]?.error

  }
  const uploadProps: UploadProps = {
    name: 'file',
    beforeUpload(file) {
      const isSizeAllowed = file.size / 1024 / 1024 < MAX_UPLOAD_SIZE;

      if (!isSizeAllowed) {
        message.error(FILE_SIZE_ERROR);
        setFileState(file.uid, FILE_SIZE_ERROR, 0);
        return false;
      }

      pendingFiles.push(file);
      processQueue({ onProgress: undefined });

      return false; // prevent automatic upload
    },
    multiple: true,
    directory: isDirectory,
    headers: getAuthorizationHeader(),
    action: URLParamBuilder(Paths.UPLOAD_IMAGE, {
      params: { journeyId: selectedJourney.hashId },
      root: `${env.ROOT_URL}${API_PATH_PREFIX}`,
    }),
    itemRender: (originNode, file, fileList, actions) => {
      return (
        <div>
          <Row align={'middle'}>
            {originNode}
            {isUploadSuccess(file.uid) && (
              <CheckCircleOutlined style={{ marginTop: 10, color: '#52c41a' }} />
            )}
          </Row>
          {uploadProgress[file.uid]?.error && (
            <Text type="danger">{uploadProgress[file.uid].error}</Text>
          )}
          {showProgress(file.uid) ? (
            <Progress size="small" percent={uploadProgress[file.uid].progress} />
          ) : undefined}
        </div>
      );
    },
  };
  return (
    <>
      <Tooltip title={<F id={'pages.images.upload'} />}>
        <a
          className={styles.uploadIcon}
          style={{ color: 'inherit' }}
          onClick={() => setVisible(true)}
        >
          <UploadOutlined />
        </a>
      </Tooltip>
      <Drawer
        visible={visible}
        onVisibleChange={() => setVisible(false)}
        destroyOnClose
        title={<F onClick={() => setVisible(true)} id={'pages.images.uploadDrawerTitle'} />}
      >
        <Radio.Group
          options={[
            {
              label: <F id={'pages.images.file'} />,
              value: false,
            },
            {
              label: <F id={'pages.images.directory'} />,
              value: true,
            },
          ]}
          onChange={(e) => setIsDirectory(e.target.value)}
          value={isDirectory}
          optionType="button"
          buttonStyle="solid"
        />
        <Divider />
        <Upload.Dragger customRequest={uploadFile} {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            <F id={'pages.images.uploadDraggerText'} />
          </p>
          <p className="ant-upload-hint">
            <F id={'pages.images.uploadDraggerHint'} />
          </p>
        </Upload.Dragger>
      </Drawer>
    </>
  );
};

export default connect(({ journey }) => ({
  selectedJourney: journey.selectedJourney,
}))(ImageUploadDrawer);
