import React from "react";
import {Radio, RadioGroupProps} from "antd";
import styles from "./index.less"

export type MyRadioGroupOptionProps = {
  title: String,
  value: Number | String
}

export type MyRadioGroupProps = RadioGroupProps & {
  values: MyRadioGroupOptionProps[],
  value: Number | String
}

const MyRadioGroup: React.FC<MyRadioGroupProps> =
  ({values = [], value, onChange, size, buttonStyle, disabled}) => {
  return <Radio.Group  className={styles.radioGroup} onChange={onChange} value={value} size={size} buttonStyle={buttonStyle} disabled={disabled}>
      {values.map(option =>
        <Radio.Button key={option.value} value={option.value}>
          {option.title}
        </Radio.Button>
      )}
    </Radio.Group>
  }
;

export default (MyRadioGroup)
