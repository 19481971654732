import React, {useEffect} from "react";
import ProForm, {ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import {Button, Col, Form, Row} from "antd";
import {connect, FormattedMessage as F, useIntl} from "umi";
import {DispatchFunction} from "@/typings/models/ReduxState";
import styles from "./index.less"
import {useModel} from "@@/plugin-model/useModel";

interface UserProfileFormProps {
  loadings: {
    [key: string]: boolean
  },
  dispatch: DispatchFunction
}

const UserProfileForm: React.FC<UserProfileFormProps> = ({dispatch, loadings}) => {

  const [form] = Form.useForm()
  const intl = useIntl();


  const {initialState, setInitialState} = useModel('@@initialState');

  useEffect(() => {
    form.setFieldsValue(initialState.currentUser?.User)
  }, [initialState])

  function onValueChange(name: string, value: any) {

  }

  return <div>
    <ProForm
      form={form}
      submitter={false}
      initialValues={initialState.currentUser?.User}
    >
      <Row gutter={32}>
        <Col span={12}>
          <ProFormText
            label={intl.formatMessage({
              id: 'pages.profile.firstName',
              defaultMessage: 'First Name',
            })}
            name={"firstname"}
          />
        </Col>
        <Col span={12}>
          <ProFormText
            label={intl.formatMessage({
              id: 'pages.profile.lastName',
              defaultMessage: 'Last Name',
            })}
            name={"lastname"}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <ProFormText
            label={intl.formatMessage({
              id: 'pages.profile.email',
              defaultMessage: 'Email',
            })}
            name={"email"}
          />
        </Col>
        <Col span={12}>
          <ProFormText
            label={intl.formatMessage({
              id: 'pages.profile.phone',
              defaultMessage: 'Phone',
            })}
            name={"phone"}
          />
        </Col>

      </Row>
      <ProFormTextArea
        label={intl.formatMessage({
          id: 'pages.profile.address',
          defaultMessage: 'Address',
        })}
        name={"address"}
      />
    </ProForm>
    <div className={styles.formSubmit}>
      <Button disabled>
        <F
          id="pages.profile.saveChanges"
          defaultMessage="Save Changes"
        />
      </Button>
    </div>
  </div>
}

export default connect()(UserProfileForm)
