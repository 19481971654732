import React, { useEffect, useState } from 'react';
import ProForm, { ProFormSwitch, ProFormText, ProFormRadio } from '@ant-design/pro-form';
import { Form, Radio, Space, Tooltip } from 'antd';
import { useSelector, useIntl, useDispatch } from 'umi';
import isEmpty from 'lodash/isEmpty';

import { LocationModel } from '@/typings/models/Location';
import FORM_TYPES from '@/constants/formTypes';
import { getFormBlurProps } from '@/utils/form';
import { isVideoCallEnabled } from '@/utils/location';

import MapWithAutocomplete from '../AutoComplete/MapWithAutocomplete';
import getLocationTypes from '@/constants/locationTypes';

function processLocation(location: LocationModel) {
  return {
    ...location,
    video_call: isVideoCallEnabled(location.video_call),
  };
}

interface LocationDetailsFormProps {
  location: LocationModel;
  isNew: boolean;
  onChange?: (location: LocationModel) => void;
}

const LocationDetailsForm: React.FC<LocationDetailsFormProps> = ({
  location,
  isNew = false,
  onChange,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { loadings } = useSelector(({ location: { changeLoadings } }: DefaultRootState) => ({
    loadings: changeLoadings,
  }));
  useEffect(() => {
    setTimeout(() => {
      form.setFieldsValue(processLocation(location));
    }, 100);
  }, [JSON.stringify(location)]);

  function onValueChange(name: string, value: any) {
    if (isNew) return;
    if (name === 'address') {
      //address text - longitude - latitude
      dispatch({
        type: 'location/change',
        payload: {
          locationId: location.id,
          ...value,
        },
      });
    } else
      dispatch({
        type: 'location/change',
        payload: {
          locationId: location.id,
          [name]: value,
        },
      });
  }

  function onFormChange(changedValues, values) {
    if (!isNew) return;
    onChange && onChange(values);
  }
  return (
    <div>
      <ProForm
        form={form}
        submitter={false}
        initialValues={location}
        onValuesChange={onFormChange}
        disabled={isNew ? false : isEmpty(location)}
      >
        <ProFormText
          label={intl.formatMessage({
            id: 'pages.location.name',
            defaultMessage: 'Name',
          })}
          required={isNew}
          validateStatus={'validating'}
          hasFeedback
          {...getFormBlurProps({
            type: FORM_TYPES.TEXT,
            value: location.location,
            callback: onValueChange,
            name: 'location',
            loadings,
          })}
        />
        <ProFormRadio.Group
          disabled={loadings['appointment_type']}
          tooltip={{
            title: intl.formatMessage({
              id: 'pages.location.type_tooltip',
              defaultMessage: 'The type of a location defines what kind of appointment is booked.',
            }),
          }}
          required
          label={intl.formatMessage({
            id: 'pages.location.type',
            defaultMessage: 'Type',
          })}
          options={getLocationTypes()}
          {...getFormBlurProps({
            type: FORM_TYPES.RADIO,
            callback: onValueChange,
            name: 'appointment_type',
          })}
        ></ProFormRadio.Group>
        <MapWithAutocomplete
          addressModel={{
            address: location.address,
            latitude: location.latitude,
            longitude: location.longitude,
          }}
          onChange={
            isNew
              ? (addressValues) => onFormChange(undefined, addressValues)
              : (addressValues) => {
                  onValueChange('address', addressValues);
                }
          }
        />
        {isNew && (
          <ProFormText
            name="email"
            required
            rules={[
              {
                required: true,
                type: 'email',
                message: intl.formatMessage({
                  id: 'pages.settings.email.errorMessage',
                  defaultMessage: 'Please enter a valid email.',
                }),
              },
            ]}
            label={intl.formatMessage({
              id: 'pages.resource.email',
              defaultMessage: 'Email',
            })}
          />
        )}
        {!isNew && (
          <ProFormText
            disabled
            name="created"
            label={intl.formatMessage({
              id: 'pages.location.createDate',
              defaultMessage: 'Create Date',
            })}
          />
        )}
        <ProFormSwitch
          checkedChildren={intl.formatMessage({
            id: 'pages.location.videoCallEnabled',
            defaultMessage: 'Active',
          })}
          unCheckedChildren={intl.formatMessage({
            id: 'pages.location.videoCallDisabled',
            defaultMessage: 'Inactive',
          })}
          label={intl.formatMessage({
            id: 'pages.location.videoCall',
            defaultMessage: 'Video Call',
          })}
          {...getFormBlurProps({
            type: FORM_TYPES.SWITCH,
            callback: onValueChange,
            name: 'video_call',
            loadings,
          })}
        />
      </ProForm>
    </div>
  );
};

export default LocationDetailsForm;
