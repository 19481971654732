import React, { useCallback, useEffect } from 'react';

import { Divider, Space, Spin, Alert, Typography } from 'antd';
import { connect, useIntl, FormattedMessage as F } from 'umi';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import MyPageContainer from '@/components/MyPageContainer';
import { DispatchFunction } from '@/typings/models/ReduxState';
import Funnel from '@/components/charts/Funnel';
import { AnalyticsFunnelResponse } from '@/typings/models/AnalyticsFunnel';


const REDUX_ACTION = 'analytics/fetchFunnel';

interface PerformancePageProps {
  dispatch: DispatchFunction;
  loading: boolean;
  data: AnalyticsFunnelResponse;
}

const PerformancePage: React.FC<PerformancePageProps> = ({
  dispatch,
  loading,
  data,
  date,
}): React.ReactNode => {
  const intl = useIntl();

  const fetchData = useCallback(
    debounce(() => {
      dispatch({
        type: REDUX_ACTION,
      });
    }, 200),
    [],
  );

  useEffect(() => {
    fetchData();
  }, [date.updatedAt]);

  const pathFunnels = get(data, 'path_funnels', {});
  const mainFunnel = get(data, 'main_funnel', []);
  const pathFunnelsArray = Object.keys(pathFunnels);

  const funnelCardTitle = (key: string) => {
    if (key === 'inspirationPath') {
      return intl.formatMessage({
        defaultMessage: 'Inspiration',
        id: 'pages.performance.inspirationPath',
      });
    }

    if (key === 'instantAppointmentPath') {
      return intl.formatMessage({
        defaultMessage: 'Instant Appointment',
        id: 'pages.performance.instantAppointmentPath',
      });
    }

    return key;
  };

  return (
    <MyPageContainer extraProps={{ hasRangePicker: true }}>
      <Spin spinning={loading}>
        <Alert
          type="info"
          message={intl.formatMessage({
            defaultMessage:
              'This data is based on tracking events. Due to tracking blockers and sampling this data will not represent all users. You may see different values compared to the dashboard.',
            id: 'pages.performance.infoText',
          })}
          style={{ marginBottom: 20 }}
        />
        <Funnel
          cardTitle={intl.formatMessage({
            defaultMessage: 'Performance Funnel',
            id: 'pages.performance.cardTitle',
          })}
          data={mainFunnel.filter((d) => typeof d !== 'number')}
        />
        {pathFunnelsArray.length > 0 && (
          <Divider style={{ marginTop: 60 }}>
            {' '}
            <Typography.Title level={3}>
              <F id={'pages.performance.paths'} />
            </Typography.Title>
          </Divider>
        )}
        <Alert
          type="info"
          message={intl.formatMessage({
            defaultMessage:
              "Paths can be initiated from the main questionnaire. Their 'started' value refers to the path's start, not the tool's start. Therefore, the starts of all paths are lower than the tool starts if users cancel in the first step.",
            id: 'pages.performance.explanation',
          })}
          style={{ marginBottom: 20 }}
        />
        <Space style={{ width: '100%', marginTop: 10 }} direction={'vertical'} size={20}>
          {pathFunnelsArray
            .filter((key) => pathFunnels[key].length > 2)
            .map((key) => (
              <Funnel
                cardTitle={funnelCardTitle(key)}
                data={pathFunnels[key].filter((d) => typeof d !== 'number')}
              />
            ))}
        </Space>
      </Spin>
    </MyPageContainer>
  );
};

export default connect(({ analytics: { funnel }, loading, date }) => ({
  data: funnel,
  date,
  loading: loading.effects[REDUX_ACTION],
}))(PerformancePage);
