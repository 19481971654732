import React from "react";
import Card from "@/components/Card";
import RingChart from "@/components/charts/Ring/RingChart";
import {StylesStatsModel} from "@/typings/models/StylesStats";
import {capitalizeAllLetters} from "@/utils/functions";

export type RingProps = {
  cardTitle: String,
  helpTooltip?: String,
  chartData: StylesStatsModel['values']
}
const Ring : React.FC<RingProps> = ({cardTitle, chartData, helpTooltip}) => {
  return <Card title={capitalizeAllLetters(cardTitle)}>
    <RingChart data={chartData}/>
  </Card>
}

export default Ring
