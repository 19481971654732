import React, {useEffect, useState} from "react";
import ProForm from "@ant-design/pro-form";
import {Form, Radio, Space} from "antd";
import {useSelector} from "umi";
import {PromotionModel} from "@/typings/models/Promotion";
import DynamicForm from "@/components/DynamicForm";


interface PromotionTypeFormProps {
  promotion: PromotionModel,
  isNew: boolean,
  onChange?: (promotion: PromotionModel) => void,
}

const PromotionTypeForm: React.FC<PromotionTypeFormProps> = ({
                                                                   promotion, isNew = false,
                                                                   onChange,
                                                                 }) => {

  const [form] = Form.useForm()
  const [selectedPromotionTypeId, setSelectedPromotionTypeId] = useState<string>()


  const {promotionTypes} = useSelector(({promotion: {promotionTypes}}: DefaultRootState) => ({
    promotionTypes
  }))

  useEffect(() => {
    if (isNew) return
    setTimeout(() => {
      setSelectedPromotionTypeId(promotion.promo_type_id)
      form.setFieldsValue(promotion.promo)
      onFormChange(null, {
        ...promotion.promo,
        id: promotion.promo_type_id
      })
    }, 100)
  }, [JSON.stringify(promotion)])


  function onFormChange(changedValues, values) {
    onChange && onChange({id: selectedPromotionTypeId, ...values,...changedValues})
  }

  const selectedPromotionType = promotionTypes.find(
    (promotionType) => promotionType.id === selectedPromotionTypeId,
  );
  return (
    <div>
      <ProForm
        form={form}
        submitter={false}
        initialValues={promotion}
        onValuesChange={onFormChange}
      >
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Radio.Group
            onChange={(e) => setSelectedPromotionTypeId(e.target.value)}
            value={selectedPromotionTypeId}
            options={promotionTypes.map((promotionType) => ({
              label: promotionType.title,
              value: promotionType.id,
            }))}
          />
          {selectedPromotionType && (
            <DynamicForm onValuesChange={onFormChange} initialValues={promotion.promo} form={form} fields={selectedPromotionType.fields} />
          )}
        </Space>
      </ProForm>
    </div>
  );
}

export default PromotionTypeForm
