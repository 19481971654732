import React, {useEffect, useState} from "react";
import ProForm, {ProFormSwitch, ProFormText} from "@ant-design/pro-form";
import {Alert, Form, Space, Typography} from "antd";
import {useIntl, useSelector} from "umi";
import FORM_TYPES from "@/constants/formTypes"
import {getFormBlurProps} from "@/utils/form";
import {PromotionModel} from "@/typings/models/Promotion";
import moment from "moment";
import MyRangePicker from "@/components/MyRangePicker";
import pick from "lodash/pick";

interface PromotionDetailsFormProps {
  promotion: PromotionModel,
  isNew: boolean,
  onChange?: (promotion: PromotionModel) => void,
}

const PromotionDetailsForm: React.FC<PromotionDetailsFormProps> = ({
                                                                     promotion, isNew = false,
                                                                     onChange,
                                                                   }) => {

  const [form] = Form.useForm()
  const [range, setRange] = useState({
    start: moment().unix() * 1000,
    end: moment().add(4, 'weeks').unix() * 1000
  });

  const intl = useIntl();

  const {loadings, length} = useSelector(({promotion: {changeLoadings, list}}: DefaultRootState) => ({
    loadings: changeLoadings,
    length: list.length
  }))

  useEffect(() => {
    if (isNew) return
    const formValue = pick(promotion, ['title', 'is_active'])
    form.setFieldsValue(formValue)

    setTimeout(() => {
      const rangeValue = {
        start: moment(promotion.start_date, "YYYY-MM-DD").unix() * 1000,
        end: moment(promotion.end_date, "YYYY-MM-DD").unix() * 1000,
      }
      setRange(rangeValue)
    }, 200)
  }, [JSON.stringify(promotion)])

  useEffect(() => {
    onFormChange()
  }, [JSON.stringify(range)])

  function onFormChange(changedValues = null, values = form.getFieldsValue()) {
    onChange && onChange({
      range,
      ...values
    })
  }

  return (
    <div>
      <ProForm
        form={form}
        submitter={false}
        initialValues={promotion}
        onValuesChange={onFormChange}
      >
        {promotion.notice && (
          <Alert
            style={{ marginBottom: 24 }}
            type={'error'}
            message={
              <Space direction={'vertical'}>
                <Typography.Title level={5}>{promotion.notice}</Typography.Title>
              </Space>
            }
          />
        )}
        <ProFormText
          label={intl.formatMessage({
            id: 'pages.promotions.title',
            defaultMessage: 'Title',
          })}
          required
          validateStatus={'validating'}
          hasFeedback
          initialValue={`Promotion #${length + 1}`}
          {...getFormBlurProps({
            type: FORM_TYPES.TEXT,
            value: promotion.title,
            name: 'title',
            loadings,
          })}
        />
        <ProForm.Item
          label={intl.formatMessage({
            id: 'pages.promotions.dates',
            defaultMessage: 'Dates',
          })}
        >
          <MyRangePicker global={false} bordered onDateChange={setRange} localDate={range} />
        </ProForm.Item>
        {!isNew && (
          <ProFormText
            disabled
            name="created"
            label={intl.formatMessage({
              id: 'pages.location.createDate',
              defaultMessage: 'Create Date',
            })}
          />
        )}
        <ProFormSwitch
          initialValue={true}
          checkedChildren={intl.formatMessage({
            id: 'pages.location.videoCallEnabled',
            defaultMessage: 'Active',
          })}
          unCheckedChildren={intl.formatMessage({
            id: 'pages.location.videoCallDisabled',
            defaultMessage: 'Inactive',
          })}
          label={intl.formatMessage({
            id: 'pages.location.videoCallEnabled',
            defaultMessage: 'Active',
          })}
          {...getFormBlurProps({
            type: FORM_TYPES.SWITCH,
            name: 'is_active',
            loadings,
          })}
        />
      </ProForm>
    </div>
  );
}

export default PromotionDetailsForm
