export default {
  // region At A Glance - Dashboard Page
  'component.atAGlance.totalCustomers': 'Número total de clientes',
  'component.atAGlance.totalCustomers.tooltip':
    'Todos los clientes que han rellenado el cuestionario.',
  'component.atAGlance.totalRequests': 'Número total de solicitudes',
  'component.atAGlance.totalRequests.tooltip': 'Todos los clientes que hayan solicitado una cita.',
  'component.atAGlance.totalSubscribers': 'Número total de abonados',
  'component.atAGlance.totalSubscribers.tooltip':
    'Todos los clientes sin solicitud de cita que hayan confirmado su correo electrónico para retargeting.',
    'component.atAGlance.sentMails': 'Correos enviados',
    // endregion

  // region Registrations - Dashboard Page
  'component.registrationsChart.day': 'Día',
  'component.registrationsChart.week': 'Semana',
  'component.registrationsChart.month': 'Mes',
  'component.registrationsChart.year': 'Año',
  'component.registrationsChart.subscribers': 'Abonados',
  // endregion

  // region ServiceRequest - Dashboard Page
  'component.serviceRequest.cardTitle': 'Últimas solicitudes de atención al cliente',
  'component.serviceRequest.cardTooltip':
    'Indica las consultas actuales de los clientes que han pedido una cita',
  'component.serviceRequest.showAll': 'Mostrar todo',
  'component.serviceRequest.scrollMore': 'Navegue hacia abajo para cargar más',
  'component.serviceRequest.title': 'Solicitud de atención al cliente',
  'component.serviceRequest.drawerDefaultLabel':
    'Cargar detalles de solicitud de atención al cliente',
  'component.serviceRequest.noMoreLoad': 'No queda más por cargar...',
  // endregion

  // region CustomerDrawer
  'component.CustomerDrawer.qualification': 'Títulos',
  'component.CustomerDrawer.status': 'Estatus',
  'component.CustomerDrawer.notes': 'Comentarios',
  'component.CustomerDrawer.profile': 'Perfil',
  'component.CustomerDrawer.styles': 'Estilos',
  'component.CustomerDrawer.results': 'Resultados',
  'component.CustomerDrawer.unsubscribe': 'Cancelar la suscripción',
  'component.CustomerDrawer.showProfile': 'Mostrar Perfil',
  // endregion

  // region LocationDrawer - Locations Page
  'component.locationDrawer.availability': 'Disponibilidad',
  'component.locationDrawer.addOpeningHours': 'Añadir horarios de apertura habituales',
  'component.locationDrawer.currentOpeningHours': 'Horarios de apertura habituales',
  'component.locationDrawer.saturday': 'Sábado',
  'component.locationDrawer.sunday': 'Domingo',
  'component.locationDrawer.monday': 'Lunes',
  'component.locationDrawer.tuesday': 'Martes',
  'component.locationDrawer.wednesday': 'Miércoles',
  'component.locationDrawer.thursday': 'Jueves',
  'component.locationDrawer.friday': 'Viernes',
  'component.locationDrawer.from': 'De',
  'component.locationDrawer.to': 'A',
  'component.locationDrawer.blockHours': 'Establecer un horario de apertura extraordinario',
  'component.locationDrawer.add': 'Agregar',
  // endregion

  'component.tagSelect.expand': 'Ampliar',
  'component.tagSelect.collapse': 'Disminuir',
  'component.tagSelect.all': 'Todo',

  // region PeriodSelector
  'component.periodSelector.day': 'Día',
  'component.periodSelector.week': 'Semana',
  'component.periodSelector.month': 'Mes',
  // endregion

  // region Table
  'component.table.downloadCSV': 'Descargar CSV',
  'component.table.showing': 'Mostrando',
  'component.table.of': 'los',
  'component.table.items': 'registros',
  'component.table.page': 'Página',

  // endregion

  // region Account component
  'component.account.profile': 'Perfil',
  'component.account.billings': 'Facturas',
  'component.account.language': 'Idioma',
  'component.account.language.select': 'Elija su idioma:',
  // endregion

  // region Registration Growth
  'component.registrationGrowth.title': 'Inscripciones',
  'component.registrationGrowth.hint':
    'Número de usuarios recién registrados con o sin cita previa',
  'component.registrationGrowth.change': 'Cambia',
  'component.registrationGrowth.cumulative': 'Total',
  // endregion

  // region User Menu Dropdown
  'component.userMenu.settings': 'Configuración',
  'component.userMenu.billing': 'Facturación',
  'component.userMenu.support': 'Servicio de asistencia',
  'component.userMenu.signOut': 'Desconectar',
  // endregion

  // region MApWithAutoComplete
  'component.mapWithAutoComplete.addressPlaceHolder': 'Por favor ingrese la dirección',
  'component.mapWithAutoComplete.addressError': "Dirección no localizada. Si desea utilizar la búsqueda de ubicación, por favor use una dirección válida.",
    // endregion

  // region Account
  'component.settings.visibility': 'Visibilidad',
   // endregion
};
