import { request } from './request';
import * as PATHS from '@/constants/path';
import { URLParamBuilder } from '@/utils/functions';
import { GetImagesRequest } from '../../typings/api/getImages';

// region Fetch Images
type FetchImagesForJourneyOptions = {
  params: GetImagesRequest['parameters'];
  url?: string;
  query?: GetImagesRequest['query'];
};

export function fetchImagesForJourney({ params, url, query = {} }: FetchImagesForJourneyOptions) {
  const finalUrl =
    url ||
    URLParamBuilder(PATHS.GET_IMAGES, {
      queryString: query,
      params,
    });
  return request(finalUrl, {
    method: 'GET',
    absoluteURL: url,
  });
}

// endregion

// region Remove Image
type RemoveImageRequestOptions = {
  params: {
    journeyId: string;
  };
  queryString: any

};

export function removeImageRequest({ params, queryString }: RemoveImageRequestOptions) {
  return request(
    URLParamBuilder(PATHS.REMOVE_IMAGE, {
      params,
      queryString
    }, false),
    {
      method: 'DELETE',
    },
   
  );
}

// endregion

// region Fetch Tags
type FetchTagsOptions = {
  params: {
    journeyId: string;
  };
};

export function fetchTags({ params }: FetchTagsOptions) {
  return request(
    URLParamBuilder(PATHS.GET_TAGS, {
      params,
    }),
    {
      method: 'GET',
    },
  );
}

// endregion

// region Fetch Tag Groups
type FetchTagGroupsOptions = {
  params: {
    journeyId: string;
  };
};

export function fetchTagGroups({ params }: FetchTagGroupsOptions) {
  return request(
    URLParamBuilder(PATHS.GET_TAG_GROUPS, {
      params,
    }),
    {
      method: 'GET',
    },
  );
}

// endregion

// region Remove Image Tag
type RemoveImageTagOptions = {
  params: {
    journeyId: string;
    imageId: string;
    tagId: string;
  };
};

export function removeImageTag({ params }: RemoveImageTagOptions) {
  return request(
    URLParamBuilder(PATHS.DELETE_IMAGE_TAG, {
      params,
    }),
    {
      method: 'DELETE',
    },
  );
}

// endregion

// region Add Image Tag
type AddImageTagOptions = {
  params: {
    journeyId: string;
    imageId: string;
    tagId: string;
  };
};



export function addImageTagRequest({ params }: RemoveImageTagOptions){
  return request(
    URLParamBuilder(PATHS.ADD_IMAGE_TAG, {
      params,
    }),
    {
      method: 'POST',
    },
  );
}

// endregion

// region Create Tag

type addImageTagResponse = {
    data: {
      tag: {id: string, name: string, count: number, taggroup: {id: string, title: string}}
    }
};
export function createTagRequest(journeyId:string,tagName:string,groupId:string): Promise<addImageTagResponse> {
  const params = {
    journeyId: journeyId,
  };
  const payload = {name: tagName, taggroup_id: groupId}
  return request(
    URLParamBuilder(PATHS.CREATE_TAG, {
      params,
    }),
    {
      method: 'POST',
      data: payload,
    },
  ) as Promise<addImageTagResponse>;
}

// endregion

// region Remove Tag
type RemoveTagRequestOptions = {
  params: {
    journeyId: string;
    tagId: string;
  };
};

export function removeTagRequest({ params }: RemoveTagRequestOptions) {
  return request(
    URLParamBuilder(PATHS.DELETE_TAG, {
      params,
    }),
    {
      method: 'DELETE',
    },
  );
}

// endregion

// region Edit Tag
type EditTagRequestOptions = {
  params: {
    journeyId: string;
    tagId: string;
  };
  payload: any;
};

export function editTagRequest({ params, payload }: EditTagRequestOptions) {
  return request(
    URLParamBuilder(PATHS.EDIT_TAG, {
      params,
    }),
    {
      method: 'PUT',
      data: payload,
    },
  );
}

// endregion

// region Edit Tag
type CreateTagGroupRequestOptions = {
  params: {
    journeyId: string;
  };
  payload: {
    title: string;
    display_color: string;
  };
};

export function createTagGroupRequest({ params, payload }: CreateTagGroupRequestOptions) {
  return request(
    URLParamBuilder(PATHS.CREATE_TAG_GROUP, {
      params,
    }),
    {
      method: 'POST',
      data: payload,
    },
  );
}

// endregion
