import { FETCH_TAGS_STATS_2TAG_GROUPS, FETCH_TAGS_STATS_3TAG_GROUPS } from './../../constants/path';
import { URLParamBuilder } from "@/utils/functions";
import {request} from "@/services/api/request";


type FetchTagStatisticsRequestOptions = {
    params: {
      journeyToken: string,
      tagGroup1Hashid: string,
      tagGroup2Hashid: string,
      tagGroup3Hashid?: string,
    }
  }
export function fetchTagStatisticsRequestFor2TagGroups({params}: FetchTagStatisticsRequestOptions) {
    const url = URLParamBuilder(FETCH_TAGS_STATS_2TAG_GROUPS, {
      params: {
        journeyToken: params.journeyToken,
        tagGroup1Hashid: params.tagGroup1Hashid,
        tagGroup2Hashid: params.tagGroup2Hashid,
      }
    });
    return request(url, {
      method: 'GET',
    });
  }
  export function fetchTagStatisticsRequestFor3TagGroups({params}: FetchTagStatisticsRequestOptions) {
    const url = URLParamBuilder(FETCH_TAGS_STATS_3TAG_GROUPS, {
      params: {
        journeyToken: params.journeyToken,
        tagGroup1Hashid: params.tagGroup1Hashid,
        tagGroup2Hashid: params.tagGroup2Hashid,
        tagGroup3Hashid: params.tagGroup3Hashid,
      },
    });
  
    return request(url, {
      method: 'GET',
    });
}