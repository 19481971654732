import React from 'react';
import {measureTextWidth, Pie} from '@ant-design/charts';
import {CHART_THEME, FONT_FAMILY} from "@/constants/theme";
import {PieConfig} from '@ant-design/charts/es/pie';
import {StylesStatsModel} from "@/typings/models/StylesStats";
import {mapRingChartData} from "@/utils/chart";

interface RingChartProps {
  data: StylesStatsModel['feature']
}

const RingChart: React.FC<RingChartProps> = ({data}) => {
  function renderStatistic(containerWidth, text, style) {
    const _measureTextWidth = (0, measureTextWidth)(text, style),
      textWidth = _measureTextWidth.width,
      textHeight = _measureTextWidth.height;
    const R = containerWidth / 2;
    const scale = 1;
    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
        ),
        1,
      );
    }
    const textStyleStr = 'width:'.concat(containerWidth, 'px;');
    return '<div style="'
      .concat(textStyleStr, ';font-size:')
      .concat(scale, 'em;line-height:')
      .concat(scale < 1 ? 1 : 'inherit', ';">')
      .concat(text, '</div>');
  }


  const config: PieConfig = {
    theme:CHART_THEME,
    appendPadding: 10,
    data: mapRingChartData(data),
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      itemName: {
        style: {
          fontFamily: FONT_FAMILY
        }
      }
    },
    innerRadius: 0.75,
    label: false,
    statistic: {
      title: {
        offsetY: -4,
        customHtml: function customHtml(container, view, datum) {
          const _container$getBoundin = container.getBoundingClientRect(),
            width = _container$getBoundin.width,
            height = _container$getBoundin.height;
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Total';
          return renderStatistic(d, text, {fontSize: 28});
        },
      },
    },
    interactions: [
      {type: 'element-selected'},
      {type: 'element-active'},
      {type: 'pie-statistic-active'},
    ],
  };
  return <div className={"ring-chart"}>
    <Pie {...config} />
  </div>;
};

export default RingChart;
