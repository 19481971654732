export function ActionBuilder(category: string, action: string, label?: string, options?: any){
  return {
    category, action, label
  }
}

export const CUSTOMER_EDIT_ACTION = (editKey?: string) => ActionBuilder('customer', 'edit', editKey)
export const LOCATION_EDIT_ACTION = (editKey?: string) => ActionBuilder('location', 'edit', editKey)
export const SERVICE_REQUEST_ACTION = (editKey?: string) => ActionBuilder('location', 'edit', editKey)

export const ADD_LOCATION_AVAILABILITY_ACTION = () => ActionBuilder('location', 'availability', 'add')
export const REMOVE_LOCATION_AVAILABILITY_ACTION = () => ActionBuilder('location', 'availability', 'remove')

export const ADD_LOCATION_BLOCK_HOURS = () => ActionBuilder('location', 'block-hours', 'add')
export const REMOVE_LOCATION_BLOCK_HOURS = () => ActionBuilder('location', 'block-hours', 'remove')
