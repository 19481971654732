import moment, { Moment } from 'moment';
import * as React from 'react';
import { getLocale } from 'umi';

interface DateFormatProps {
  date: Moment | string;
  format?: string;
  ignoreLocalTimezone?: boolean;
}

const DateFormat = ({
  date,
  format = 'YYYY-MM-DD HH:mm',
  ignoreLocalTimezone,
}: DateFormatProps) => {
  if (typeof date === 'string') {
    if (ignoreLocalTimezone) {
      date = date.split('+')[0];
      return <>{moment(date).format(format)}</>;
    }

    date = moment(date);
  }

  return <span>{date.locale(getLocale()).format(format)}</span>;
};

export default DateFormat;
