import React, {useEffect, useState} from 'react';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import MyPageContainer from '@/components/MyPageContainer';
import Table from '@/components/Table';
import type {ProColumns} from '@ant-design/pro-table';
import {BillingModel, BillingOverviewModel, BillingTableDataModel,} from '@/typings/models/Billing';
import {DispatchFunction} from '@/typings/models/ReduxState';
import {RouterMatchProps} from '@/typings/common/react';
import JourneySelectLink from '@/components/JourneySelectLink';
import createExpandedRowFromColumns from '@/components/Table/createExpandedRowFromColumns';
import Money, {getMoneyString} from '@/components/Money';
import CustomerName from '@/components/Customer/CustomerName';

interface BillingDetailsProps {
  dispatch: DispatchFunction;
  billings: {
    [billingId: string]: BillingTableDataModel;
  };
  overviewList: BillingOverviewModel[];
  loading: boolean;
  match: RouterMatchProps;
}
const TRACKING_LABEL = "BillingDetails"
const BillingDetails: React.FC<BillingDetailsProps> = ({
  dispatch,
  billings,
  overviewList,
  loading,
  match,
}) => {
  const intl = useIntl();

  const [overview, setOverview] = useState<BillingOverviewModel>();
  function getBillingId() {
    return match.params.billingId;
  }

  function getBilling() {
    return billings[getBillingId()];
  }
  function getDataSource() {
    const currentBilling = getBilling();
    if (currentBilling) {
      return currentBilling.items;
    }
    return [];
  }

  function getExportUrl() {
    const currentBilling = getBilling();
    if (currentBilling) {
      return currentBilling.links?.export;
    }
    return null;
  }
  useEffect(() => {
    if (overviewList.length > 0) {
      const billingId = getBillingId();
      setOverview(overviewList.filter((overview) => overview.links.href.includes(billingId))[0]);
    }
  }, [overviewList]);

  useEffect(() => {
    fetchTableData();
  }, [match]);

  function fetchTableData(payload: any = undefined) {
    dispatch({
      type: 'billing/fetchDetails',
      payload: {
        billingId: getBillingId(),
      },
    });
  }

  const columns: ProColumns<BillingModel>[] = [
    {
      title: intl.formatMessage({
        id: "pages.billings.customer",
        defaultMessage: "Customer"
      }),
      dataIndex: 'email',
      render: (_, billing: BillingModel) => (
        <CustomerName drawer={true} customer={{ email: billing.email, links: billing.links }}>
          {billing.email}
        </CustomerName>
      ),
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.journey",
        defaultMessage: "Journey"
      }),
      dataIndex: 'journey',
      render: (journeyId: string) => <JourneySelectLink journeyId={journeyId} />,
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.event",
        defaultMessage: "Event"
      }),
      dataIndex: 'event',
      responsive: ['xxl', 'xl'],
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.created",
        defaultMessage: "Created"
      }),
      dataIndex: 'created',
      responsive: ['xxl', 'xl'],
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.mode",
        defaultMessage: "Mode"
      }),
      dataIndex: 'mode',
      responsive: ['xxl', 'xl'],
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.subtotal",
        defaultMessage: "subtotal"
      }),
      dataIndex: 'subtotal',
      responsive: ['xxl', 'xl'],
      csvRender: (subtotal: string) => getMoneyString(subtotal),
      render: (subtotal: string) => <Money amount={subtotal} />,
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.discount",
        defaultMessage: "Discount"
      }),
      dataIndex: 'discount',
      responsive: ['xxl', 'xl'],
      csvRender: (discount: string) => getMoneyString(discount),
      render: (discount: string) => <Money amount={discount} />,
    },
    {
      title: intl.formatMessage({
        id: "pages.billings.total",
        defaultMessage: "Total"
      }),
      dataIndex: 'total',
      csvRender: (total: string) => getMoneyString(total),
      render: (total: string) => <Money amount={total} />,
    },
  ];

  function handleDownloadExport(){
    let exportData = ""
    exportData += columns.map(column => column.title).join(",")
    getDataSource().forEach(data => {
      exportData += "\n" + columns.map(column => {
        const value = data[column.dataIndex]
        const renderedValue = column.csvRender ? column.csvRender(value) : value
        return renderedValue || " - "
      }).join(",")
    })

    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([exportData], {type: 'text/csv'}));
    a.download = `conversional-billing-${getBillingId()}.csv`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  //'menu.BillingDetails'
  return (
    <MyPageContainer label={TRACKING_LABEL} title={intl.formatMessage({
      id: 'pages.BillingDetails',
      defaultMessage: 'Billing Details',
    })} extraProps={{ exportTable: { downloadAction: handleDownloadExport, url: true }, hasRangePicker: false }}>
      <Table
        headerTitle={intl.formatMessage({
          id: 'pages.billings.tableTitle',
          defaultMessage: 'Billings',
        })}
        columns={columns}
        pagination={false}
        search={false}
        rowKey={'email'}
        dataSource={getDataSource()}
        loading={loading}
        label={TRACKING_LABEL}
        request={fetchTableData}
        expandableRow={createExpandedRowFromColumns<BillingModel>(columns)}
      />
    </MyPageContainer>
  );
};

export default connect(({ billing, loading }) => ({
  overviewList: billing.overview,
  loading: loading.effects['billing/fetchDetails'],
  billings: billing.details,
}))(BillingDetails);
