import {MailOutlined} from '@ant-design/icons';
import {Button, message, Result, Typography} from 'antd';
import React, {useState} from 'react';
import ProForm, {ProFormText} from '@ant-design/pro-form';
import {FormattedMessage as F, NavLink, useIntl} from 'umi';

import UserLayout from "@/pages/user/UserLayout";
import styles from "@/pages/user/Login/index.less";
import layoutStyles from "@/pages/user/userLayout.less";
import {forgotPasswordRequest} from '@/services/api/user';

const ForgotPassword: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const intl = useIntl();


  function handleSubmit(values){
    setSubmitting(true)
    forgotPasswordRequest({payload: values}).then(({response, data}) => {
      setSubmitting(false)
      if (response.status === 200){
        setIsSent(true)
      }else{
        message.error(data.message)
      }
    })
  }

  return (
    <UserLayout>
      {isSent ? <Result
        status="success"
        title={<F id={"pages.forgotPassword.sentMail"}/>}
        subTitle={<F id={"pages.forgotPassword.sentMail"}/>}
        extra={[
          <NavLink key={"login"} to={"/user/login"}>
            <Button type="primary" key="console">
              <F id={"pages.login.submit"}/>
            </Button>,
          </NavLink>
        ]}
      /> :
      <div>
        <div className={layoutStyles.pageTitle}>
          <Typography.Title><F id={"pages.forgotPassword.forgotPasswordTitle"} defaultMessage={"Reset Password"}/></Typography.Title>
          <Typography.Text><F id={"pages.forgotPassword.hint"} defaultMessage={"To which Email should the password reset information be sent to?"}/></Typography.Text>
        </div>
        <ProForm
          submitter={{
            searchConfig: {
              submitText: intl.formatMessage({
                id: 'pages.forgotPassword.submit',
                defaultMessage: 'Forgot Password',
              }),
            },
            render: (_, dom) => dom.pop(),
            submitButtonProps: {
              loading: submitting,
              size: 'large',
              style: {
                width: '100%',
              },
            },
          }}
          onFinish={handleSubmit}
        >
          <ProFormText
            name="email"
            label={intl.formatMessage({
              id: 'pages.forgotPassword.label',
              defaultMessage: 'Email Address'
            })}
            tooltip={intl.formatMessage({
              id: 'pages.forgotPassword.tooltip',
              defaultMessage: 'If you do not remember your email address please contact us through support@conversional.de'
            })}
            fieldProps={{
              size: 'large',
              prefix: <MailOutlined className={styles.prefixIcon} />,
            }}
            placeholder={intl.formatMessage({
              id: 'pages.forgotPassword.placeholder',
              defaultMessage: 'Email Address',
            })}
            rules={[
              {
                required: true,
                message: (
                  <F
                    id="pages.forgotpassword.required"
                    defaultMessage="This field is required! please type your email address."
                  />
                ),
              },
            ]}
          />

        </ProForm>
        <div style={{marginTop: 24, textAlign: 'center'}}>
          <NavLink
            to={"/user/login"}
            className={"color-secondary"}
          >
            <F id="pages.forgotPassword.login" defaultMessage="Back to login" />
          </NavLink>
        </div>
      </div>}
    </UserLayout>
  )
}

export default ForgotPassword
