import styles from "./index.less"
import React from "react";
import { Row, Typography } from 'antd';
import {EditOutlined, RightOutlined} from "@ant-design/icons";
import {useDispatch, useSelector, FormattedMessage as F,useIntl} from "umi";
import isEmpty from "lodash/isEmpty";

import Drawer from "@/components/Drawer";
import {ReadonlySectionsProps} from "@/typings/models/ReadonlyForm";
import ReadonlySections from "@/components/ReadonlySections";
import NestedDrawerButton from "@/components/buttons/NestedDrawerButton";
import CustomerDrawer from "@/components/CustomerDrawer";
import { DEFAULT_CURRENCY_UNIT } from "@/constants/currencyUnit";

const ServiceRequestDrawer: React.FC = () => {

  const {visible, nested, serviceRequest} = useSelector(({serviceRequest: {drawer}, customers}: DefaultRootState) => ({
    nestedCustomer: customers?.drawer?.nested,
    ...drawer,
  }))
  const intl = useIntl();
  const selectedJourneyConfig = useSelector(
    (state: DefaultRootState) => state.journey?.config?.[state.journey.selectedJourney?.hashId],
  );
  const currencyUnit = selectedJourneyConfig?.locale?.currency_unit || DEFAULT_CURRENCY_UNIT;


  const dispatch = useDispatch()
  function toggle() {
    dispatch({
      type: 'serviceRequest/toggleDrawer',
      payload: {
        serviceRequest: null
      }
    })
  }
  const showChildrenDrawer = (customer) => {
    dispatch({
      type: 'customers/toggleDrawer',
      payload: {
       customer,
        nested: true
      }
    })
  }

  const readonlySectionsConfig: ReadonlySectionsProps['config'] = [
    {
      title: 'pages.serviceRequest.qualification',
      defaultMessage: 'Qualification',
      visible: true,
      jsx: (
        <>
          <Row className={styles.formItemLabel}>
            <Typography.Text level={3}>
              {intl.formatMessage({
                id: 'pages.customer.state',
                defaultMessage: 'Status',
              })}
            </Typography.Text>
          </Row>
          <Row style={{ display: 'block' }} className={styles.readonlyForm}>
            <NestedDrawerButton
              isInput
              icon={<EditOutlined />}
              block
              onClick={() => showChildrenDrawer(serviceRequest?.Customer)}
            >
              {serviceRequest?.State?.label}
            </NestedDrawerButton>
          </Row>
          <Row className={styles.formItemLabel}>
            <Typography.Text level={3}>
              {intl.formatMessage({
                id: 'pages.customer.orderVolume',
                defaultMessage: 'Order Volume',
              })}
            </Typography.Text>
          </Row>
          <Row style={{ display: 'block' }} className={styles.readonlyForm}>
            <NestedDrawerButton
              isInput
              icon={<EditOutlined />}
              block
              onClick={() => showChildrenDrawer(serviceRequest?.Customer)}
            >
              {serviceRequest?.Customer?.order_volume
                ? serviceRequest?.Customer?.order_volume + currencyUnit
                : ''}
            </NestedDrawerButton>
          </Row>
        </>
      ),
    },
    {
      title: 'pages.serviceRequest.customer',
      defaultMessage: 'Customer',
      visible: !nested,
      form: {
        data: serviceRequest?.Customer,
        config: [
          {
            name: 'pages.customers.firstName',
            defaultMessage: 'First Name',
            key: 'firstname',
          },
          {
            name: 'pages.customers.lastName',
            defaultMessage: 'Last Name',
            key: 'lastname',
          },
          {
            name: 'pages.customers.email',
            defaultMessage: 'Email',
            key: 'email',
          },
          {
            name: 'pages.customers.phone',
            defaultMessage: 'Phone',
            key: 'phone',
          },
          {
            name: 'pages.customers.created',
            defaultMessage: 'Created At',
            key: 'created',
          },
        ],
      },
    },

    {
      title: null,
      visible: !nested && !isEmpty(serviceRequest?.Customer),
      jsx: (
        <NestedDrawerButton
          icon={<RightOutlined />}
          block
          onClick={() => showChildrenDrawer(serviceRequest?.Customer)}
        >
          <F id={'pages.customers.details'} />
        </NestedDrawerButton>
      ),
    },
    {
      title: 'pages.serviceRequest.inquiry',
      defaultMessage: 'Inquiry',
      form: {
        data: serviceRequest?.ServiceRequest,
        config: [
          {
            name: 'pages.serviceRequest.what',
            defaultMessage: 'What',
            key: 'what',
          },
          {
            name: 'pages.serviceRequest.when',
            defaultMessage: 'When',
            key: 'when',
          },
          {
            name: 'pages.serviceRequest.where',
            defaultMessage: 'Where',
            key: 'where',
          },
        ],
      },
    },
  ];

  return <div className={styles.drawerContainer}>
    <Drawer title={serviceRequest?.ServiceRequest.what} visible={visible} onVisibleChange={toggle}>
      <ReadonlySections config={readonlySectionsConfig}/>
      {visible && !nested && <CustomerDrawer/>}
    </Drawer>
  </div>
}

export default ServiceRequestDrawer;
