import * as React from 'react'
import {connect} from 'umi'
import {DispatchFunction} from "@/typings/models/ReduxState";

interface JourneySelectLinkProps {
  dispatch: DispatchFunction,
  journeyId: string,
  text?: string
}

const JourneySelectLink: React.FC<JourneySelectLinkProps> = ({journeyId, dispatch}: JourneySelectLinkProps) => {

  function onJourneyClick(){
    dispatch({
      type: 'journey/select',
      payload: {
        journeyId
      }
    })
  }

  return <a onClick={onJourneyClick}>{journeyId}</a>
}

export default connect()(JourneySelectLink)
