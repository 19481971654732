import React, {CSSProperties, ReactElement} from "react";
import {Space} from "antd";
import {useDispatch} from "umi"
import {PromotionModel} from "@/typings/models/Promotion";

export interface PromotionNameProps {
  promotion: PromotionModel,
  style?: CSSProperties,
  fallback?: ReactElement | string,
  drawer?:boolean,
  children?: ReactElement
}

const PromotionName =
  ({
     promotion,
     style = {},
     fallback = " - ",
     children,
     drawer = false
   }: PromotionNameProps) => {


    const dispatch = useDispatch()
    function toggleDrawer() {
      dispatch({
        type: "promotion/toggleDrawer",
        payload: {
          promotion
        }
      })
    }

    if (!children && !location) {
      return <>
        {fallback}
      </>;
    }

    const content = children || <Space size={3}>
      <span>{promotion?.title}</span>
    </Space>


    if (drawer) {
      return <a style={style} onClick={toggleDrawer}>
        {content}
      </a>
    }
    return <div style={style}>
      {content}
    </div>
  };

export default PromotionName
