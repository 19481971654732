import createExpandedRowFromColumns from "@/components/Table/createExpandedRowFromColumns";
import React from 'react';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import MyPageContainer from "@/components/MyPageContainer";
import Table from "@/components/Table";
import type {ProColumns} from "@ant-design/pro-table";
import {DispatchFunction} from "@/typings/models/ReduxState";
import {LocationModel, LocationTableData} from "@/typings/models/Location";
import LocationName from "@/components/Location/LocationName";
import StatusCheck from "@/components/StatusCheck";
import {isVideoCallEnabled} from "@/utils/location";
import DateFormat from "@/components/format/DateFormat";
import {Button, Popconfirm, Space, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";


interface LocationsProps {
  dispatch: DispatchFunction,
  list: LocationTableData,
  loading: boolean
}

const TRACKING_LABEL = "Locations"
const EMPTY_LOCATION = {
  id: 'NEW',
  Availability: [],
  Resources: [],
  location: '',
  address: '',
  created: '',
  email: '',
  video_call: false
}
const Locations: React.FC<LocationsProps> = ({dispatch, list, loading}) => {

  const intl = useIntl();

  function fetchTableData(payload?: any) {
    if (!payload?.search)
      dispatch({
        type: 'location/fetch',
      });
  }

  function removeLocation(locationId: string, resolve: Function) {
    dispatch({
      type: 'location/delete',
      payload: {
        locationId,
        cb: resolve,
        intl,
      },
    });
  }

  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.locations.location" defaultMessage="Location" />,
      dataIndex: 'location',
      render: (_, location: LocationModel) => <LocationName location={location} />,
    },
    {
      title: <F id="pages.locations.address" defaultMessage="Address" />,
      dataIndex: 'address',
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.locations.videoCall" defaultMessage="Video Call" />,
      dataIndex: 'video_call',
      render: (videoCall: any) => <StatusCheck checked={isVideoCallEnabled(videoCall)} badge />,
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.locations.created" defaultMessage="Created At" />,
      dataIndex: 'created',
      responsive: ['xxl', 'xl'],
      render: (date: string) => <DateFormat date={date} />,
    },
    {
      title: <F id="pages.locations.actions" defaultMessage="Actions" />,
      dataIndex: '',
      render: (_, location: LocationModel) => (
        <Space size={20}>
          <Tooltip title={<F id={'pages.common.edit'} defaultMessage={'Edit'} />}>
            <a>
              <LocationName location={location} drawer>
                <EditOutlined />
              </LocationName>
            </a>
          </Tooltip>
          <Popconfirm
            onConfirm={() => new Promise((resolve, reject) => removeLocation(location.id, resolve))}
            placement={'topRight'}
            title={
              <F
                id={'pages.locations.removeLocationConfirm'}
                defaultMessage={'Are you sure to remove this location?'}
              />
            }
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{exportTable: {}, hasRangePicker: false}}>
      <Table headerTitle={intl.formatMessage({
        id: 'pages.locations.tableTitle',
        defaultMessage: 'Locations',
      })} columns={columns}
             pagination={false}
             label={TRACKING_LABEL}
             dataSource={list || []}
             isClientSideSearch={true}
             loading={loading}
             rowKey={"id"}
             request={fetchTableData}
             expandableRow={createExpandedRowFromColumns<LocationModel>(columns)}
             toolBarRender={() => <LocationName style={{color: "inherit"}} drawer location={EMPTY_LOCATION}>
               <Tooltip title={<F id={'pages.locations.newLocation'} defaultMessage={"New location"}/>}>
                 <PlusOutlined/>
               </Tooltip>
             </LocationName>}
      />
    </MyPageContainer>
  );
};

export default connect(({location, loading}) => ({
  list: location.list,
  loading: loading.effects["location/fetch"]
}))(Locations);
