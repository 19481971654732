import {TimePicker} from "antd";
import React from "react";
import styles from './index.less';

type TODOIValue = any

interface MyTimeRangePickerProps {
  onChange: (value: TODOIValue) => void,
  value: TODOIValue
}

const MyTimeRangePicker: React.FC<MyTimeRangePickerProps> = ({onChange, value}: MyTimeRangePickerProps) => {

  return <div className={styles.pickerWrapper}>
    <TimePicker.RangePicker value={value} onChange={onChange} format="HH:mm" showSecond={false}/>
  </div>
}

export default MyTimeRangePicker
