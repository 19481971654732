import storage from 'conversional-persistent-storage'
import omit from "lodash/omit"

const STATE_KEY = "state"
const BLACKLIST = ["@@dva", "router", "loading"]

// Makes sense to persist redux store data in session storage
export function persistGlobalState(state) {
  const copy = Object.create(state)
  for (const [key] of Object.entries(copy)){
     copy[key]['drawer'] = undefined
  }
  storage.setItem(STATE_KEY, JSON.stringify(omit(copy, BLACKLIST)))
}

export function getGlobalInitialState() {
  const state = storage.getItem(STATE_KEY);
  if (!state) {
    return {}
  }
  const parsedState = JSON.parse(state)
  if (parsedState.serviceRequest){
    parsedState.serviceRequest.infiniteList = {};
  }
  return parsedState
}

export function resetPersistentReduxStore() {
  storage.removeItem(STATE_KEY);
}
