import {fetchBillingDetailsRequest, fetchBillingOverviewRequest} from "@/services/api/billing";
import {processAPIResponse} from "@/utils/request";
import {selectDateFilter} from "@/models/date";
import {openCSVLink} from "@/utils/download";
import {selectCurrentJourneyId} from "@/models/journey";
import {fetchCustomersForJourney} from "@/services/api/customer";

export default {
  namespace: 'billing',

  state: {
    overview: [],
    details: {},
  },

  effects: {
    * init({ }, { put }) {
      const actions = ['fetchOverview', 'fetchDetails', 'downloadExport']
      for (const action of actions) {
        yield put({
          type: action
        })
      }
    },
    * fetchOverview({ }, { call, put, select }) {
      const date = yield select(selectDateFilter)
      const { response, data } = yield call(fetchBillingOverviewRequest, { query: { ...date } });
      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveBillingOverview',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchDetails({ payload: { billingId } }, { call, put, select }) {
      const date = yield select(selectDateFilter)

      const { response, data } = yield call(fetchBillingDetailsRequest, {
        params: {
          billingId
        },
        query: { ...date }
      });

      yield processAPIResponse({ response, data }, {
        * onSuccess(data) {
          yield put({
            type: 'saveBillingDetails',
            payload: {
              data,
              billingId
            }
          })
        },
        * onError(error) {

        }
      }, 'data')
    },
    * downloadExport({ payload = {} }: {payload: {url?: string}}, { call, put, select }) {
      const date = yield select(selectDateFilter)
      const query = { ...date, export: true };
      const journeyId = yield select(selectCurrentJourneyId)
      const { response, data } = yield call(fetchCustomersForJourney, { params: { journeyId }, url: payload.url, query });
      yield processAPIResponse({ response, data }, {
        * onSuccess() {
          yield openCSVLink(data)
        },
        * onError(error) {

        }
      }, 'data')
    }
  },
  reducers: {
    saveBillingOverview(state, action) {
      return {
        ...state,
        overview: action.payload.data
      }
    },
    saveBillingDetails(state, action) {
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.billingId]: action.payload.data
        },
      }
    },
  }
};
