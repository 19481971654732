import {changeUserSettingsRequest, fetchUserSettingsRequest} from "@/services/api/user";
import {processAPIResponse} from "@/utils/request";
import {BackofficeUserModel} from "@/typings/api/user";

const initialState = {
  loggedIn: false,
  settings: {}
}

export default {
  namespace: 'user',

  state: initialState,

  effects: {
    * loggedIn({payload = {}}: { payload: BackofficeUserModel }, {put}) {
      const {User, Partner, Journeys, Role} = payload;
      yield put({
        type: 'gotMe',
        // TODO: Check Avatar
        payload: {
          user: {...User, avatar: "https://pbs.twimg.com/profile_images/1362000555820937217/fNKKSMBa_200x200.jpg"},
          partner: Partner,
          role: Role
        }
      })
      yield put({
        type: 'journey/gotJourneys',
        payload: Journeys
      })
      yield put({
        type: 'fetchSettings'
      })
    },
    * fetchSettings({payload = {}}, {call, put}) {
      const {response, data} = yield call(fetchUserSettingsRequest)
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'fetchedSettings',
            payload: data
          })
        },
        * onError() {

        }
      })
    },
    * changeSettings({payload = { }}, {put,call}) {
      yield call(changeUserSettingsRequest, {
        payload: {
          locale: payload["locale"]
        }
      })
      yield put({
        type: 'fetchSettings',
      })
    },
    * resetPassword({payload = {}}, {put}) {
      yield put({
        type: 'resetPasswordResult',
        payload: {}
      })
    },
    * forgetPassword({payload = {}}, {put}) {
      yield put({
        type: 'forgetPasswordResult',
        payload: {}
      })
    }
  },
  reducers: {
    gotMe(state, action) {
      return {
        ...state,
        ...action.payload,
        loggedIn: true
      }
    },
    loggedOut() {
      return initialState
    },
    gotUserData(state) {
      return {
        ...state
      }
    },
    fetchedSettings(state, action) {
      return {
        ...state,
        settings: action.payload
      }
    },
    changedSettings(state) {
      return {
        ...state
      }
    },
    resetPasswordResult(state) {
      return {
        ...state
      }
    },
    forgetPasswordResult(state) {
      return {
        ...state
      }
    },
  },
};
