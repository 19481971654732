import * as React from 'react';
import { ReactElement } from 'react';
import { connect } from 'umi';
import { checkUserAccessToPath } from '@/utils/access';
import ForbiddenPage from '@/pages/403';
import { normalizePath } from '@/utils/pathUtils';

interface AccessControlWrapperProps {
  children?: ReactElement | null;
  route:any
}

const AccessControlWrapper: React.FC<AccessControlWrapperProps> = ({
  children,
  route
}: AccessControlWrapperProps) => {
  const { path } = route;
   if (checkUserAccessToPath(normalizePath(path))) return children;
   return <ForbiddenPage />;
};

export default connect(({ user }) => ({
  user,
}))(AccessControlWrapper);
