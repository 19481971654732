import React, {CSSProperties, useEffect, useState} from "react";
import {connect, useIntl} from "umi";
import {Button, DatePicker, Grid, Popover, Space} from "antd";
import {FieldTimeOutlined} from "@ant-design/icons";
import moment from "moment";
import styles from "./index.less";
import {emptyFunction} from "@/utils/functions";
import {DateRanges, formatDateRanges, getDisabledDate} from "@/utils/date";
import {DispatchFunction} from "@/typings/models/ReduxState";


const {RangePicker} = DatePicker;

export interface MyRangePickerProps {
  date?: any,
  localDate?: any,
  global?: boolean,
  bordered?: boolean,
  miniPlaceHolder?: boolean,
  className?: string,
  onDateChange?: any,
  showSmallFormat?: boolean
  refreshWatchlist?: boolean
  hidden?: boolean,
  dispatch: DispatchFunction,
  disableDate: any
}

const MyRangePicker: React.FC<MyRangePickerProps> =
  ({
     date: {start, end, initialized, quickDiff}, miniPlaceHolder,
     localDate = {}, dispatch, hidden = false, disableDate = {}, bordered = false, global = true, className = "",
     showSmallFormat = false, onDateChange = emptyFunction, ...restProps
   }: MyRangePickerProps) => {

    const {useBreakpoint} = Grid
    const screens = useBreakpoint()

    const [localInitialized, setLocalInitialized] = useState(false);
    useEffect(() => {
      if (!initialized && global) {
        initializeDates()
      }
    });
    useEffect(() => {
      if (!global && localInitialized) {
        if (start !== localDate.start || end !== localDate.end)
          initializeDates()
      }
    }, [start, end]);

    const intl = useIntl();

    function initializeDates() {
      const payload = {};
      let initialStart = null, initialEnd = null;
      if (!start || !end) {
        quickDiff = 30 * 6;
      } else {
        initialStart = moment(start);
        initialEnd = moment(end);
      }
      if (quickDiff) {
        payload.quickDiff = quickDiff;
        const quickDate = DateRanges(intl).filter(range => quickDiff === range.dateDiff)[0];
        initialStart = quickDate.period[0]
        initialEnd = quickDate.period[1]
      }
      setLocalInitialized(true);
      payload.start = initialStart.unix() * 1000;
      payload.end = initialEnd.unix() * 1000;
      if (global && !initialized) {
        dispatch({
          type: 'date/changeDate',
          payload
        });
      } else
        onDateChange(payload)
    }

    function onChange([start, end], dateStrings) {
      const payload = {
        start: start.unix() * 1000, end: end.unix() * 1000
      };
      const endIsToday = end.diff(moment(), 'days') === 0;
      if (endIsToday) {
        const daysDiff = end.diff(start, 'days');
        const quickDate = DateRanges(intl).filter(range => daysDiff === range.dateDiff)[0];
        if (quickDate) {
          payload.quickDiff = daysDiff;
        }
      }
      if (global) {
        dispatch({
          type: 'date/changeDate',
          payload
        });
      } else
        onDateChange(payload)
    }


    let finalStart = start, finalEnd = end;

    if (!global) {
      if (localDate.start) {
        finalStart = localDate.start;
      }
      if (localDate.end) {
        finalEnd = localDate.end;
      }
    }

    let dateFormat = "";
    if (showSmallFormat) {
      dateFormat = moment(finalStart).format("MM/DD") + " - " + moment(finalEnd).format("MM/DD")
    }

    const style: CSSProperties = {};
    if (hidden) {
      style.display = "none";
    }

    const content = <div
      className={`${styles.myRangePicker} ${className} ${miniPlaceHolder ? styles.miniPlaceHolder : ''}`}
      style={style}>
      <Space direction="vertical" size={20}>
        <RangePicker
          bordered={bordered}
          allowClear={false}
          size={"large"}
          value={[moment(finalStart), moment(finalEnd)]}
          disabledDate={current => getDisabledDate(current, disableDate)}
          ranges={formatDateRanges(intl)}
          onChange={onChange}
        />
        {showSmallFormat && <small>
          {dateFormat}
        </small>}
      </Space>
    </div>

    if (screens.md)
      return content

    return <Popover placement={"bottomRight"} content={content}>
      <Button shape={"circle"} size={"large"} icon={<FieldTimeOutlined/>}/>
    </Popover>
  };

export default connect(({date}) => ({date}))(MyRangePicker)
