import React from "react";
import {ImageModel} from "@/typings/models/Image";
import {Popover, Space, Typography} from "antd";
import {useDispatch} from "umi";
import styles from "./index.less"
// @ts-ignore
import * as environmentUtils from 'conversional-utils/environment'

export interface ImageThumbnailProps {
  image: ImageModel,
}

const generateThumbnailImage = (imageUrl: string) => {
  //@ts-ignore
  return environmentUtils.generateMultiResolutionSrc({imageSource:imageUrl, env: BACKOFFICE_ENV, width: 180})
}
const generateLargerImage = (imageUrl: string) => {
  //@ts-ignore
  return environmentUtils.generateMultiResolutionSrc({imageSource:imageUrl, env: BACKOFFICE_ENV, width: 800})
}
const ImageThumbnail =
  ({
     image,
   }: React.PropsWithChildren<ImageThumbnailProps>) => {
    return <Popover
      placement={"bottomRight"}
      content={<img className={styles.realImage}
                    src={generateLargerImage(image.url)}/>}>
      <div className={styles.imageThumbnailContainer}>
        <Space>
          <Space direction={"vertical"}>
            <img className={styles.thumbnailImage} src={generateThumbnailImage(image.url)}/>
            <small style={{fontFamily: "arial"}}>{image.created}</small>
          </Space>
          <Space direction={"vertical"}>
            {image.name}
            {image.description && <small>
              <Typography.Text
                style={{ width: 100 }}
                ellipsis={{ tooltip: image.description }}
              >
                {image.description}
              </Typography.Text>
            </small>}
          </Space>
        </Space>
      </div>
    </Popover>
  };

export default ImageThumbnail
