import {selectCurrentJourneyId} from "@/models/journey";
import {
  fetchAnalyticsFunnelDataRequest,
  fetchCTAChartRequest,
  fetchCTATableRequest,
  fetchRegistrationStatsRequest,
  fetchResultStatsRequest,
  fetchScoresRequest,
  fetchServiceRequestStats,
  fetchStylesStatsRequest,
  fetchSubscribersStatsRequest
} from "@/services/api/analytics";
import {processAPIResponse} from "@/utils/request";
import {selectCurrentPeriod} from "@/models/periodSelector";
import {selectDateFilter} from "@/models/date";
import get from "lodash/get";

export default {
  namespace: 'analytics',

  state: {
    funnel: {},
    styles: [],
    subscriber: [],
    registration: [],
    results: [],
    scores: {},
    ctaChart: [],
  },

  effects: {
    * init({}, {put}) {
      const actions = ['fetchFunnel', 'fetchSubscriberStats', 'fetchStylesStats', 'fetchResultStats', 'fetchScores', 'fetchRegistrationStats']
      for (const action of actions) {
        yield put({
          type: action
        })
      }
    },
    * fetchFunnel({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchAnalyticsFunnelDataRequest, {params: {journeyId}, query: {...date}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveFunnelChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchScores({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchScoresRequest, {params: {journeyId}, query: {...date}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveScores',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchSubscriberStats({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const interval = yield select(selectCurrentPeriod)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchSubscribersStatsRequest, {
        params: {journeyId},
        query: {interval, ...date}
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveSubscriberStatsChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchServiceRequestStats({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const interval = yield select(selectCurrentPeriod)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchServiceRequestStats, {
        params: {journeyId},
        query: {interval, ...date}
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveServiceRequestStatsChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchRegistrationStats({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const interval = yield select(selectCurrentPeriod)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchRegistrationStatsRequest, {
        params: {journeyId},
        query: {interval, ...date}
      });
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveRegistrationsStatsChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchStylesStats({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchStylesStatsRequest, {params: {journeyId}, query: {...date}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveStylesStatsChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchResultStats({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchResultStatsRequest, {params: {journeyId}, query: {...date}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveResultStatsChartData',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchCTACharts({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const date = yield select(selectDateFilter)
      const {response, data} = yield call(fetchCTAChartRequest, {params: {journeyId}, query: {...date}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveCTAChart',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * fetchCTATable({payload}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const date = yield select(selectDateFilter)
      const page = get(payload, 'page', 0) + 1
      const per_page = get(payload, 'perPage', 10)
      const search = get(payload, 'search')
      const sort = get(payload, 'sort')
      const used_action = get(payload, 'used_action')
      const {response, data} = yield call(fetchCTATableRequest, {params: {journeyId}, query: {...date,
          page, per_page, search,
          sort, used_action
        }});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'saveCTATable',
            payload: data
          })
        },
        * onError(error) {

        }
      })
    },
  },
  reducers: {
    saveFunnelChartData(state, action) {
      return saveChartData(state, 'funnel', action.payload)
    },
    saveServiceRequestStatsChartData(state, action) {
      return saveChartData(state, 'serviceRequest', action.payload)
    },
    saveSubscriberStatsChartData(state, action) {
      return saveChartData(state, 'subscriber', action.payload)
    },
    saveRegistrationsStatsChartData(state, action) {
      return saveChartData(state, 'registration', action.payload)
    },
    saveStylesStatsChartData(state, action) {
      return saveChartData(state, 'styles', action.payload)
    },
    saveResultStatsChartData(state, action) {
      return saveChartData(state, 'results', action.payload)
    },
    saveCTAChart(state, action) {
      return saveChartData(state, 'ctaChart', action.payload)
    },
    saveCTATable(state, action) {
      return saveChartData(state, 'ctaTable', action.payload)
    },
    saveScores(state, action) {
      return {
        ...state,
        scores: action.payload
      }
    },
  }
};


function saveChartData(state: any, key: string, payload: any) {
  return {
    ...state,
    [key]: payload.data
  }
}
