import styles from './index.less';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, FormattedMessage as F } from 'umi';
import Drawer from '@/components/Drawer';
import CustomerName from '@/components/Customer/CustomerName';
import { Button, Spin, Typography } from 'antd';
import ReadonlySections from '@/components/ReadonlySections';
import DrawerSection from '@/components/Drawer/DrawerSection';
import CustomerQualificationForm from '@/components/CustomerDrawer/CustomerQualificationForm';
import { RightOutlined } from '@ant-design/icons';
import type {
  RecentServiceRequestItem,
  ServiceRequestModel,
} from '@/typings/models/ServiceRequest';
import ServiceRequestDrawer from '@/components/ServiceRequestDrawer';
import NestedDrawerButton from '@/components/buttons/NestedDrawerButton';
import get from "lodash/get";
import CustomerFiles from './CustomerFiles';

const CustomerDrawer: React.FC = () => {
  const DEFAULT_SERVICE_REQUEST_DRAWER_LABEL = (
    <F id={'component.serviceRequest.drawerDefaultLabel'} />
  );

  const {
    visible,
    customer,
    nested,
    unSubscribeLoading,
    customerDetails = {},
    loading,
  } = useSelector(({ customers: { drawer, details }, loading }: DefaultRootState) => ({
    ...drawer,
    loading: loading.effects['customers/fetchDetails'],
    unSubscribeLoading: loading.effects['customers/unSubscribe'],
    customerDetails: details,
  }));

  const dispatch = useDispatch();

  function toggle() {
    dispatch({
      type: 'customers/toggleDrawer',
      payload: {
        customer: null,
      },
    });
  }

  function getCustomerId() {
    const link = customer?.link || customer?.links?.href;
    return link?.split('customers/')[1];
  }

  useEffect(() => {
    if (!customer) {
      return;
    }
    const customerId = getCustomerId();
    if (visible) {
      dispatch({
        type: 'customers/fetchDetails',
        payload: {
          customerId,
        },
      });

      dispatch({
        type: 'customers/fetchStates',
        payload: {
          customerId,
        },
      });
    }
  }, [customer, visible]);
  const customerId = getCustomerId();
  const details = customerId ? customerDetails[customerId] : {};

  function getUnsubscribeAction() {
    if (
      details &&
      details.Profile &&
      (details.Profile?.subscriber === undefined || details.Profile?.subscriber === true)
    )
      return (
        <Button
          loading={unSubscribeLoading}
          type={'link'}
          className="color-secondary"
          onClick={unsubscribeCustomer}
        >
          <F id={'pages.customer.unsubscribe'} defaultMessage={'Unsubscribe'} />
        </Button>
      );
    return null;
  }

  function unsubscribeCustomer() {
    dispatch({
      type: 'customers/unSubscribe',
      payload: {
        customerId,
      },
    });
  }
  function getServiceRequestDrawerLabel(serviceRequest: ServiceRequestModel) {
    const what = get(serviceRequest, 'what')
    const when = get(serviceRequest, 'when')
    return (what && when) ? `${what} - ${when}` : DEFAULT_SERVICE_REQUEST_DRAWER_LABEL;
  }
  const readonlySectionsConfig = details
    ? [
        {
          title: 'pages.customers.profile',
          defaultMessage: 'Profile',
          form: {
            data: details.Profile,
            config: [
              {
                name: 'pages.customers.firstName',
                defaultMessage: 'First Name',
                key: 'firstname',
              },
              {
                name: 'pages.customers.lastName',
                defaultMessage: 'Last Name',
                key: 'lastname',
              },
              {
                name: 'pages.customers.email',
                defaultMessage: 'Email',
                key: 'email',
              },
              {
                name: 'pages.customers.phone',
                defaultMessage: 'Phone',
                key: 'phone',
              },
              {
                name: 'pages.customers.created',
                defaultMessage: 'Created At',
                key: 'created',
              },
            ],
          },
        },
        {
          title: 'component.serviceRequest.title',
          visible: (details.ServiceRequests || []).length > 0 && !nested,
          jsx: <>{details.ServiceRequests?.map(ServiceRequestItem)}</>,
        },
        {
          title: 'pages.customers.styles',
          defaultMessage: 'Styles',
          form: {
            data: details.Styles,
            config: Object.keys(details.Styles || {}).map((key) => ({
              defaultMessage: null,
              key,
              name: key,
            })),
          },
        }
      ]
    : [];

  function ServiceRequestItem(serviceRequest) {
    return (
      <NestedDrawerButton
        icon={<RightOutlined />}
        block
        onClick={() => showChildrenDrawer(serviceRequest)}
      >
        {getServiceRequestDrawerLabel(serviceRequest)}
      </NestedDrawerButton>
    );
  }

  const showChildrenDrawer = (request: RecentServiceRequestItem) => {
    let extraServiceRequestObject = {};
    if (nested) {
      toggle();
      extraServiceRequestObject = {
        Customer: customer,
      };
    }
    dispatch({
      type: 'serviceRequest/toggleDrawer',
      payload: {
        serviceRequest: {
          ServiceRequest: request,
          ...extraServiceRequestObject,
        },
        nested: !nested,
      },
    });
  };
  return (
    <div className={styles.drawerContainer}>
      <Drawer
        title={<CustomerName customer={details && details.Profile} drawer={false} />}
        visible={visible}
        onVisibleChange={toggle}
      >
        <Spin spinning={loading}>
          {details && (
            <div>
              <DrawerSection
                action={getUnsubscribeAction()}
                title={<F id={'pages.customer.qualification'} defaultMessage={'Qualification'} />}
              >
                <CustomerQualificationForm customerId={customerId} customer={details.Profile} />
              </DrawerSection>
              {visible && !nested && <ServiceRequestDrawer />}
              <ReadonlySections config={readonlySectionsConfig} />
              {customer?.links?.view && (
                <DrawerSection
                  title={<F id={'pages.customer.results'} defaultMessage={'Results'} />}
                >
                  <Typography.Text copyable={{ text: customer?.links.view }}>
                    <a target={'_blank'} className={'color-secondary'} href={customer?.links.view}>
                      {customer?.links.view}
                    </a>
                  </Typography.Text>
                </DrawerSection>
              )}
              {Array.isArray(details?.UploadedFiles) && (
                <DrawerSection
                  title={
                    <F id={'pages.customer.uploadedFiles'} defaultMessage={'Uploaded Files'} />
                  }
                >
                  {details?.UploadedFiles && (
                    <CustomerFiles uploadedFiles={details?.UploadedFiles} />
                  )}
                </DrawerSection>
              )}
            </div>
          )}
        </Spin>
      </Drawer>
    </div>
  );
};

export default CustomerDrawer;
