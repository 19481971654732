import localStorageFallback from 'local-storage-fallback'
import unset from 'lodash/unset'
import get from 'lodash/get'
import set from 'lodash/set'
import entries from 'lodash/entries'

import blackList from './blackList'
import type {State} from 'conversional-journey'
import {JourneyConfig} from 'conversional-types'

const STORAGE_KEY = 'conversional-state'
export const STORAGE_EXPIRATION_IN_MILLIS = 360000
export const STORAGE_CREATED_AT_NAME = 'createdAt'

let questionnaireId
export const setKey = key => {
    questionnaireId = key
}

interface IOptions {
    prefixed?: boolean;
    noAppendix?: boolean;
    ttl?: number;
}

export const ENGINES = {
    SESSION_STORAGE: "sessionStorage",
    LOCAL_STORAGE: "localStorage",
}
const DEFAULT_ENGINE = ENGINES.SESSION_STORAGE

// The keys we are using for storing the data for the application
const COMMONLY_USED_STORAGE_KEYS = ['origin', 'cSessionId', 'lastCta', 'abTest']

const CONSENT_DISABLED_KEY = 'conversional-storage-consent-disabled'
/**
 * Setting default value to window.sessionStorage causes an error so by default it is localstorage and
 * if we ensure that session storage is available, we will set sessionstorage
 * */
const engines = {
    [ENGINES.LOCAL_STORAGE]: localStorageFallback,
}
try {
    engines[ENGINES.SESSION_STORAGE] = window.sessionStorage
} catch (e) {
    engines[ENGINES.SESSION_STORAGE] = localStorageFallback
}

const getEngine = (engine: string) => {
    return engines[engine] || engines[DEFAULT_ENGINE]
}

export const wipe = () => {
    const keys = [...COMMONLY_USED_STORAGE_KEYS]
    try {
        keys.push(...Object.keys(localStorageFallback).filter(key => key.includes('conversional')))
    } catch (e) {
        // Handle exception for cases we can't access local storage
    }
    try {
        keys.push(...Object.keys(sessionStorage).filter(key => key.includes('conversional')))
    } catch (e) {
        // Handle exception for cases we can't access session storage
    }
    for (const engineKey of Object.keys(ENGINES)) {
        const engine = getEngine(ENGINES[engineKey])
        for (const key of keys) {
            try {
                engine.removeItem(key)
            } catch (e) {
                // Handle exception for cases we can't access engine
            }
        }
    }

}

export const disableStorage = () => {
    wipe()
    setItem(CONSENT_DISABLED_KEY, "1", {}, ENGINES.LOCAL_STORAGE)
}
export const isStorageEnabled = () => !getItem(CONSENT_DISABLED_KEY, {}, ENGINES.LOCAL_STORAGE)
export const enableStorage = () => removeItem(CONSENT_DISABLED_KEY, {}, ENGINES.LOCAL_STORAGE)

export const getItem = function (key: string, options: IOptions = {
    prefixed: false,
    noAppendix: false,
    ttl: 0
}, engine = DEFAULT_ENGINE): string {

    const storage = getEngine(engine)
    const saveKey = namespaceKey(key, {
        prefixed: options.prefixed
    })

    const et = storage.getItem(saveKey + '-et')
    // Last condition is to clear out previously incorrect settings of the timestamp which was containing more digits and lead to an invalid date
    if (et && new Date().getTime() >= parseInt(et)) {
        storage.removeItem(saveKey)
        storage.removeItem(saveKey + '-et')
        return null
    }
    return storage.getItem(saveKey)
}

export const setItem = function (key: string, value: string, options: IOptions = {}, engine = DEFAULT_ENGINE): string {
    if (!isStorageEnabled()) return

    const storage = getEngine(engine)
    const saveKey = namespaceKey(key, options)
    storage.setItem(saveKey, value)


    if (options.ttl) {
        const expiry = `${new Date().getTime() + Number(options.ttl)}`
        setItem(saveKey + '-et', expiry, {noAppendix: true, prefixed: false}, engine)
    }
    return saveKey
}

export const removeItem = function (key: string, options: IOptions = {}, engine = DEFAULT_ENGINE): string {
    const saveKey = namespaceKey(key, {
        prefixed: options.prefixed
    })

    const storage = getEngine(engine)

    storage.removeItem(saveKey)
    return saveKey
}

const namespaceKey = function (key: string, options: IOptions = {}): string {
    const appendix = questionnaireId || ''
    return (options.prefixed ? `${STORAGE_KEY}-` : '') + ((!options.noAppendix && appendix) ? `${key}-${appendix}` : key)
}

export const loadState = (getCurrentJourneys?: () => Array<JourneyConfig>, captureException?: Function): State | undefined => {
    try {
        const serializedState = getItem(STORAGE_KEY)
        if (serializedState === null) {
            return undefined
        }

        const state = JSON.parse(serializedState)
        const validState = removeExpiredAttributes(state, getCurrentJourneys)

        return validState
    } catch (err) {
        captureException && captureException(err, {place: "LOAD_STATE_FROM_STORAGE"})
        return undefined
    }
}

export const saveState = (state: State): void => {
    const stateToPersist = filter(state)
    const stateWithExpiryTime = setTimestamp(stateToPersist)
    try {
        const serializedState = JSON.stringify(stateWithExpiryTime)
        setItem(STORAGE_KEY, serializedState)
    } catch (err) {
        // Ignore write errors
    }
}

export const filter = (state: State): State => {
    const filteredState = JSON.parse(JSON.stringify(state))

    for (const prop of blackList) {
        unset(filteredState, prop)
    }

    return filteredState
}

type ICallbackRemoveExpired = (state: State) => JourneyConfig[]
export const removeExpiredAttributes = (state: State, getCurrentJourneys: ICallbackRemoveExpired): State => {
    // Remove results if tokens don't match
    const tokens = entries(get(state, 'config.tokens', {}))
    tokens.forEach(([journeyId, token]) => {
        if (get(state, `result.${journeyId}.config.alias`) !== token) {
            unset(state, `result.${journeyId}`)
        }
    })

    // If state is not expired, return it as it is
    if (
        !state.journey ||
        !state.journey[STORAGE_CREATED_AT_NAME] ||
        !isExpired(state.journey[STORAGE_CREATED_AT_NAME])
    ) {
        return state
    }

    // Collect the values to keep
    // TODO: UPdate moving from questionnaires to processes!
    const newState = {}
    const journeys: Array<JourneyConfig> = getCurrentJourneys(state)
    entries(journeys).forEach(([journeyId, journey]) => {
        if (journey.hideExitIntent) {
            set(newState, `journey.${journeyId}.hideExitIntent`, journey.hideExitIntent)
        }
    })
// @ts-ignore
    return newState // TODO: The current extraction is not aligned with the expected type
}

export const isExpired = (timestamp: number): boolean => {
    return new Date().getTime() - timestamp > STORAGE_EXPIRATION_IN_MILLIS
}

export const setTimestamp = (state: State): State => {
    if (state.journey && !state.journey[STORAGE_CREATED_AT_NAME]) {
        return {
            ...state,
            journey: {
                ...state.journey,
                [STORAGE_CREATED_AT_NAME]: new Date().getTime()
            }
        }
    }
    return state
}

const store = {
    getItem,
    removeItem,
    setItem,
    wipe,
    disableStorage,
    enableStorage,
    isStorageEnabled
}

export default store
