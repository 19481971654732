import React from "react";
import Card from "@/components/Card";
import AreaChart from "@/components/charts/Area/AreaChart";

export type RingProps = {
  cardTitle: String,
  helpTooltip?: String,
  // chartData: StylesStatsModel['values']
}
const Area : React.FC<RingProps> = ({cardTitle, helpTooltip}) => {
  return <Card title={cardTitle}>
    <AreaChart />
  </Card>
}

export default Area
