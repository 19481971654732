import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Select, Space, Spin } from 'antd';

import { createTagGroupRequest } from '@/services/api/image';
import { connect, FormattedMessage as F } from 'umi';
import type { InputRef } from 'antd';

const NEW_TAG_ID = 'NEW';

export interface ChooseTagGroupProps {
  tagGroupList: [];
  selectedJourney: { hashId: string };
  dispatch;
  tag;
  submitText?: string;
  loading: boolean;
  onConfirm: (tagGroupId: string) => void;
}

const COLORS = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];
const ChooseTagGroup = ({
  tagGroupList,
  selectedJourney,
  onConfirm,
  dispatch,
  tag,
  submitText,
  loading,
}: React.PropsWithChildren<ChooseTagGroupProps>) => {
  const [createNewTagGroupLoading, setCreateNewTagGroupLoading] = useState(false);
  const [selectedTagGroup, setSelectedTagGroup] = useState(undefined);
  const [newTagGroupTitle, setNewTagGroupTitle] = useState(null);
  const [newTagGroupDisplayColor, setNewTagGroupDisplayColor] = useState(undefined);
  const [createNewTagGroupVisible, setCreateNewTagGroupVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const buttonRef = useRef();
  const inputRef = useRef<InputRef>(null);
  const newTagGroupColorRef = useRef<InputRef>(null);
  const [selectKey, setSelectKey] = useState(Math.random());

  const resetState = () => {
    setNewTagGroupDisplayColor(undefined);
    setSelectedTagGroup(undefined);
    setNewTagGroupTitle(null)
    setCreateNewTagGroupLoading(false);
    setCreateNewTagGroupVisible(false)
    setInputValue(null)
    newTagGroupColorRef.current = null
    inputRef.current = null
    setSelectKey(Math.random());
  }

  useEffect(() => {
    resetState()
  }, []);

  useEffect(() => {
    if (tag && tag.taggroup) {
      setSelectedTagGroup(tag.taggroup.id);
    }
  }, [JSON.stringify(tag)]);

  useEffect(() => {
    buttonRef.current.focus();
  }, [selectedTagGroup]);

  useEffect(() => {
    if (createNewTagGroupVisible) newTagGroupColorRef.current.focus();
  }, [createNewTagGroupVisible]);

  useEffect(() => {
    if (newTagGroupDisplayColor) handleCreateNewTagGroup();
  }, [newTagGroupDisplayColor]);

  const handleCreateNewTagGroup = () => {
    setCreateNewTagGroupLoading(true);
    createTagGroupRequest({
      params: {
        journeyId: selectedJourney.hashId,
      },
      payload: {
        title: newTagGroupTitle,
        display_color: newTagGroupDisplayColor,
      },
    })
      .then((response) => {
        dispatch({
          type: 'images/fetchTagGroups',
        });
        setSelectedTagGroup(response.data.taggroup.id);
        onConfirm(response.data.taggroup.id);
      })
      .finally(() => {
        resetState()

      });
  };

  const getOptions = () => {
    if (inputValue)
      return [
        {
          title: (
            <Space>
              <PlusOutlined /> <strong>{inputValue}</strong>
            </Space>
          ),
          isAdd: true,
          id: NEW_TAG_ID,
        },
        ...tagGroupList,
      ];

    return tagGroupList;
  };

  const handleTagGroupSelect = (tagGroupId) => {
    setSelectedTagGroup(tagGroupId);
    if (tagGroupId === NEW_TAG_ID) {
      setCreateNewTagGroupVisible(true);
      setNewTagGroupTitle(inputValue);

      return;
    }
    onConfirm(tagGroupId);
    resetState();
  };
  return (
    <div style={{ maxWidth: 400 }}>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Select
          key={selectKey}

          style={{ width: '100%' }}
          showSearch
          onSearch={setInputValue}
          filterOption={(input, option) => {
            if (option.isAdd) return true;
            return (option?.title ?? '').toLowerCase().includes(input.toLowerCase());
          }}
          ref={inputRef}
          value={selectedTagGroup}
          onChange={handleTagGroupSelect}
          fieldNames={{
            label: 'title',
            options: 'tags',
            value: 'id',
          }}
          options={getOptions()}
        />
        {createNewTagGroupVisible && (
          <Spin spinning={createNewTagGroupLoading}>
            <Select
              ref={newTagGroupColorRef}
              showAction={['focus']}
              style={{ width: '100%' }}
              autoFocus={true}
              value={newTagGroupDisplayColor}
              onChange={setNewTagGroupDisplayColor}
            >
              {COLORS.map((color) => (
                <Select.Option key={color} value={color}>
                  <Badge color={color} text={color} />
                </Select.Option>
              ))}
            </Select>
          </Spin>
        )}

        <Button
          loading={loading}
          ref={buttonRef}
          disabled={!selectedTagGroup || selectedTagGroup === NEW_TAG_ID}
          block
          style={{ position: 'fixed', top: -2000 }}
          onClick={() => {onConfirm(selectedTagGroup)
          resetState();
          }}
        >
          {submitText || <F id={'pages.images.createTag'} />}
        </Button>
      </Space>
    </div>
  );
};

export default connect(({ images, loading, journey }) => ({
  selectedJourney: journey.selectedJourney,
  tagList: images.tags,
  tagGroupList: images.tagGroups,
}))(ChooseTagGroup);
