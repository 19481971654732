import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {Button, Input, message, Space, Tag, Tooltip} from 'antd';
// Commented out because it was not working with Node 16.16.0 but we can bring it back later
// import {TweenOneGroup} from 'rc-tween-one';
import {ClearOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import ProForm from "@ant-design/pro-form";
import {FormattedMessage} from "umi";
import isEmpty from "lodash/isEmpty";
import {F} from '@/utils/intl';
import get from "lodash/get";

const VALIDATION_TYPES = {
  zip: /^[\d\-*]+$/i,
  text: null
}
const MultipleTagsFormItem = ({
                                onChange,
                                label,
                                addInputStyles = {},
                                tooltip,
                                type = "zip",
                                value = [],
                                field,
                                disableClear
                              }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag) => onChange(value.filter((tag) => tag !== removedTag));

  const showInput = () => setInputVisible(true);


  const isValid = (input = inputValue) => {
    const regex = get(VALIDATION_TYPES, type)
    if (!regex) return true

    !input || regex.test(input)
  }
  const handleInputChange = (e) => {
    if (e.target.value.includes(",") && type === "zip") {
      const tags = e.target.value.split(",")
        .map(tag => tag.trim().replaceAll(" ", ""))
        // Check for validity and not repeated in the current value
        .filter(tag => !isEmpty(tag) && isValid(tag) && value.indexOf(tag) === -1)
      return handleCommaSeparatedTags(tags)
    }
    setInputValue(e.target.value)
  };


  const handleCommaSeparatedTags = (tags) => {
    setInputValue('');
    onChange([...value, ...tags]);
  }

  const handleInputConfirm = (e: SyntheticEvent) => {
    if (!isValid()) {
      message.error("Invalid value")
      return
    }
    if (inputValue && value.indexOf(inputValue) === -1) {
      onChange([...value, inputValue]);
    }
    e?.type === "blur" && setInputVisible(false);
    setInputValue('');
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
          margin: "2px 0"
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = value.map(forMap);
  const tagPlusStyle = {
    borderStyle: 'dashed',
  };

  return (
    <>
      <div>
        {(label || tooltip || value.length > 0) ? <Space>
          {label ? <div>{label}</div> : null}
          {tooltip ? (
            <Tooltip title={tooltip}>
              <QuestionCircleOutlined/>
            </Tooltip>
          ) : null}
          <div>
            {value.length > 0 && (
              <Tooltip title={<F id={'pages.common.clearAll'} defaultMessage={'Clear All'}/>}>
                <Button
                  disabled={disableClear}
                  size={'small'}
                  danger
                  onClick={() => onChange([])}
                  type={'link'}
                  icon={<ClearOutlined/>}
                />
              </Tooltip>
            )}
          </div>
        </Space> : null}
      </div>
      {tagChild ? <div
        style={{
          marginBottom: 8,
        }}
      >
        {/*Can bring the animation back when we update node*/}
        {/*<TweenOneGroup*/}
        {/*  enter={{*/}
        {/*    scale: 0.8,*/}
        {/*    opacity: 0,*/}
        {/*    type: 'from',*/}
        {/*    duration: 100,*/}
        {/*  }}*/}
        {/*  onEnd={(e) => {*/}
        {/*    if (e.type === 'appear' || e.type === 'enter') {*/}
        {/*      e.target.style = 'display: inline-block';*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  leave={{*/}
        {/*    opacity: 0,*/}
        {/*    width: 0,*/}
        {/*    scale: 0,*/}
        {/*    duration: 200,*/}
        {/*  }}*/}
        {/*  appear={false}*/}
        {/*>*/}
        {tagChild}
        {/*</TweenOneGroup>*/}
      </div> : null}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 78,
            ...(addInputStyles || {})
          }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag onClick={showInput} style={tagPlusStyle}>
          <Space>
            <PlusOutlined/>
            <FormattedMessage id="pages.common.add" defaultMessage="New"/>
          </Space>
        </Tag>
      )}
    </>
  );
};


const ProFormMultipleTagsFormItem = ({label, name, field, tooltip, disabled, type, addInputStyles}) => {
  return <ProForm.Item name={name}>
    <MultipleTagsFormItem type={type} field={field} label={label}
                          name={name} tooltip={tooltip}
                          addInputStyles={addInputStyles}
                          disableClear={disabled}/>
  </ProForm.Item>


}
export default ProFormMultipleTagsFormItem;


