import React, {useEffect, useState} from "react";
import ProForm, {ProFormSelect, ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import { Form, InputNumber, Row, Space, Tooltip, Typography } from 'antd';
import {connect, useIntl, useSelector} from "umi";
import {InfoCircleOutlined} from "@ant-design/icons";

import FORM_TYPES from "@/constants/formTypes"
import {getFormBlurProps} from "@/utils/form";
import {DispatchFunction} from "@/typings/models/ReduxState";
import {CustomerModel, CustomerStateModel} from "@/typings/models/Customer";
import {EngagementScore} from "@/pages/Customers/EngagementScore";
import { DEFAULT_CURRENCY_UNIT } from "@/constants/currencyUnit";


interface CustomerQualificationFormProps {
  customer: CustomerModel,
  customerId?: string,
  setPrevious?: boolean,
  onChange?: (name: string, value: string) => void,
  loadings: {
    [key: string]: boolean
  },
  states: CustomerStateModel[],
  dispatch: DispatchFunction
}

const CustomerQualificationForm: React.FC<CustomerQualificationFormProps> = ({customer, setPrevious = true, onChange, customerId, states, dispatch, loadings}) => {

  const [form] = Form.useForm()
  const [previousCustomer, setPreviousCustomer] = useState<CustomerModel>({})
  const intl = useIntl();
  const selectedJourneyConfig = useSelector(
    (state: DefaultRootState) => state.journey?.config?.[state.journey.selectedJourney?.hashId],
  );
  const currencyUnit = selectedJourneyConfig?.locale?.currency_unit || DEFAULT_CURRENCY_UNIT;


  useEffect(() => {
    form.setFieldsValue(customer)
    if (setPrevious)
      setPreviousCustomer(customer)
  }, [customer])

  function onValueChange(name: string, value: string) {
    onChange?.(name, value);
    if (previousCustomer[name] !== value) {
      dispatch({
        type: 'customers/change',
        payload: {
          customerId,
          [name]: value,
        },
      });
    }
  }
  const valueEnum = {};
  states.forEach((state) => (valueEnum[state.hash] = state.label));
  return (
    <div>
      <ProForm form={form} submitter={false} initialValues={customer}>
        <ProFormSelect
          label={intl.formatMessage({
            id: 'pages.customer.state',
            defaultMessage: 'State',
          })}
          placeholder={intl.formatMessage({
            id: 'pages.customer.state',
            defaultMessage: 'State',
          })}
          valueEnum={valueEnum}
          name="state"
          fieldProps={{
            onChange: (value) => {
              onValueChange('state_hash', value);
            },
          }}
          disabled={loadings['state']}
        />

        <div className="ant-form-item">
          <Typography.Text className="ant-col ant-form-item-label" level={3}>
            {intl.formatMessage({
              id: 'pages.customer.orderVolume',
              defaultMessage: 'Order Volume',
            })}
          </Typography.Text>
          <InputNumber
            style={{ width: '100%' }}
            addonAfter={currencyUnit}
            defaultValue={customer?.order_volume}
            onChange={(value) => {
              onValueChange('order_volume', value?.toString());
            }}
          />
        </div>

        <div>
          <Tooltip
            title={intl.formatMessage({
              id: 'pages.customers.scoreExplanation',
              defaultMessage:
                'Estimated probability this customer will buy in your store based on all previous interactions.',
            })}
            className="ant-col ant-form-item-label"
          >
            <Typography.Text level={3}>
              {intl.formatMessage({
                id: 'pages.customer.engagementScore',
                defaultMessage: 'Engagement Score',
              })}
            </Typography.Text>
            <InfoCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
          <div className="ant-form-item">
            {' '}
            {customer?.engagement_score && <EngagementScore score={customer.engagement_score} />}
          </div>
        </div>
        <div>
          <div className="ant-form-item">
            {' '}
            <ProFormText
              disabled
              name="lead_medium"
              placeholder={''}
              key="lead_medium"
              label={intl.formatMessage({
                id: 'pages.customers.leadMedium',
                defaultMessage: 'Lead Medium',
              })}
            />
          </div>
        </div>

        <ProFormTextArea
          label={intl.formatMessage({
            id: 'pages.customer.notes',
            defaultMessage: 'Notes',
          })}
          {...getFormBlurProps({
            type: FORM_TYPES.TEXT,
            callback: onValueChange,
            name: 'notes',
            loadings,
          })}
        />
      </ProForm>
    </div>
  );
}

export default connect(({customers: {changeLoadings, states}}: DefaultRootState) => ({
  loadings: changeLoadings,
  states
}))(CustomerQualificationForm)
