import React from 'react';
import {PageContainer} from '@ant-design/pro-layout';
import {connect, useIntl} from 'umi';
import {DispatchFunction} from "@/typings/models/ReduxState";
import AccountMenuTabs from "@/components/Account/AccountMenuTabs";
import Card from "@/components/Card";
import styles from "./index.less"
import {Avatar} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import UserProfileForm from "@/pages/Profile/UserProfileForm";
import {useModel} from "@@/plugin-model/useModel";

interface ProfilePageProps {
  dispatch: DispatchFunction
}

const ProfilePage: React.FC<ProfilePageProps> = ({dispatch}: ProfilePageProps) => {
  const intl = useIntl();
  const {initialState} = useModel('@@initialState');
  function getUserName(){
    const {currentUser: {User}} = initialState
    return User.firstname + " " + User.lastname
  }
  function getAvatar(){
    const {currentUser: {User}} = initialState
    try{
      return User.firstname[0] + User.lastname[0]
    }catch(e){
      return "U"
    }
  }
  return (
    <PageContainer>
      <AccountMenuTabs/>
      <Card className={styles.card}>
        <div className={styles.inner}>
          <div className={styles.avatarContainer}>
            <div className={styles.avatar}>
              <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} size={80}>{getAvatar()}</Avatar>
              <span className={styles.edit}><EditOutlined/></span>
            </div>
            <div className={styles.name}>
              {getUserName()}
            </div>
          </div>
          <div className={styles.formContainer}>
            <UserProfileForm/>
          </div>
        </div>
      </Card>
    </PageContainer>
  );
};

export default connect()(ProfilePage);
