import React from "react"
import styles from "./index.less"
import {useIntl} from "umi"
import {ReadonlySectionsProps} from "@/typings/models/ReadonlyForm";
import DrawerSection from "@/components/Drawer/DrawerSection";
import ReadonlyForm from "@/components/ReadonlyForm";


const ReadonlySections: React.FC<ReadonlySectionsProps> = ({config}: ReadonlySectionsProps) => {
  const intl = useIntl();

  return (
    <div className={styles.readonlySections}>
      {config
        .filter((section) => {
          return section.visible === true || section.visible === undefined;
        })
        .map((readonlySection) =>
          readonlySection.notSection ? (
            readonlySection.jsx
          ) : (
            <div key={readonlySection.title}>
              <DrawerSection
                sectionStyles={readonlySection.sectionStyles}
                title={
                  readonlySection.title &&
                  intl.formatMessage({
                    id: readonlySection.title,
                    defaultMessage: readonlySection.defaultMessage,
                  })
                }
              >
                {readonlySection.jsx ? (
                  readonlySection.jsx
                ) : (
                  <ReadonlyForm {...readonlySection.form} />
                )}
              </DrawerSection>
            </div>
          ),
        )}
    </div>
  );
}

export default ReadonlySections
