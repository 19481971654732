import dev from "./dev"
import production from "./production"
import testing from "./testing"
import staging from "./staging"
import devTesting from "./devTesting"

function getEnvironment(){

  // @ts-ignore
  switch (BACKOFFICE_ENV){
    case "dev": return dev;
    case "staging": return staging;
    case "production": return production;
    case "testing": return testing;
    case "devTesting": return devTesting;
  }
    return production
}

export default getEnvironment()
