import React, { useEffect, useState } from 'react';
import { connect, FormattedMessage as F, useIntl, useSelector } from 'umi';
import { Alert, Divider, Popconfirm, Space, Tooltip, Typography,Modal } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, WarningOutlined, YoutubeOutlined } from '@ant-design/icons';

import MyPageContainer from '@/components/MyPageContainer';
import Table from '@/components/Table';
import type { ProColumns } from '@ant-design/pro-table';
import { DispatchFunction } from '@/typings/models/ReduxState';
import { LocationModel } from '@/typings/models/Location';
import createExpandedRowFromColumns from '@/components/Table/createExpandedRowFromColumns';
import StatusCheck from '@/components/StatusCheck';
import PromotionName from '@/components/Promotion/PromotionName';
import { PromotionModel } from '@/typings/models/Promotion';
import styles from './index.less';
import YouTubeVideoWithCaptions from './YouTubeVideoWithCaptions';
import { getLanguageFromUmiLocale } from '@/utils/locale';
import { fetchCaptions } from './util';

interface PromotionProps {
  dispatch: DispatchFunction;
  list: PromotionModel[];
  loading: boolean;
}

const TRACKING_LABEL = 'Promotions';
const EMPTY_PROMOTION = {
  id: 'NEW',
};
const Promotions: React.FC<PromotionProps> = ({ dispatch, list, loading }) => {
  const intl = useIntl();

  function fetchTableData(payload?: any) {
    dispatch({
      type: 'promotion/fetch',
    });
  }

  function fetchPromotionTypes() {
    dispatch({
      type: 'promotion/fetchPromotionTypes',
    });
  }

  function removePromotion(emailPromotionId: string, resolve: Function) {
    dispatch({
      type: 'promotion/delete',
      payload: {
        emailPromotionId,
        cb: resolve,
        intl,
      },
    });
  }

  useEffect(() => {
    fetchPromotionTypes();
  }, []);

  const columns: ProColumns<PromotionModel>[] = [
    {
      title: '',
      dataIndex: 'notice',
      render: (_, record) =>
        record.notice ? (
          <Tooltip title={record.notice}>
            <WarningOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Tooltip>
        ) : undefined,
    },
    {
      title: <F id="pages.promotions.dates" defaultMessage="Dates" />,
      dataIndex: 'start_date',
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),

      render: (_, record) => (
        <small>
          <Space>
            <F id="pages.promotions.start" defaultMessage="Start" />
            {record.start_date}
          </Space>
          <Divider style={{ margin: '0 10px' }} />
          <Space>
            <F id="pages.promotions.end" defaultMessage="End" />
            {record.end_date}
          </Space>
        </small>
      ),
    },
    {
      title: <F id="pages.promotions.title" defaultMessage="Title" />,
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: <F id="pages.promotions.type" defaultMessage="Type" />,
      dataIndex: ['promo_type', 'title'],
      sorter: (a, b) => a.promo_type.title.localeCompare(b.promo_type.title),
    },

    {
      title: <F id="pages.locations.isActive" defaultMessage="Active" />,
      sorter: (a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1),
      dataIndex: 'is_active',
      render: (isActive: any) => <StatusCheck checked={isActive} badge />,
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.promotions.modifiedDate" defaultMessage="Modified Date" />,
      dataIndex: 'modified',
      sorter: (a, b) => new Date(a.modified) - new Date(b.modified),
      defaultSortOrder: 'descend',
    },
    {
      title: <F id="pages.common.actions" defaultMessage="Actions" />,
      dataIndex: '',
      render: (_, promotion: PromotionModel) => (
        <Space size={20}>
          <Tooltip
            title={<F id={'pages.common.editPromotion'} defaultMessage={'Edit Promotion'} />}
          >
            <a>
              <PromotionName promotion={promotion} drawer>
                <EditOutlined />
              </PromotionName>
            </a>
          </Tooltip>
          <Popconfirm
            onConfirm={() =>
              new Promise((resolve, reject) => removePromotion(promotion.id, resolve))
            }
            placement={'topRight'}
            title={
              <F
                id={'pages.promotions.removePromotionConfirm'}
                defaultMessage={'Are you sure to remove this promotion?'}
              />
            }
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
 
  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{ exportTable: {}, hasRangePicker: false }}>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Alert
          type={'info'}
          showIcon={false}
          message={
            <Space direction={'vertical'}>
              <Space direction="horizontal">
                <Typography.Title level={5}>
                  <F id={'pages.promotions.help_1'} />
                </Typography.Title>
              </Space>
              <>
                <F id={'pages.promotions.help_2'} />
                <YouTubeVideoWithCaptions height={100} width={150} />
              </>
            </Space>
          }
        />
        <Table
          headerTitle={intl.formatMessage({
            id: 'pages.promotions.tableTitle',
            defaultMessage: 'Promotions',
          })}
          rowClassName={(record: PromotionModel) => (record.notice ? styles['red-background'] : '')}
          columns={columns}
          pagination={false}
          search={false}
          label={TRACKING_LABEL}
          dataSource={list || []}
          loading={loading}
          rowKey={'id'}
          request={fetchTableData}
          expandableRow={createExpandedRowFromColumns<LocationModel>(columns)}
          toolBarRender={() => (
            <PromotionName style={{ color: 'inherit' }} drawer promotion={EMPTY_PROMOTION}>
              <Tooltip
                title={<F id={'pages.promotions.newPromotion'} defaultMessage={'New Promotion'} />}
              >
                <PlusOutlined />
              </Tooltip>
            </PromotionName>
          )}
        />
      </Space>
    </MyPageContainer>
  );
};

export default connect(({ promotion, loading }) => ({
  list: promotion.list,
  loading: loading.effects['promotion/fetch'],
}))(Promotions);
