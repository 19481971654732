

import config from '../../../youtube-api-key.json';


export const getAPIKey: () => string = () => {
  let API_KEY = '';
  if (config) {
    API_KEY = config["REACT_APP_YOUTUBE_API_KEY"];
  }
  return API_KEY
}

 const API_KEY = getAPIKey();
 export const VIDEO_Id = "i_Ys4w6pnCM";
 
export async function fetchCaptions() {
  const url = `https://www.googleapis.com/youtube/v3/captions?part=snippet&videoId=${VIDEO_Id}&key=${API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  return data?.items?.map(item => ({
      language: item.snippet.language,
      name: item.snippet.name,
      isCC: item.snippet.trackKind === 'ASR',
  }));
}
export const loadYouTubeIframeAPI = () => {
  return new Promise((resolve, reject) => {
    const scriptId = 'youtube-iframe-api';
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      return resolve();
    }
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = 'https://www.youtube.com/iframe_api';
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load YouTube IFrame API'));
    document.head.appendChild(script);
  });
};