import React from "react";
import {connect, useIntl} from 'umi'
import {getTreeForTagsAndTagGroups} from "@/utils/images";
import {Card, Divider, Empty, Modal, Space, Typography} from "antd";
import ImageTag from "@/components/Image/ImageTag";
import {removeTagRequest} from "@/services/api/image";


const TagListInformation =
  ({
     tags,
     tagGroups,
     selectedJourney,
     loading,
     dispatch
   }) => {

    const data = getTreeForTagsAndTagGroups(tags, tagGroups)
    const intl = useIntl();

    const handleRemove = tag => {
      Modal.confirm({
        title: <Space>
          {intl.formatMessage({id: "pages.common.remove"})}
          <span>{tag.title}</span>
        </Space>,
        content: <div>
          <p>{intl.formatMessage({id: "pages.images.removeTagGloballyTitle"})}</p>
          <strong>{intl.formatMessage({id: "pages.images.removeTagGloballySubTitle"})}</strong>
        </div>,
        onOk: () => new Promise(resolve => {
          removeTagRequest({
            params: {
              tagId: tag.id,
              journeyId: selectedJourney.hashId
            }
          }).then(() => {
            dispatch({
              type: "images/fetchTags"
            })
            dispatch({
              type: "images/fetch"
            })
            resolve()
          })
        })
      })
    }

    return <Card loading={loading} size={"small"}>
      <Space size={"large"} wrap style={{alignItems: "baseline"}} split={<Divider type={"vertical"}/>}>
        {data?.map(tagGroup => <div key={tagGroup.id}>
          <Typography.Title level={5}>{tagGroup.title}</Typography.Title>
          {(tagGroup.tags || []).length === 0 && <Empty imageStyle={{height: 25}} style={{margin: 0, marginTop: 5, fontSize: 10}} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {(tagGroup.tags || []).map(tag => <ImageTag editOnDoubleClick onRemove={handleRemove} key={tag.id} tag={tag} showCount/>)}
        </div>)}
      </Space>
    </Card>

  };

export default  connect(({images, journey, loading}) => ({
  tags: images.tags,
  tagGroups: images.tagGroups,
  selectedJourney: journey.selectedJourney,
  loading: loading.effects["images/fetchTags"] || loading.effects["images/fetchTagGroups"]
}))(TagListInformation)
