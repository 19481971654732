import UserMenuDropdown from '@/components/UserMenuDropdown';
import * as React from 'react'
import {useModel} from 'umi';

const MenuFooter: React.FC = () => {
  const {initialState} = useModel('@@initialState');

  return <div>
    <UserMenuDropdown mini={initialState?.collapsed}/>
  </div>
}

export default (MenuFooter)
