import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";
import {JourneyModel} from "../../typings/models/Journey";


// region Fetch Journey
type FetchJourneyRequestOptions = {
  params: {
    journeyId: JourneyModel["hashId"]
  },
}

export function fetchJourneyConfigRequest({
                                            params,
                                          }: FetchJourneyRequestOptions) {
  const url = URLParamBuilder(PATHS.GET_JOURNEY_CONFIG, {
    params
  })
  return request(url, {
    method: 'GET'
  });
}

// endregion

// region Edit Configuration
type EditConfigurationRequestOptions = {
  params: {
    journeyId: JourneyModel["hashId"],
    configurationKey: string,
  },
  data: any
}

export function editJourneyConfigurationRequest({
                                                  params,
                                                  data
                                                }: EditConfigurationRequestOptions) {
  const url = URLParamBuilder(PATHS.EDIT_JOURNEY_CONFIG, {
    params
  })
  return request(url, {
    method: 'PUT',
    data
  });
}

// endregion
