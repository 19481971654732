import * as React from "react";
import {mapHistogramChartData} from "@/utils/chart";
import {Column} from "@ant-design/charts";
import {ColumnConfig} from "@ant-design/charts/es/column";
import {CHART_THEME, FONT_FAMILY} from "@/constants/theme";

export type HistogramProps = {
  data: any
}
const HistogramChart: React.FC<HistogramProps> = ({data, title, ...extraProps}) => {
  const chartConfig: ColumnConfig = {
    theme: CHART_THEME,
    data: mapHistogramChartData(data),
    seriesField: 'key',
    xField: 'key',
    yField: 'value',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
        fontFamily: FONT_FAMILY,
      },
    },
    tooltip: {

    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      itemName: {
        style: {
          fontFamily: FONT_FAMILY
        }
      }
    },
    xAxis: {
      label: {
        autoHide: true,
        style: {
          fontFamily: FONT_FAMILY
        },
        autoRotate: false,
      },
    },
    meta: {
      key: {alias: title},
      value: {alias: 'Value'},
    },
    ...extraProps
  };
  return <Column {...chartConfig}/>;

}

export default HistogramChart
