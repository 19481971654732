import ProTable from '@ant-design/pro-table';
import {Card, Checkbox, Divider, Space, Tag, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect, FormattedMessage as F} from 'umi';

export interface TagStatisticsProps {}

const ACTION_FETCH_TAG_STATS_FOR_2GROUPS = 'tagStatistics/fetchTagStatisticsFor2TagGroups';
const ACTION_FETCH_TAG_STATS_FOR_3GROUPS = 'tagStatistics/fetchTagStatisticsFor3TagGroups';
const TagStatistics: React.FC<TagStatisticsProps> = ({
  dispatch,
  tagGroups,
  tagStatistics,
  loading,
}) => {
  const [selectedTags, setSelectedTags] = useState(
    tagGroups.slice(0, 2).map((group) => group.title),
  );

  const fetchTagGroups = () => {
    dispatch({
      type: 'images/fetchTagGroups',
    });
  };

  const fetchTagStatistics = () => {
    if (selectedTags.length < 2) {
      return;
    }
    const actions = {
      2: ACTION_FETCH_TAG_STATS_FOR_2GROUPS,
      3: ACTION_FETCH_TAG_STATS_FOR_3GROUPS,
    };

    const actionType = actions[selectedTags.length];
    if (actionType) {
      dispatch({
        type: actionType,
        payload: selectedTags.reduce((acc, tag, index) => {
          acc[`tagGroup${index + 1}Hashid`] = tag;
          return acc;
        }, {}),
      });
    }
  };
  useEffect(fetchTagGroups, [dispatch]);

  useEffect(fetchTagStatistics, [dispatch, selectedTags]);
  const columns = [
    {
      title: <F id="pages.stats.tagCombination" />,
      key: 'Combinations',
      render: (text, record) => (
        <>
          <Tag color="blue">{record.tag1}</Tag>
          <Tag color="green">{record.tag2}</Tag>
          {record.tag3 && <Tag color="volcano">{record.tag3}</Tag>}
        </>
      ),
    },
    {
      title: <F id="pages.stats.frequency" />,
      dataIndex: 'count',
      key: 'Frequency',
      sorter: (a, b) => a.count - b.count,
      render: (count) => <Tag color="blue">{count}</Tag>,
    },
  ];
  let dataSource = [];
  if (Array.isArray(tagStatistics)) {
    dataSource = tagStatistics.map((tagStatistic, index) => ({
      key: index,
      tag1: tagStatistic.tag1,
      tag2: tagStatistic.tag2,
      tag3: tagStatistic.tag3,
      count: tagStatistic.count,
    }));
  }
  const handleTagChange = (checkedValues) => {
    setSelectedTags(checkedValues);
  };
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card>
        <Divider orientation={'center'}>
          <Typography.Text>
            <F id="pages.stats.selectTagGroups" />
          </Typography.Text>
        </Divider>
        <Checkbox.Group
          value={selectedTags}
          onChange={handleTagChange}
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          {tagGroups.map((group) => (
            <div key={group.id} style={{ marginRight: 16, marginBottom: 24 }}>
              <Checkbox
                value={group.id}
                disabled={selectedTags.length >= 3 && !selectedTags.includes(group.id)}
              >
                {group.title}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </Card>

      <ProTable
        columns={columns}
        dataSource={dataSource}
        rowKey="key"
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: ['10', '20', '50'],
        }}
        search={false}
        loading={loading}
        options={{ density: false, reload: () => fetchTagStatistics(), setting: false }}
      />
    </Space>
  );
};

export default connect(({ images, journey, tagStatistics, loading }) => ({
  selectedJourney: journey.selectedJourney,
  tagGroups: images.tagGroups,
  tagStatistics: tagStatistics.tagStatistics,
  loading:
    loading.effects[ACTION_FETCH_TAG_STATS_FOR_2GROUPS] ||
    loading.effects[ACTION_FETCH_TAG_STATS_FOR_3GROUPS],
}))(TagStatistics);
