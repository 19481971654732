import FORM_TYPES from "@/constants/formTypes"
import {ValidateStatus} from "antd/lib/form/FormItem";
import {ProFormDigit, ProFormText, ProFormTextArea} from "@ant-design/pro-form";
import ProFormMultipleTagsFormItem from "@/components/Form/MultipleTagsFormItem";
import CustomUrlFormItem from "@/components/Form/CustomUrlFormItem";


type IFormBlurProps = {
  type?: string,
  callback?: (name: string, value: string) => void,
  name: string,
  loadings?: { [key: string]: boolean | undefined }
  disabled?: boolean,
  validateStatus?: ValidateStatus
  hasFeedback?: boolean,
  value?: string,
  fieldProps?: {
    onBlur?: (e) => void,
    onChange?: (e) => void,
  }
}

export function getFormBlurProps({type, callback, name, loadings = {}}: IFormBlurProps): IFormBlurProps {
  let props: IFormBlurProps = {
    name,
    disabled: loadings[name]
  }
  switch (type) {
    case FORM_TYPES.TEXT: {
      props = {
        ...props,
        hasFeedback: loadings[name],
        validateStatus: "validating",
        fieldProps: {
          onBlur: (e) => {
            callback && callback(name, e.target.value)
          }
        }
      }
    }
      break
    case FORM_TYPES.SWITCH:
    case FORM_TYPES.SELECT: {
      props = {
        ...props,
        // disabled: loadings[name],
        fieldProps: {
          onChange: value => {
            callback && callback(name, value)
          }
        }
      }
    }
      break
    case FORM_TYPES.RADIO: {
      props = {
        ...props,
        disabled: loadings[name],
        fieldProps: {
          onChange: event => {
            callback && callback(name, event.target.value)
          }
        }
      }
    }
      break
  }

  return props;
}


export function getChangeLoadings(body: Record<string, unknown>, value: boolean): Record<string, unknown> {
  const keys = Object.keys(body);
  const changeLoadings = {};
  keys.forEach(key => changeLoadings[key] = value)
  return changeLoadings
}

export function setChangeLoadingsUtility(state, action) {
  return {
    ...state,
    changeLoadings: {
      ...state.changeLoadings,
      ...action.payload
    }
  }
}



// region Dynamic Form

export const DYNAMIC_FORM_FIELD_TYPES = {
  STRING: "string",
  NUMBER: "number",
  TEXTAREA: "textarea",
  TAGS: "tags",
  CUSTOM_URL: "custom_url"
}

export const DYNAMIC_FORM_RULE_TYPES = {
  MIN: "min",
  MAX: "max",
  REQUIRED: "required",
  URL: "url",
  EMAIL: "email",
  CUSTOM_URL_EMPTY_IF_DEFAULT:"custom_url_empty_if_default"
}

export const FIELD_TYPE_COMPONENTS = {
  [DYNAMIC_FORM_FIELD_TYPES.STRING]: ProFormText,
  [DYNAMIC_FORM_FIELD_TYPES.NUMBER]: ProFormDigit,
  [DYNAMIC_FORM_FIELD_TYPES.TEXTAREA]: ProFormTextArea,
  [DYNAMIC_FORM_FIELD_TYPES.TAGS]: ProFormMultipleTagsFormItem,
  [DYNAMIC_FORM_FIELD_TYPES.CUSTOM_URL]: CustomUrlFormItem,
}
export const extractDynamicFormRules = (validation = "") => {
  const rules = validation.split("|")
  const processed = {}
  rules.forEach(rule => {
    const separated = rule.split(":")
    if ([DYNAMIC_FORM_RULE_TYPES.MAX, DYNAMIC_FORM_RULE_TYPES.MIN].includes(separated[0])) {
      processed[separated[0]] = parseInt(separated[1])
    } else {
      processed[separated[0]] = true
    }
  })

  return processed
}

export const getDynamicFormInputRules = (field) => {
  const rules = extractDynamicFormRules(field.validation)
  return Object.entries(rules).map(([key, value]) => {
    if ([DYNAMIC_FORM_RULE_TYPES.URL, DYNAMIC_FORM_RULE_TYPES.EMAIL].includes(key)){
      return {
        type: key
      }
    }
    return {
      [key]: value
    }
  })
}

// endregion
