import {message} from "antd";

import {
  changeLocationRequest,
  createLocationRequest,
  createResourceRequest, deleteLocationRequest,
  deleteResourceRequest,
  fetchLocationsRequest
} from "@/services/api/location";
import {selectCurrentJourneyId} from "@/models/journey";
import {getChangeLoadings, setChangeLoadingsUtility} from "@/utils/form";
import {trackModalView, trackOnlineEditForm} from "@/services/tracker";
import {LOCATION_EDIT_ACTION} from "@/constants/trackerActions";
import {processAPIResponse} from "@/utils/request";

export default {
  namespace: 'location',

  state: {
    list: [],
    changeLoadings: {},
    drawer: {
      location: {},
      visible: false
    }
  },

  effects: {
    * init({}, {put}) {
      const actions = ['fetch']
      for (const action of actions) {
        yield put({
          type: action
        })
      }
    },
    * fetch({}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      const {response, data} = yield call(fetchLocationsRequest, {params: {journeyId}});
      yield processAPIResponse({response, data}, {
              * onSuccess(data) {
                yield put({
                  type: 'saveLocationList',
                  payload: data
          })
        },
        * onError(error) {

        }
      })
    },
    * create({payload}, {call, put, select}) {
      const journeyId = yield select(selectCurrentJourneyId)
      payload.appointment_available_from = 48
      if (!payload.weekdays){
        payload.weekdays = []
      }
      payload.weekdays = payload.weekdays.map(w => ({
        ...w,
        day: w.day.toLowerCase()
      }))
      // endregion
      const {response, data} = yield call(createLocationRequest, {params: {journeyId}, payload});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: 'toggleDrawer',
            payload: {}
          })
          yield put({
            type: 'fetch',
          })
        }
      })

    },
    * delete({payload: {locationId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteLocationRequest, {params: {locationId}});
      yield processAPIResponse({response, data}, {
        * onSuccess() {
          yield put({
            type: "fetch"
          })
          intl && message.success(intl.formatMessage({id: "pages.locations.removeLocationSuccess"}))
          cb && cb(data)
        },
        * onError(error) {
          message.error(error?.message || intl.formatMessage({id: "pages.locations.removeLocationFailed"}))
        },
      })
    },
    * change({payload: {locationId, ...body}}, {call, put, select}) {
      yield put({
        type: "setChangeLoadings",
        payload: getChangeLoadings(body, true)
      })
      const {response, data} = yield call(changeLocationRequest, {params: {locationId}, payload: body});
      yield put({
        type: "setChangeLoadings",
        payload: getChangeLoadings(body, false)
      })

      trackOnlineEditForm(body, LOCATION_EDIT_ACTION)

      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: "fetch"
          })
          if (body.Availability) {
            yield put({
              type: "updateDrawerLocation",
              payload: {
                location: data?.data
              }
            })
          }
        }
      })
    },
    * addResource({payload: {projectId, body, cb, intl}}, {call, put}) {
      const {response, data} = yield call(createResourceRequest, {params: {projectId}, payload: body});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: "fetch"
          })
          message.success(intl.formatMessage({id: "pages.locations.addNewResourceSuccess"}))
          cb(data)
        }, * onError(error) {
          message.error(error?.message || intl.formatMessage({id: "pages.locations.addNewResourceFailed"}))
        },
      })
    },
    * deleteResource({payload: {projectId, resourceId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteResourceRequest, {params: {resourceId, projectId}});
      yield processAPIResponse({response, data}, {
        * onSuccess(data) {
          yield put({
            type: "fetch"
          })
          message.success(intl.formatMessage({id: "pages.locations.removeResourceSuccess"}))
          cb(data)
        },
        * onError(error) {
          message.error(error?.message || intl.formatMessage({id: "pages.locations.removeResourceFailed"}))
        }
      })
    },
  },
  reducers: {
    saveLocationList(state, action) {
      return {
        ...state,
        list: action.payload.data
      }
    },
    setChangeLoadings(state, action) {
      return setChangeLoadingsUtility(state, action)
    },
    updateDrawerLocation(state, action) {
      const {payload: {location}} = action;
      return {
        ...state,
        drawer: {
          ...state.drawer,
          location
        }
      }
    },
    toggleDrawer(state, action) {
      const {payload: {location}} = action;

      const drawer = state.drawer || {};
      if (location) {
        drawer.locationId = location.id
        drawer.visible = true
        trackModalView('location-details')
      } else {
        drawer.visible = false
      }

      return {
        ...state,
        drawer
      }
    }
  }
};
