import React, {useEffect} from 'react';
import {FormattedMessage as F, useDispatch, useIntl, useSelector} from 'umi';
import {Button, Space} from 'antd';
import Drawer from '@/components/Drawer';

import styles from './index.less';
import PromotionConditionForm from "@/components/PromotionDrawer/PromotionConidtionForm";
import isEmpty from "lodash/isEmpty";
import {merge, pick} from "lodash";

const PromotionConditionDrawer: React.FC = () => {

  const dispatch = useDispatch();
  const { visible, loading, promotionCondition } = useSelector(
    ({ promotion: { promotionConditionDrawer }, loading, journey }: DefaultRootState) => ({
      visible: promotionConditionDrawer?.visible,
      promotionCondition: promotionConditionDrawer?.promotionCondition,
      loading: loading.effects['promotion/createPromotionCondition'] || loading.effects['promotion/editPromotionCondition']
    }),
  );
  const [promotionConditionFormData, setPromotionConditionFormData] = React.useState({})

  function toggle() {
    dispatch({
      type: 'promotion/togglePromotionConditionDrawer'
    });
  }

  function isNewPromotionCondition() {
    return isEmpty(promotionCondition);
  }

  useEffect(() => {
    if (!isNewPromotionCondition())
      setPromotionConditionFormData(promotionCondition)
  }, [JSON.stringify(promotionCondition)])

  function onSubmit() {
    dispatch({
      type: isNewPromotionCondition() ? 'promotion/createPromotionCondition' : 'promotion/editPromotionCondition',
      payload: merge(pick(promotionCondition, 'id'), promotionConditionFormData),
    });
  }

  function getFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Button
            className={'button-secondary button-bordered'}
            size={'large'}
            type={'primary'}
            onClick={toggle}
          >
            <F id={'app.settings.close'} defaultMessage={'Close'} />
          </Button>
          <Button
            size={'large'}
            loading={loading}
            onClick={onSubmit}
            type={'primary'}
          >
            {isNewPromotionCondition() ? <F id={'pages.promotions.addPromotionCondition'} defaultMessage={'Add Promotion Condition'} /> :
            <F id={'pages.common.edit'} defaultMessage={'Edit Promotion Condition'} />}
          </Button>
        </Space>
      </div>
    );
  }

  const intl = useIntl();


  return (
    <div className={styles.drawerContainer}>
      <Drawer
        footer={getFooter()}
        visible={visible}
        open={visible}
        destroyOnClose
        title={intl.formatMessage({
          id: 'pages.promotions.promotionCondition',
          defaultMessage: 'Promotion Terms & Conditions',
        })}
        onVisibleChange={toggle}
      >
          <PromotionConditionForm initialValues={promotionCondition} onChange={setPromotionConditionFormData}/>
      </Drawer>
    </div>
  );
};

export default PromotionConditionDrawer;
