import React from 'react';
import { Space, Typography } from 'antd';
import Card from '@/components/Card';
import styles from './index.less';
import CardHelpTooltip from '../Help/CardHelpTooltip';

interface ScoreTitleProps {
  title?: string;
  tooltip?: string;
  score?: number | string;
  fallback?: string;
  icon?: React.ReactNode;
}

const ScoreTitle: React.FC<ScoreTitleProps> = ({
  title,
  tooltip,
  score,
  icon,
  fallback = ' - ',
}: ScoreTitleProps) => {
  function parseScore(parsed) {
    return parsed ? Number(parsed).toLocaleString() : fallback;
  }

  function getScore() {
    if (score === null || score === undefined) {
      return fallback;
    }
    if (typeof score === 'number') {
      return parseScore(score);
    }
    if (typeof score === 'string') {
      const parsed = parseInt(score);
      if (!isNaN(parsed)) {
        return parseScore(parsed);
      } else {
        return score;
      }
    } else {
      return fallback;
    }
    return fallback;
  }

  return (
    <Card>
      <div className={styles.ScoreTitle}>
        <div className={styles.ScoreTitle__content}>
          <Space>
            {title && (
              <Typography.Text className={styles.ScoreTitle__content__title}>
                {title}
              </Typography.Text>
            )}
            {tooltip && <CardHelpTooltip title={tooltip} />}
          </Space>
          <div className={styles.ScoreTitle__content__score}>{getScore()}</div>
        </div>
        <div className={styles.ScoreTitle__icon}>{icon}</div>
      </div>
    </Card>
  );
};

export default React.memo(ScoreTitle);
