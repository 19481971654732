import React from 'react';
import { FilePdfOutlined, FileImageOutlined, FileOutlined } from '@ant-design/icons';

interface FileAvatarProps {
  filename: string;
}

const FileIcon: React.FC<FileAvatarProps> = ({ filename }) => {
  const fileFormat = filename.split('.').pop();

  let Icon = FileOutlined; // default to FileOutlined
  let style = { color: 'grey', height: 20, width: 20, fontSize: 23 }; // default style
  switch (fileFormat) {
    case 'pdf':
      Icon = FilePdfOutlined;
      style = { color: '#F5222D', fontSize: 23 };
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      Icon = FileImageOutlined;
      style = { color: '#1677FF', fontSize: 23 };

      break;
    default:
      Icon = FileOutlined;
  }

  return <Icon style={style} />;
};

export default FileIcon;
