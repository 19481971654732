import isEmpty from "lodash/isEmpty";

export const randomString = function (length?: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
};

export function getPosition(string: string, subString: string, index: number = 0) {
    return string.split(subString, index).join(subString).length;
}

export const generateUUID = () => {
    let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    return [u.substring(0, 8), u.substring(8, 12), '4000-8' + u.substring(13, 16), u.substring(16, 28)].join('-');
}

export const getFileSize = (size: number) => {
    return `${(size / 1000000).toFixed(
        2
    )} MB`
}

export const isEmptyString = (text?: string) => typeof text !== "string" ? isEmpty(text) : isEmpty((text || "").trim())


export function capitalizeFirstLetter(str: string = "") {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
