import React from "react";
import Card from "@/components/Card";
import HistogramChart from "@/components/charts/Histogram/HistogramChart";


export type HistogramProps = {
  cardTitle: String,
  helpTooltip?: String,
  chartData: any
}
const Histogram : React.FC<HistogramProps> = ({cardTitle, chartData, helpTooltip}) => {
  return <Card title={cardTitle}>
    <HistogramChart data={chartData}/>
  </Card>
}

export default Histogram
