import {request} from "./request";
import * as PATHS from "@/constants/path"
import {URLParamBuilder} from "@/utils/functions";
import {GetRecentServiceRequestsRequest} from "@/typings/api/getRecentServiceRequest";

/** Fetch Recent Service Requests */

type FetchRecentServiceRequestsOptions = {
  params: GetRecentServiceRequestsRequest["parameters"]
  url?: string
  query?: GetRecentServiceRequestsRequest["query"]
}
export function fetchRecentServiceRequests({ params, query, url }: FetchRecentServiceRequestsOptions ) {
  const finalUrl = url || URLParamBuilder(PATHS.GET_RECENT_SERVICE_REQUEST_API_PATH, {
    params,
    queryString: query
  })

  return request<GetRecentServiceRequestsRequest["response"]>(finalUrl, {
    method: 'GET'
  });
}

