import createExpandedRowFromColumns from "@/components/Table/createExpandedRowFromColumns";
import React, {useCallback, useEffect, useState} from 'react';
import {connect, FormattedMessage as F, useIntl} from 'umi';
import MyPageContainer from "@/components/MyPageContainer";
import Table from "@/components/Table";
import debounce from "lodash/debounce";
import type {ProColumns} from "@ant-design/pro-table";
import {CustomerModel} from "@/typings/models/Customer";
import CustomerName from "@/components/Customer/CustomerName";
import {getExportURL} from "@/utils/functions";
import StatusCheck from "@/components/StatusCheck";
import { EngagementScore } from "./EngagementScore";

const TRACKING_LABEL = "Customers"
const REDUX_ACTION = 'customers/fetch'
const Customers: React.FC = ({dispatch, list, loading, date}) => {

  const intl = useIntl();
  const [tablePayload, setTablePayload] = useState({})

  const fetchTableData = useCallback(debounce((payload = tablePayload) => {
      setTablePayload(payload)
      dispatch({
        type: REDUX_ACTION,
        payload
      })
  }, 200), [])

  useEffect(() => {
    fetchTableData()
  }, [date.updatedAt])

  const columns: ProColumns<any>[] = [
    {
      title: <F id="pages.customers.name" defaultMessage="Name" />,
      dataIndex: 'name',
      render: (_, customer: CustomerModel) => <CustomerName customer={customer} />,
    },
    {
      title: <F id="pages.customers.engagementScore" defaultMessage="Engagement Score" />,
      tooltip: (
        <F
          id="pages.customers.scoreExplanation"
          defaultMessage="Estimated probability this customer will buy in your store based on all previous interactions."
        />
      ),
      dataIndex: 'engagement_score',
      render: (score: number) =>
        score ? (
          <div style={{ textAlign: 'center' }}>
            {score ? <EngagementScore score={score} /> : '-'}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: <F id="pages.customers.email" defaultMessage="Email" />,
      dataIndex: 'email',
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: <F id="pages.customers.leadMedium" defaultMessage="Lead Medium" />,
      dataIndex: 'lead_medium',
      responsive: ['xxl', 'xl', 'lg'],
    },
    {
      title: <F id="pages.customers.status" defaultMessage="Status" />,
      dataIndex: ['state', 'value'],
      responsive: ['xxl', 'xl', 'lg', 'md'],
    },
    {
      title: <F id="pages.customers.activeRecipient" defaultMessage="Active Recipient" />,
      dataIndex: 'subscriber',
      render: (subscriber: boolean) => <StatusCheck checked={subscriber} badge />,
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.customers.doubleOptin" defaultMessage="Double Opt-in" />,
      dataIndex: 'doi_date',
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.customers.createDate" defaultMessage="Create Date" />,
      dataIndex: 'created',
      responsive: ['xxl', 'xl'],
    },
    {
      title: <F id="pages.customers.actions" defaultMessage="Actions" />,
      dataIndex: 'actions',
      render: (_, customer: CustomerModel) => (
        <CustomerName drawer={true} customer={customer}>
          <F id="pages.customers.details" defaultMessage="Details" />
        </CustomerName>
      ),
    },
  ];

  return (
    <MyPageContainer label={TRACKING_LABEL} extraProps={{exportTable: { downloadAction: 'customers/downloadExport', url: getExportURL(list)}, hasRangePicker: true}}>
      <Table headerTitle={intl.formatMessage({
        id: 'pages.customers.tableTitle',
        defaultMessage: 'Customers',
      })} columns={columns}
             rowKey={"email"}
             label={TRACKING_LABEL}
             total={list.total}
             links={list.links}
             dataSource={list.items} loading={loading}
             request={fetchTableData}
             expandableRow={createExpandedRowFromColumns<CustomerModel>(columns)}
      />
    </MyPageContainer>
  );
};

export default connect(({customers, loading, date}) => ({
  list: customers.list,
  date,
  loading: loading.effects[REDUX_ACTION]
}))(Customers);
