import * as React from 'react'
import {useCallback, useEffect, useState} from 'react'
import {connect} from 'umi'
import debounce from "lodash/debounce";
import {Divider, Segmented} from "antd";

import Card from "@/components/Card";
import AreaChart from "@/components/charts/Area/AreaChart";
import {DispatchFunction} from "@/typings/models/ReduxState";
import PeriodSelector from "@/components/PeriodSelector";
import {RegistrationStatsModel} from "@/typings/models/RegistrationStats";
import {SubscribersStatsModel} from "@/typings/models/SubscribersStats";
import {ServiceRequestStatsModel} from "@/typings/models/ServiceRequestStats";

import styles from "./index.less";
import MyRadioGroup from '../MyRadioGroup/index';
import {CHART_HINTS, CHART_LABEL_OPTIONS, CHART_MODELS, CHART_RADIO_GROUP_OPTIONS, REDUX_ACTIONS} from "./constants";

interface TrendChartsProps {
  registration: RegistrationStatsModel[],
  subscriber: SubscribersStatsModel[],
  serviceRequest: ServiceRequestStatsModel[],
  dispatch: DispatchFunction,
  loading: boolean
}

const TrendCharts: React.FC<TrendChartsProps> = ({
                                                   registration,
                                                   subscriber,
                                                   serviceRequest,
                                                   loading,
                                                   dispatch,
                                                   date
                                                 }: TrendChartsProps) => {

  const [chartModel, setChartModel] = useState(CHART_MODELS.REGISTRATIONS)
  const [chartType, setChartType] = useState("cumulative")

  const DATA_MAP = {
    [CHART_MODELS.REGISTRATIONS]: registration,
    [CHART_MODELS.SUBSCRIBERS]: subscriber,
    [CHART_MODELS.SERVICE_REQUEST]: serviceRequest,
  }

  const fetchData = useCallback(debounce(() => {
    dispatch({
      type: REDUX_ACTIONS[chartModel]
    })
  }, 200), [chartModel])

  useEffect(() => {
    fetchData()
  }, [date.updatedAt, chartModel])


  function formatData() {
    const data = DATA_MAP[chartModel]
    return (data || []).map(point => ({
      timePeriod: point.interval,
      value: point.values[chartType === "change" ? "change" : chartModel],
      type: "data"
    }))
  }

  const data = formatData()
  return <Card className={styles.card} loading={loading} title={
    <>
      <Segmented size={"large"}
                 value={chartModel}
                 onChange={setChartModel}
                 options={CHART_LABEL_OPTIONS}/>
    </>
  }>
    <div className={styles.chartActions}>
      <div className={styles.chartTypeSelector}>
        <MyRadioGroup onChange={e => setChartType(e.target.value)} value={chartType} values={CHART_RADIO_GROUP_OPTIONS}/>
      </div>
      <div className={styles.periodSelector}>
        <PeriodSelector onChange={() => fetchData()}/>
      </div>
    </div>
    {CHART_HINTS[chartModel] &&
      <Divider style={{marginTop: 0}} orientation={"left"}>{CHART_HINTS[chartModel]}</Divider>}
    <AreaChart data={data}/>
  </Card>
}

export default connect(({analytics: {registration, subscriber, serviceRequest}, loading, date}) => ({
  registration,
  subscriber,
  serviceRequest,
  loading: Object.entries(REDUX_ACTIONS).some((([model, action]) => loading.effects[action])),
  date
}))(TrendCharts)
